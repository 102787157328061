import {
	Box,
	Button,
	Container,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Linear, Power2 } from "gsap";
import { TimelineMax } from "gsap/gsap-core";
import { TweenLite } from "gsap/gsap-core";
import { Bounce, Slide } from "react-reveal";
import bg from "../../images/background3.png";
import bg1 from "../../images/background81.png";
import bg2 from "../../images/background82.png";
import img from "../../images/upcoming1.png";
import img1 from "../../images/upcoming2.png";
import cross from "../../images/cross.png";
import cross2 from "../../images/cross2.png";
import cross3 from "../../images/cross3.png";
import cross4 from "../../images/cross4.png";
import cross5 from "../../images/cross5.png";
import cross6 from "../../images/cross6.png";

function Sideimages() {
	const matches = useMediaQuery("(max-width:600px)");
	const hover = () => {
		const tlCan = new TimelineMax({ repeat: -1 });
		tlCan
			.to(".hover", 4, {
				y: "-=30",
				ease: Power2.easeIn,
			})
			.to(".hover", 4, {
				y: "+=30",
				ease: Power2.easeOut,
			});
		TweenLite.to(tlCan, 27, { ease: Linear.easeNone });
		const tlCan1 = new TimelineMax({ repeat: -1 });
		tlCan1
			.to(".rotate", 4, {
				rotation: 100,
				ease: Power2.easeIn,
			})
			.to(".rotate", 4, {
				rotation: 360,
				ease: Power2.easeOut,
			});
		TweenLite.to(tlCan1, 27, { ease: Linear.easeNone });
		const tlCan2 = new TimelineMax({ repeat: -1 });
		tlCan2
			.from(".scale", 4, {
				scale: 1,
				ease: Power2.easeIn,
			})
			.to(".scale", 4, {
				scale: 1.1,
				ease: Power2.easeIn,
			})
			.to(".scale", 4, {
				scale: 1,
				ease: Power2.easeOut,
			});
		TweenLite.to(tlCan2, 27, { ease: Linear.easeNone });
	};

	useEffect(() => {
		hover();
	}, []);
	return (
		<Box
			pt={10}
			sx={{
				background: `url(${bg})`,
				backgroundPosition: "right top",
				backgroundRepeat: "no-repeat",
				backgroundSize: "auto",
			}}
		>
			<Container maxWidth="lg">
				<Grid container>
					<Grid
						item
						xs={12}
						md={6}
						display="flex"
						flexDirection="column"
						justifyContent="center"
					>
						<Bounce left>
							<Box>
								<Typography
									fontWeight="700"
									fontFamily="Josefin Sans"
									textAlign={{ xs: "center", md: "start" }}
									fontSize={{ xs: "30px", md: "40px" }}
									lineHeight="56px"
									letterSpacing="2px"
									color="#FFFFFF"
								>
									Meta's Gate Launchpad
								</Typography>
							</Box>
							<Box>
								<Box>
								<Typography
										fontWeight="400"
										font-size="16px"s
										lineHeight="32px"
										textAlign={{ xs: "center", md: "start" }}
										color="#7C7B97"
									>
										Metasgate is a decentralized launchpad that offers a wide range of services such as decentralized launchpad, token minting, liquidity locker, and token locker. With our platform, you can launch your project quickly and securely, with low gas fees. Our decentralized infrastructure ensures that all transactions are transparent and secure, providing a seamless experience for our users. Whether you're launching a new project or looking to invest in existing ones, Metasgate is the perfect platform for all your crypto needs. 
									</Typography><br />
								</Box>
							</Box>
							<Box
								py={6}
								display="flex"
								justifyContent={{ xs: "center", md: "flex-start" }}
							>
								<Button
									sx={{
										width: "231.91px",

										height: "40px",
										borderRadius: "25px",

										border: "1px transparent",
										backgroundImage:
											"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "1px",
										fontSize: "14px",
										letterSpacing: "2%",
										fontWeight: 500,
										fontFamily: "Poppins",
										color: "#fff",
										zIndex: 1,
										marginRight: "20px",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									<span> Go Explore Now</span>
								</Button>
							</Box>
						</Bounce>
					</Grid>
					<Grid item xs={12} md={6}>
						{/* <Box
							sx={{
								borderRadius: "32px",
							}}
						>
							<Box
								mb={2}
								style={{
									borderRadius: "32px",
								}}
								className="details-box hover"
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<span className="span"></span>
								<span className="span"></span>
								<span className="span"></span>
								<span className="span"></span>
								<img
									src={img}
									style={{
										borderRadius: "32px",
										width: "100%",
										maxWidth: "555px",
										height: "auto",
									}}
									alt=""
								/>
							</Box>
						</Box> */}
						<Box display="flex" justifyContent="center" alignItems="center">
							<Box
								className="details-box hover"
								sx={{
									position: "relative",
								}}
							>
								<Slide bottom>
									<img
										src={img}
										style={{
											width: "100%",
											maxWidth: "430px",
										}}
										alt=""
									/>
									<Box
										className="rotate"
										sx={{
											position: "absolute",
											right: 20,
											bottom: 150,
										}}
									>
										<img src={cross4} alt="" />
									</Box>
									<Box
										className="scale"
										sx={{
											position: "absolute",
											left: 0,
											bottom: { sm: 300, xs: 200 },
											zIndex: -1,
										}}
									>
										<img src={cross5} alt="" />
									</Box>
									<Box
										className="rotate"
										sx={{
											position: "absolute",
											left: 0,
											bottom: 20,
										}}
									>
										<img src={cross6} alt="" />
									</Box>
								</Slide>
							</Box>
						</Box>
					</Grid>
				</Grid>
				<Box
					pt={10}
					sx={{
						background: `url(${bg1}),url(${bg2})`,
						backgroundPosition: "top left, bottom right",
						backgroundRepeat: "no-repeat, no-repeat",
						backgroundSize: { sm: "auto", xs: "contain, auto" },
					}}
				>
					<Grid container spacing={5}>
						<Grid item xs={12} md={6}>
							<Box display="flex" justifyContent="center" alignItems="center">
								<Box
									className="details-box hover"
									sx={{
										position: "relative",
									}}
								>
									<Slide bottom>
										<img
											src={img1}
											style={{
												width: "100%",
												maxWidth: "430px",
											}}
											alt=""
										/>
										<Box
											className="rotate"
											sx={{
												position: "absolute",
												right: 20,
												bottom: 150,
											}}
										>
											<img src={cross} alt="" />
										</Box>
										<Box
											className="rotate"
											sx={{
												position: "absolute",
												left: 0,
												bottom: { sm: 250, xs: 200 },
												zIndex: -1,
											}}
										>
											<img src={cross3} alt="" />
										</Box>
										<Box
											className="rotate"
											sx={{
												position: "absolute",
												left: 0,
												bottom: 20,
											}}
										>
											<img src={cross2} alt="" />
										</Box>
									</Slide>
								</Box>
							</Box>
						</Grid>
						{/* <Grid item xs={12} md={3}>
							<Box
								className="details-box hover "
								sx={{
									display: "flex",
									justifyContent: "center",
									pt: { md: 15, sm: 5 },
								}}
							>
								<Slide bottom>
									<img
										src={hand}
										style={{
											borderRadius: "15px",
											width: "100%",
											maxWidth: "310px",
											height: "auto",
										}}
										alt=""
									/>
								</Slide>
							</Box>
						</Grid>
						<Grid item xs={12} md={3}>
							<Box
								className="details-box hover"
								sx={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Slide top>
									<img
										src={coin}
										style={{
											borderRadius: "15px",
											width: "100%",
											maxWidth: "310px",
											height: "auto",
										}}
										alt=""
									/>
								</Slide>
							</Box>
						</Grid> */}
						<Grid item xs={12} md={6}>
							<Box py={{ md: 8, sm: 5 }}>
								<Bounce right>
									<Box>
										<Typography
											fontWeight="700"
											textAlign={{ xs: "center", md: "start" }}
											fontSize={{ xs: "30px", md: "40px" }}
											fontFamily="Josefin Sans"
											lineHeight="56px"
											letterSpacing="2px"
											color="#FFFFFF"
										>
											IDO for everyone.
										</Typography>
									</Box>
									<Box py={3}>
										<Typography
											fontWeight="400"
											font-size="16px"s
											lineHeight="32px"
											textAlign={{ xs: "center", md: "start" }}
											color="#7C7B97"
										>
											Metasgate makes it easy for you to invest in any project on the Binance Smart Chain (BSC) network. All you have to do is connect your wallet and you are ready to participate in a project. You can invest with USDT, a stablecoin pegged to the value of the US dollar, which provides a stable and secure way to invest in a project. With our platform, you can easily and quickly invest in any project that interests you, without any complicated procedures or steep learning curves.
										</Typography>
									</Box>
									<Box>
										<Button
											variant="outlined"
											fontWeight="500"
											fontSize="16px"
											lineHeight="24px"
											letterSpacing="0.02em"
											sx={{
												marginRight: "20px",
												height: "48px",
												textTransform: "capitalize",
												width: matches ? "45%" : "40%",
												backgroundClip: "content-box, border-box",
												color: "#FFFFFF",
												border: "2px transparent",
												backgroundImage:
													"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												backgroundOrigin: "border-box",
												filter:
													// "drop-shadow(0px 13px 17px rgba(119, 87, 239, 0.2))",
													"boxShadow: 0px 4px 23px rgba(70, 60, 221, 0.34)",
												borderRadius: "56px",
												padding: "2px",
												"&:hover": {
													background:
														"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
												},
											}}
										>
											<span>Participate Now</span>
										</Button>

										<Button
											variant="outlined"
											fontWeight="500"
											fontSize="16px"
											lineHeight="24px"
											letterSpacing="0.02em"
											sx={{
												height: "48px",
												textTransform: "capitalize",
												width: matches ? "45%" : "40%",
												backgroundClip: "content-box, border-box",
												color: "#FFFFFF",
												border: "2px transparent",
												backgroundImage:
													"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												backgroundOrigin: "border-box",
												filter:
													// "drop-shadow(0px 13px 17px rgba(119, 87, 239, 0.2))",
													"boxShadow: 0px 4px 23px rgba(70, 60, 221, 0.34)",
												borderRadius: "56px",
												padding: "2px",
												"&:hover": {
													background:
														"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
												},
											}}
										>
											<span>Learn More</span>
										</Button>
									</Box>
								</Bounce>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	);
}

export default Sideimages;
