import React, { useState, useContext } from "react";
import {
	Box,
	Container,
	Hidden,
	Typography,
	IconButton,
	Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppContext } from "../utils";
import logo from "../images/mainlogo1.png";
import wallet from "../images/icowal.png";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import icon from "../images/icon.png";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
	list: {
		width: 250,
		paddingTop: "70px",
	},
	fullList: {
		width: "auto",
		alignItems: "center",
	},
	paper: {
		background: "#090E27 !important",
		justifyContent: "space-between",
	},
	hover: {
		"&:hover": {
			color: "#FFB800",
		},
	},
	colorChange: {
		backgroundColor: "#11112e",
	},
});

export default function Header() {
	const classes = useStyles();
	const [state, setState] = useState(false);
	const { account, connect, disconnect } = useContext(AppContext);

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};
	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<Link to="/" style={{ cursor: "pointer" }}>
				<Box mb={5} display="flex" justifyContent="center" alignItems="center">
					<img width="80%" src={logo} alt="" />
				</Box>
			</Link>
			<List>
				{["Projects", "Services", "Dashboard", "Earn", "Docs"].map(
					(text, index) => (
						<ListItem
							button
							style={{
								justifyContent: "center",
							}}
							key={index}
						>
							<Link to={`/${text.toLowerCase()}`}>
								<ListItemText
									style={{
										textTransform: "capitalize",
										textAlign: "center",
										textDecoration: "none",
										cursor: "pointer",
										color: "#ffffff",
										fontSize: "15px",
										fontWeight: 600,
										fontFamily: "Montserrat",
									}}
									primary={text}
								/>
							</Link>
						</ListItem>
					)
				)}
			</List>
			<Box
				mt={3}
				sx={{
					display: "flex",
					gap: "15px",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img src={icon} width="30px" height="35px" alt="icon" />
				<Typography
					sx={{
						fontFamily: "open sans",
						fontStyle: "normal",
						fontWeight: "400",
						fontSize: "15px",
						lineHeight: "20px",
						color: "text.primary",
					}}
				>
					{" "}
					$ 0.9998884
				</Typography>
			</Box>
			<Box mt={2} display="flex" justifyContent="center">
				{/* {account ? (
					<Box
						sx={{
							background: "transparent",
							cursor: "pointer",
							color: "#ffffff",
							"&:hover": {
								background: "white",
								color: " #5142FC",
							},
							"&:active": {
								background: "white",
								color: " #5142FC",
							},
						}}
						border="1px solid #5142FC"
						height="48px"
						fontWeight="700"
						borderRadius="24px"
						fontSize="15px"
						width="90%"
						display="flex"
						justifyContent="center"
						alignItems="center"
						fontFamily="Montserrat"
						onClick={() => disconnect()}
						style={{ zIndex: 1 }}
					>
						{account.slice(0, 4) + "..." + account.slice(-4)}
					</Box>
				) : (
					<Box
						zIndex={1}
						sx={{
							background: "transparent",
							cursor: "pointer",
							color: "#ffffff",
							"&:hover": {
								background: "white",
								color: " #5142FC",
							},
							"&:active": {
								background: "white",
								color: " #5142FC",
							},
						}}
						height="48px"
						fontWeight="700"
						borderRadius="24px"
						border="1px solid #5142FC"
						fontSize="15px"
						width="90%"
						display="flex"
						justifyContent="center"
						alignItems="center"
						fontFamily="Montserrat"
						onClick={() => connect()}
					>
						<img src={wallet} style={{ marginRight: "10px" }} alt="" /> Wallet
						connect
					</Box>
				)} */}
				{account ? (
					<Button
						sx={{
							width: "198px",
							height: "48px",
							borderRadius: "32px",

							border: "1px transparent",
							backgroundImage:
								"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
							backgroundOrigin: "border-box",
							backgroundClip: "content-box, border-box",
							padding: "1px",
							fontSize: "14px",
							letterSpacing: "2%",
							fontWeight: 500,
							fontFamily: "Poppins",
							color: "#fff",
							zIndex: 1,
							"&:hover": {
								background:
									"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
							},
						}}
						onClick={() => disconnect()}
					>
						{" "}
						{account.slice(0, 4) + "..." + account.slice(-4)}
					</Button>
				) : (
					<Button
						sx={{
							width: "198px",
							height: "48px",
							borderRadius: "32px",

							border: "1px transparent",
							backgroundImage:
								"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
							backgroundOrigin: "border-box",
							backgroundClip: "content-box, border-box",
							padding: "1px",
							fontSize: "14px",
							letterSpacing: "2%",
							fontWeight: 500,
							fontFamily: "Poppins",
							color: "#fff",
							zIndex: 1,
							"&:hover": {
								background:
									"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
							},
						}}
						onClick={() => connect()}
					>
						<img src={wallet} style={{ marginRight: "10px" }} alt="" /> Wallet
						connect
					</Button>
				)}
			</Box>
		</div>
	);

	const [colorChange, setColorchange] = useState(false);
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setColorchange(true);
		} else {
			setColorchange(false);
		}
	};
	window.addEventListener("scroll", changeNavbarColor);
	return (
		<Box position="relative" width="100%">
			<Box
				sx={{
					position: "fixed",
					zIndex: 100,
				}}
				width="100%"
				// className={colorChange ? classes.colorChange : null}
				className={classes.colorChange}
				borderBottom="1px solid rgba(138, 138, 160, .4)"
			>
				<Container maxWidth="lg">
					<Box
						padding="10px 0px"
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Link to="/" style={{ cursor: "pointer" }}>
							<img width="80%" src={logo} alt="logo" />
						</Link>
						<Hidden mdDown>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								width="100%"
							>
								<Box
									display="flex"
									justifyContent="space-evenly"
									alignItems="center"
									pl={2}
								>
									<Box fontWeight={400} fontSize="15px" mr={2}>
										<Link
											to="/projects"
											style={{ color: "white", textDecoration: "none" }}
										>
											Projects
										</Link>
									</Box>

									<Box fontWeight={400} fontSize="15px" mr={2}>
										<Link
											to="/services"
											style={{ color: "white", textDecoration: "none" }}
										>
											Services
										</Link>
									</Box>
									<Box fontWeight={400} fontSize="15px" mr={2}>
										<Link
											to="/dashboard"
											style={{ color: "white", textDecoration: "none" }}
										>
											Dashboard
										</Link>
									</Box>

									<Box fontWeight={400} fontSize="15px" mr={2}>
										<Link
											to="/earn"
											style={{ color: "white", textDecoration: "none" }}
										>
											Earn
										</Link>
									</Box>
									<Box fontWeight={400} fontSize="15px" mr={2}>
										<Link
											to="#"
											style={{ color: "white", textDecoration: "none" }}
										>
											Docs
										</Link>
									</Box>
								</Box>
								<Box sx={{ display: "flex", gap: "30px" }}>
									<Box
										sx={{ display: "flex", gap: "15px", alignItems: "center" }}
									>
										<img src={icon} width="30px" height="35px" alt="icon" />
										<Typography
											sx={{
												fontFamily: "Urbanist",
												fontStyle: "normal",
												fontWeight: "400",
												fontSize: "15px",
												lineHeight: "20px",
												color: "text.primary",
											}}
										>
											{" "}
											$ 0.9998884
										</Typography>
									</Box>

									{account ? (
										<Button
											sx={{
												width: "198px",
												height: "48px",
												borderRadius: "32px",

												border: "1px transparent",
												backgroundImage:
													"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												backgroundOrigin: "border-box",
												backgroundClip: "content-box, border-box",
												padding: "1px",
												fontSize: "14px",
												letterSpacing: "2%",
												fontWeight: 500,
												fontFamily: "Poppins",
												color: "#fff",
												zIndex: 1,
												"&:hover": {
													background:
														"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
												},
											}}
											onClick={() => disconnect()}
										>
											{" "}
											{account.slice(0, 4) + "..." + account.slice(-4)}
										</Button>
									) : (
										<Button
											sx={{
												width: "198px",
												height: "48px",
												borderRadius: "32px",

												border: "1px transparent",
												backgroundImage:
													"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												backgroundOrigin: "border-box",
												backgroundClip: "content-box, border-box",
												padding: "1px",
												fontSize: "14px",
												letterSpacing: "2%",
												fontWeight: 500,
												fontFamily: "Poppins",
												color: "#fff",
												zIndex: 1,
												"&:hover": {
													background:
														"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
												},
											}}
											onClick={() => connect()}
										>
											<img
												src={wallet}
												style={{ marginRight: "10px" }}
												alt=""
											/>{" "}
											Wallet connect
										</Button>
									)}
								</Box>
							</Box>
						</Hidden>

						<Hidden mdUp>
							{["left"].map((anchor) => (
								<React.Fragment key={anchor}>
									<IconButton onClick={toggleDrawer(anchor, true)}>
										<MenuIcon
											style={{
												fontSize: "38px",

												color: "#fff",
											}}
										></MenuIcon>
									</IconButton>

									<SwipeableDrawer
										classes={{ paper: classes.paper }}
										anchor={anchor}
										open={state[anchor]}
										onClose={toggleDrawer(anchor, false)}
										onOpen={toggleDrawer(anchor, true)}
									>
										{list(anchor)}
									</SwipeableDrawer>
								</React.Fragment>
							))}
						</Hidden>
					</Box>
				</Container>
			</Box>
		</Box>
	);
}
