import React from 'react';
import useDeviceDetect from '../isMobile'
import { Link } from 'react-router-dom';
import ArrowColor from '../../images/arrowColor.svg'
import './heading.scss';

const Heading = ({headingText, className, ViewAll, links}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <h3 className={`heading ${className}`}>
      {headingText}
      {!isMobile && ViewAll && <Link to={`/${links}`} className="fontW500 textGradient fontS wsN" tabindex="0">{ViewAll} <img src={ArrowColor} className="pR" alt="" /></Link> }
    </h3>
  );
}

export default Heading;
