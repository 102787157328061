import React from "react";
// import Bg from "../../images/Bg2.png";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
// import { Slide, Bounce } from "react-reveal";
import img from "../../images/daomaker1.png";
import bg1 from "../../images/background51.png";
import bg2 from "../../images/background52.png";

function Explore() {
	return (
		<Box
			py={10}
			sx={{
				background: `url(${bg1}),url(${bg2})`,
				backgroundPosition: "95% 0%, 90% -20%",
				backgroundRepeat: "no-repeat, no-repeat",
				backgroundSize: "auto,auto",
			}}
		>
			<Container maxWidth="lg">
				<Box
					sx={{
						width: "100%",
						height: "100%",
						backgroundClip:
							"content-box, border-box, border-box, border-box, border-box",
						borderRadius: "15px",
						// boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
						border: "1px solid transparent",
						backgroundImage:
							"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
						backgroundOrigin:
							"content-box, border-box, border-box, border-box, border-box",
						backgroundRepeat: "no-repeat",
						backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
						backgroundPosition:
							"top left, top left, top right, bottom left, bottom right",
					}}
				>
					<Grid container>
						<Grid item md={7}>
							<Box
								// bgcolor="#090E27"
								// height="100%"
								display="flex"
								flexDirection="column"
								justifyContent="center"
								p={5}
							>
								<Typography
									fontSize={{ xs: "30px", sm: "40px" }}
									fontWeight={700}
									color="text.primary"
									fontFamily="Josefin Sans"
									letterSpacing="2px"
								>
									Launch your project on META'S GATE
								</Typography>
								<Box py={3} textAlign="justify">
									<Typography
										variant="p"
										sx={{
											fontWeight: "400",
											fontSize: "20px",
											fontFamily: "Open Sans",
											lineHeight: "30px",
											color: "#7C7B97",
										}}
									>
										We screen and help best IDO and crypto projects to launch
										through our trusted launchpad platform. If you have a crypto
										project defined and need last round of investments, apply
										through META'S GATE.
									</Typography>
								</Box>
								<Button
									sx={{
										width: "220px",
										height: "48px",
										borderRadius: "25px",

										border: "1px transparent",
										backgroundImage:
											"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "1px",
										fontSize: "15px",
										letterSpacing: "2%",
										fontWeight: 600,
										fontFamily: "Roboto",
										color: "#fff",
										zIndex: 1,
										marginRight: "20px",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									<span>Launch Project Now</span>
								</Button>
							</Box>
						</Grid>
						<Grid item md={5} sx={{ width: "100%" }}>
							<Box
								sx={{
									width: "100%",
									// height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									// margin: 0,
									// padding: 0,
									// boxShadow: "5px 0px 10px 0px rgba(0, 0, 0, 0.3)",
									// overflow: "hidden",
								}}
							>
								<img
									src={img}
									alt="dao maker"
									style={{
										width: "250px",
										// maxWidth: "250px",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
				{/* <Grid container spacing={4}>
					<Grid
						item
						xs={12}
						md={6}
						display="flex"
						flexDirection="column"
						justifyContent="center"
					>
						<Bounce left>
							<Box py={3}>
								<Typography
									fontWeight="600"
									textAlign={{ xs: "center", md: "start" }}
									fontSize={{ xs: "35px", md: "40px" }}
									lineHeight="56px"
									letterSpacing="2px"
									color="#FFFFFF"
								>
									Launch your Project on META'S GATE
								</Typography>
							</Box>
							<Box>
								<Box>
									<Typography
										fontWeight="400"
										textAlign={"justify"}
										font-size="16px"
										lineHeight="32px"
										color="#7C7B97"
									>
										We screen and help best IDO and crypto projects to launch
										through our trusted launchpad platform. If you have a crypto
										project defined and need last round of investments, apply
										through META'S GATE.
									</Typography>
								</Box>
							</Box>
							<Box
								py={6}
								display="flex"
								justifyContent={{ xs: "center", md: "flex-start" }}
							>
								<Button
									fontWeight="500"
									fontSize="16px"
									textAlign={{ xs: "center", md: "start" }}
									lineHeight="24px"
									letterSpacing="0.02em"
									sx={{
										textTransform: "capitalize",
										width: "231.91px",
										color: "#FFFFFF",
										background:
											"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
										borderRadius: "56px",
									}}
								>
									<span> Apply for Launchapd</span>
								</Button>
							</Box>
						</Bounce>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box
							sx={{
								borderRadius: "32px",
								marginTop: "80px",
								marginRight: "16px",
							}}
							pt={3}
						>
							<Box
								mb={2}
								style={{
									borderRadius: "32px",
								}}
								py="3px"
								px="0px"
								// boxShadow="#043055 0px 0px 7px 1px"
								className="details-box hover"
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<span className="span"></span>
								<span className="span"></span>
								<span className="span"></span>
								<span className="span"></span>
								<img
									src={img}
									style={{
										borderRadius: "32px",
										width: "539.06px",
										height: "488.87px",
									}}
									alt=""
								/>
							</Box>
						</Box>
					</Grid>
				</Grid> */}

				{/* <Box py={5}>
					<Container maxWidth="lg">
						<Box
							sx={{
								backgroundImage: `url(${Bg})`,
								backgroundRepeat: "no-repeat",
								backgroundPosition: "center",
								backgroundBlendMode: "lighten",
							}}
							bgcolor="#11112e"
						>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								py={8}
							>
								<Slide top>
									<Box py={5} mt={2}>
										<Typography
											fontWeight="600"
											fontSize={{ xs: "35px", md: "40px" }}
											lineHeight="56px"
											textAlign="center"
											letterSpacing="2px"
											color="#FFFFFF"
										>
											What are you waiting for?
											<br /> explore now!
										</Typography>
									</Box>
								</Slide>
								<Slide bottom>
									<Box>
										<Button
											fontWeight="500"
											fontSize="16px"
											lineHeight="24px"
											letterSpacing="0.02em"
											sx={{
												textTransform: "capitalize",
												width: "200px",
												height: "48px",

												color: "#FFFFFF",
												background:
													"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
												borderRadius: "56px",
											}}
										>
											<span> Explore Artwork</span>
										</Button>
									</Box>
								</Slide>
							</Box>
						</Box>
					</Container>
				</Box> */}
			</Container>
		</Box>
	);
}

export default Explore;
