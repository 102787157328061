import {
	Button,
	Grid,
	IconButton,
	InputBase,
	Stack,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { useTokenDeployerContract } from "../../ConnectivityAss/hooks";
import { AppContext, url } from "../../utils";
// import InputBase from "../InputBase";
import { admin } from "../../ConnectivityAss/environment";
import { parseUnits } from "@ethersproject/units";
import tokenDeployerAbi from "../../ConnectivityAss/tokenDeolayerAbi.json";
import mintableAbi from "../../ConnectivityAss/mintableTokenAbi.json";
import tokenDeployerByte from "../../ConnectivityAss/AbiByte/simpleTokenByte.json";
import mintTokenByte from "../../ConnectivityAss/AbiByte/mintTokenByte.json";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";

function SimpleToken({ setloading }) {
	const mobile = useMediaQuery("(max-width:800px)");
	const { account, signer } = useContext(AppContext);
	// TOKEN INFORMATION
	const [ownerAddress, setOwnerAddress] = useState("");
	const [reffrelAddress, setRefrelAddress] = useState("");
	const [tokenSymbol, setTokenSymbol] = useState("");
	const [tokenDecimel, setTokenDecimel] = useState();
	const [tokenName, settokenName] = useState("");
	const [totalSupply, settotalSupply] = useState("");
	const [txhash, settxhash] = useState("");
	const [color, setColor] = useState("white");

	// Appprove Fee
	// const [adminFee, setAdminFee] = useState("");
	// const [feeToken, setfeeToken] = useState("");
	// const zeroAddress = "0x0000000000000000000000000000000000000000";
	//hooks contract

	// const tokenDeployerContract = useTokenDeployerContract(signer);

	useEffect(() => {
		if (window.location.href.includes("?ref=")) {
			let getAddress = window.location.href.split("?ref=")[1];
			let final = getAddress.slice(0, 42);

			setRefrelAddress(final.toString());
			localStorage.setItem("META_STRTER", final);
		}
		const init = async () => {
			let ref = await localStorage.getItem("META_STRTER");
			console.log("ref ==>", ref);
			setRefrelAddress(ref);
		};
		init();
	}, []);

	const createSimpleToken = async () => {
		if (!account) {
			toast.error("Error! Please connect your wallet .");
		} else if (!ownerAddress) {
			toast.error("Error! Please fill ownerAddress field");
		} else if (!tokenName) {
			toast.error("Error! Please fill tokenName field");
		} else if (!tokenSymbol) {
			toast.error("Error! Please fill tokenSymbol field");
		} else if (!tokenDecimel) {
			toast.error("Error! Please fill tokenDecimel field");
		} else if (!totalSupply) {
			toast.error("Error! Please fill totalSupply field");
		} else {
			try {
				setloading(true);

				const factory = new ethers.ContractFactory(
					new ethers.utils.Interface(tokenDeployerAbi),
					tokenDeployerByte,
					signer
				);

				// let refAddress = localStorage.getItem("META_STRTER");

				let fe = parseUnits("0.05");

				let data = [
					ownerAddress,
					tokenName,
					tokenSymbol,
					tokenDecimel,
					totalSupply,
					admin,
					fe,
					reffrelAddress?.length > 1 ? reffrelAddress : admin,
					fe,
				];
				let fees = "0.1";
				const contract = await factory.deploy(
					...[
						...data,
						// parseUnits(fe),
						{
							value: parseUnits(fees),
						},
					]
				);

				let tx = await contract.deployTransaction.wait();

				settxhash(tx.contractAddress);
				// --------------------minted token post------------------------

				let mintedPost = await axios.post(
					url + "MintedTokenRouter/mintedTokens",
					{
						ownerAddress: ownerAddress,
						tokenAddress: tx.contractAddress,
						tokenName: tokenName,
						tokenSymbol: tokenSymbol,
						totalSupply: totalSupply,
					}
				);

				// let refPost =
				//   reffrelAddress?.length > 1
				//     ? await axios.post(url + "RefRouter/refpost", {
				//         walletAddress: reffrelAddress,
				//         amount: 0.05,
				//       })
				//     : null;

				toast.success("Token Created Succssfully");
				setloading(false);
			} catch (error) {
				setloading(false);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				console.log("e", error);
			}
		}
	};

	const createMintableToken = async () => {
		console.log(
			ownerAddress,
			tokenName,
			tokenSymbol,
			tokenDecimel,
			totalSupply
			// adminFee
		);
		if (!account) {
			toast.error("Error! Please connect your wallet .");
		} else if (!ownerAddress) {
			toast.error("Error! Please fill ownerAddress field");
		} else if (!tokenName) {
			toast.error("Error! Please fill tokenName field");
		} else if (!tokenSymbol) {
			toast.error("Error! Please fill tokenSymbol field");
		} else if (!totalSupply) {
			toast.error("Error! Please fill totalSupply field");
		} else if (!tokenDecimel) {
			toast.error("Error! Please fill tokenDecimel field");
		}

		// else if (!feeToken) {
		//   toast.error("Fee not Fetched");
		// }
		else {
			try {
				setloading(true);

				const factory = new ethers.ContractFactory(
					new ethers.utils.Interface(mintableAbi),
					mintTokenByte,
					signer
				);
				console.log(factory, "abc");
				// let refAddress = localStorage.getItem("META_STRTER");

				let fe = parseUnits("0.05");

				let data = [
					ownerAddress,
					tokenName,
					tokenSymbol,
					tokenDecimel,
					totalSupply,
					admin,
					fe,
					reffrelAddress?.length > 1 ? reffrelAddress : admin,
					fe,
				];
				let fees = "0.1";
				const contract = await factory.deploy(
					...[
						...data,
						// parseUnits(fe),
						{
							value: parseUnits(fees),
						},
					]
				);
				let tx = await contract.deployTransaction.wait();

				// --------------------minted token post------------------------

				let mintedPost = await axios.post(
					url + "MintedTokenRouter/mintedTokens",
					{
						ownerAddress: ownerAddress,
						tokenAddress: tx.contractAddress,
						tokenName: tokenName,
						tokenSymbol: tokenSymbol,
						totalSupply: totalSupply,
					}
				);

				settxhash(tx.contractAddress);
				// let refPost =
				//   reffrelAddress?.length > 1
				//     ? await axios.post(url + "RefRouter/refpost", {
				//         walletAddress: reffrelAddress,
				//         amount: 0.05,
				//       })
				//     : null;

				toast.success("Token Created");
				setloading(false);
			} catch (error) {
				setloading(false);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
			}
		}
	};

	return (
		<div>
			<Grid container py={5} spacing={5}>
				<Grid item xs={12} md={6}>
					<Box
						my={mobile ? "10px" : "20px"}
						fontSize={{ md: "24px", sm: "18px" }}
						sx={{
							width: "100%",

							fontWeight: "700",
							textAlign: "left",
							color: "white",
							fontFamily: "josefin Sans",
						}}
					>
						Standard token Minting
					</Box>
					<Box
						sx={{
							width: "100%",
							// height: "100%",
							backgroundClip:
								"content-box, border-box, border-box, border-box, border-box",
							borderRadius: "15px",
							border: "1px solid transparent",
							backgroundImage:
								"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
							backgroundOrigin:
								"content-box, border-box, border-box, border-box, border-box",
							backgroundRepeat: "no-repeat",
							backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
							backgroundPosition:
								"top left, top left, top right, bottom left, bottom right",
						}}
					>
						<Box p={4}>
							{/* <Box
              mt={mobile ? "30px" : "40px"}
              fontSize={{ md: "26px", sm: "20px" }}
              sx={{
                width: "100%",
                fontFamily: "open sans",
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              Fee with Ref: 0.05 BNB
              <Box>Fee without Ref: 0.1 BNB</Box>
            </Box> */}

							<Box width={"100%"}>
								<Box color="#fff" my={1}>
									Owner*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setOwnerAddress(e.target.value)}
									placeholder={"Ex: 0xF4674B8eD8e9D140610C654095C5EFAE3805C95b"}
								/>
							</Box>
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Name*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => settokenName(e.target.value)}
									placeholder={"Ex: Ethereum"}
								/>
							</Box>
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Symbol*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setTokenSymbol(e.target.value)}
									placeholder={"Ex: ETH"}
								/>
							</Box>
							{/* <Box
              mt={mobile ? "30px" : "40px"}
              fontSize={{ md: "26px", sm: "20px" }}
              sx={{
                width: "100%",
                fontFamily: "open sans",
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              Total Supply (Excluding decimals e.g. 100 tokens)
            </Box> */}
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Supply*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => settotalSupply(e.target.value)}
									placeholder={"Ex: 100000000000000"}
								/>
								{+totalSupply > 100000000000000 ? (
									<Box color="red" fontSize="12px">
										100000000000000 is the maximum
									</Box>
								) : (
									""
								)}
							</Box>
							{/* <Box
              mt={mobile ? "30px" : "40px"}
              fontSize={{ md: "26px", sm: "20px" }}
              sx={{
                width: "100%",
                fontFamily: "open sans",
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              Decimals ( 8 - 18 reccomended )
            </Box> */}
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Decimals*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setTokenDecimel(e.target.value)}
									placeholder={"Ex: 18"}
								/>
								{+tokenDecimel > 18 || +tokenDecimel < 8 ? (
									<Box color="red" fontSize="12px" fontFamily="open sans">
										Please Enter Decimals Between 8 is min and 18 is max
									</Box>
								) : (
									""
								)}
							</Box>

							{/* <Box
              mt={mobile ? "30px" : "40px"}
              fontSize={{ md: "26px", sm: "20px" }}
              sx={{
                width: "100%",
                fontFamily: "open sans",
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              Total Supply ( Including Decimals - raw amounts )
            </Box> */}
							{/* <Box
              mt={mobile ? "35px" : "55px"}
              fontSize={{ md: "26px", sm: "20px" }}
              sx={{
                width: "100%",
                fontFamily: "open sans",
                fontWeight: "600",
                textAlign: "center",
                color: "white",
              }}
            >
              {+totalSupply && +totalSupply}
              {+totalSupply && tokenDecimel
                ? formatUnits(
                    totalSupply.toString(),
                    tokenDecimel.toString()
                  ).toLocaleString("fullwide", { useGrouping: false })
                : 0}
            </Box> */}

							{/* <Box
              mt={mobile ? "15px" : "20px"}
              fontSize={{ md: "22px", sm: "16px" }}
              sx={{
                width: "100%",
                fontFamily: "open sans",
                fontWeight: "500",
                textAlign: "center",
                color: "white",
              }}
            >
              + 0.3% total supply
            </Box> */}
							<Stack
								mt={2}
								direction={"row"}
								alignItems="center"
								justifyContent={"space-evenly"}
							>
								<Button
									onClick={createMintableToken}
									sx={{
										height: "55px",
										borderRadius: "56px",
										width: "200px",
										border: "2px transparent",
										backgroundImage:
											"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "2px",
										marginTop: "22px",
										fontSize: mobile ? "12px" : "20px",
										letterSpacing: "2%",
										fontWeight: 500,
										fontFamily: "Poppins",
										color: "#fff",
										textTransform: "capitalize",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									Mintable Tokens
								</Button>
								<Button
									onClick={createSimpleToken}
									sx={{
										height: "55px",
										borderRadius: "56px",
										width: "200px",
										border: "2px transparent",
										backgroundImage:
											"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "2px",
										marginTop: "22px",
										fontSize: mobile ? "12px" : "20px",
										letterSpacing: "2%",
										fontWeight: 500,
										fontFamily: "Poppins",
										color: "#fff",
										textTransform: "capitalize",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									Simple Tokens
								</Button>
							</Stack>
							<Box
								mt={2}
								fontSize={{ md: "16px", sm: "12px" }}
								sx={{
									width: "100%",
									fontFamily: "open sans",
									fontWeight: "600",
									textAlign: "center",
									color: "white",
								}}
							>
								{txhash?.length > 1 ? (
									<>
										{" "}
										Address :{txhash}
										<IconButton
											onClick={() => {
												if (color === "white") {
													setColor("lightblue");
												}
											}}
										>
											<CopyToClipboard
												text={txhash.toString()}
												onCopy={() => {
													if (account) {
														toast.success("Address Copied");
													}
												}}
											>
												<Box
													sx={{
														color: color,
														display: "flex",
														alignItems: "center",
													}}
												>
													<i class="fa-solid fa-copy"></i>
												</Box>
											</CopyToClipboard>
										</IconButton>
									</>
								) : (
									<> Create a token for Token Address </>
								)}
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box
						my={mobile ? "10px" : "20px"}
						fontSize={{ md: "24px", sm: "18px" }}
						sx={{
							width: "100%",
							fontFamily: "josefin Sans",
							fontWeight: "700",
							textAlign: "left",
							color: "white",
						}}
					>
						Standard token Features
					</Box>
					<Box
						color="#7C7B97"
						textAlign="justify"
						fontFamily="Open Sans"
						fontSize={"15px"}
					>
						The following characteristics are retained in the ERC20 token
						contracts created by the Metasgate.
					</Box>
					<Box
						component={"ul"}
						sx={{
							ml: 2,
							my: 2,
							color: "#7C7B97",
							textAlign: "justify",
							fontFamily: "Open Sans",
							fontSize: "15px",
							listStyleType: "disc",
							listStylePosition: "outside",
						}}
					>
						<li>
							<b>Total supply </b>
							Total amount of tokens that have been minted
						</li>
						<li>
							<b>Approve </b>
							helps to rule out the prospect of receiving favors producing queer
							tokens.
						</li>
						<li>
							<b>Balance of </b>
							Users can check their balances.
						</li>
						<li>
							<b>Transfer from </b>
							enables the automation of transactions.
						</li>
						<li>
							<b>Transfer </b>
							to allow the transfer of tokens between addresses.
						</li>
						<li>
							<b>Allowance </b>
							enables the transaction to be cancelled if the user has not enough
							money in their account.
						</li>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
}

export default SimpleToken;
