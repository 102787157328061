import React, { useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import { Container, InputBase, NativeSelect, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SimpleToken from "./SimpleToken";
import DeflationaryToken from "./DeflationaryToken";
import "./token.css";
import Loading from "../../loading";
import { styled } from "@mui/styles";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: "#090e27",
		fontSize: 16,
		boxShadow: "0 0 10px 0 rgba(0,0,0,.25)",
		padding: "10px 26px 10px 12px",
	},
	"& .MuiInputBase-input:not([multiple]) option": {
		backgroundColor: "#090e27 !important",
		margin: "100px",
	},
}));

function Token() {
	const [value, setValue] = React.useState("simpleToken");
	const [loading, setloading] = useState(false);

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	useEffect(() => {
		console.log(value);
	}, [value]);
	return (
		<Box pt={12} pb={10}>
			<Loading loading={loading} />
			<Container maxWidth="lg">
				<Typography
					fontSize={{ xs: "30px", sm: "40px" }}
					fontWeight={700}
					fontFamily="Josefin Sans"
					color="text.primary"
					textAlign="center"
					pb={2}
				>
					Total 0 tokens created on Meta'sGate
				</Typography>
				<Typography
					fontSize={{ xs: "16px", sm: "20px" }}
					fontWeight={400}
					fontFamily="open Sans"
					color="#7C7B97"
					textAlign="center"
					pb={4}
				>
					Use Metasgate Token Factory to create your own token in seconds!.
				</Typography>
				<FormControl
					sx={{
						width: "100%",
						boxShadow: " 0 0 10px 2px #00002050",
						borderRadius: "12px",
					}}
					color="#ffffff"
					variant="dark"
				>
					<NativeSelect
						sx={{
							"& svg": {
								color: "#fff",
							},
						}}
						value={value}
						onChange={handleChange}
						input={<BootstrapInput />}
					>
						<option
							//   style={{ padding: "40px !important" }}
							value={"simpleToken"}
						>
							Standard Token
						</option>
						<option value={"deflationaryToken"}>Deflationary Token</option>
					</NativeSelect>
				</FormControl>

				<Box>
					{value === "simpleToken" ? (
						<SimpleToken setloading={setloading} />
					) : (
						<DeflationaryToken setloading={setloading} />
					)}
				</Box>
			</Container>
		</Box>
	);
}

export default Token;
