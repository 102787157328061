import React from "react";
import {
	Box,
	Container,
	Divider,
	Stack,
	TableCell,
	useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTokenLockerContract } from "../../ConnectivityAss/hooks";
import { useContext, useEffect, useState } from "react";
import { AppContext, url } from "../../utils";
import axios from "axios";
import { formatUnits } from "@ethersproject/units";
import moment from "moment";
import Loader from "../../loading";

export default function LockTokenRecord() {
	const { id } = useParams();
	const matches = useMediaQuery("(max-width:750px)");

	///////////// Connectivity ////////////
	const { signer } = useContext(AppContext);
	const tokenLockerContract = useTokenLockerContract(signer);
	let totalLockedAmount = 0;
	const [totalAmount, setTotalAmount] = useState(0);
	const [loading, setloading] = useState(false);
	const [userLockedTokensInfo, setuserLockedTokensInfo] = useState([]);
	const [tokenDetails, settokenDetails] = useState({
		tokenDecimals: "-",
		tokenAddress: "-",
		tokenName: "-",
		tokenSymbol: "-",
	});

	const init = async () => {
		setloading(true);
		try {
			const {
				data: {
					data: {
						account,
						tokenDecimals,
						tokenAddress,
						tokenName,
						tokenSymbol,
						_id,
					},
				},
			} = await axios.get(`${url}tokenRouter/getToken/${id}`);
			let object = { tokenDecimals, tokenAddress, tokenName, tokenSymbol, _id };
			settokenDetails(object);
			let tokenInfo = [];
			const count = await tokenLockerContract.getUserLocksForTokenLength(
				account,
				tokenAddress
			);
			for (let j = 0; j < +count; j++) {
				const lockID = await tokenLockerContract.getUserLockIDForTokenAtIndex(
					account,
					tokenAddress,
					j.toString()
				);

				const lockDetail = await tokenLockerContract.getLock(lockID.toString());
				let obj = {
					lockID: lockID.toString(),
					claimableamount: lockDetail[2].toString(),
					claimedamount: lockDetail[3].toString(),
					totalamount: lockDetail[4].toString(),
					unlockDate: lockDetail[7].toString(),
					owner: account,
				};
				tokenInfo.push(obj);
				totalLockedAmount += +formatUnits(
					lockDetail[2].toString(),
					tokenDecimals.toString()
				);
			}
			setuserLockedTokensInfo(tokenInfo);
			setTotalAmount(totalLockedAmount);
			setloading(false);
		} catch (error) {
			console.log(error);
			setloading(false);
		}
	};
	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	return (
		<Box py={15}>
			<Loader loading={loading} />
			<Container maxWidth="md">
				<Box
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						py={2}
						px={2}
						color="#fff"
						textAlign="left"
						fontSize={matches ? "16px" : "20px"}
						fontWeight="600"
					>
						Lock Info
					</Box>
					<Divider color="#383A41" />
					<Container maxWidth="lg">
						<Box py={2}>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								fontSize={matches ? "13px" : "17px"}
								fontWeight="500"
							>
								<Box>Total Amount Locked</Box>
								<Box>{+totalAmount}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								fontSize={matches ? "13px" : "17px"}
								fontWeight="500"
							>
								<Box>Token Address</Box>
								<Box
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									<a
										style={{ color: "#a6cfff" }}
										href={`https://testnet.bscscan.com/address/${tokenDetails.tokenAddress}`}
										target="_blank"
										rel="noreferrer"
									>
										{matches
											? tokenDetails.tokenAddress.slice(0, 5) +
											  "..." +
											  tokenDetails.tokenAddress.slice(-4)
											: tokenDetails.tokenAddress}
									</a>
								</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								fontSize={matches ? "13px" : "17px"}
								fontWeight="500"
							>
								<Box>Token Name</Box>
								<Box> {tokenDetails.tokenName}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								fontSize={matches ? "13px" : "17px"}
								fontWeight="500"
							>
								<Box>Token Symbol</Box>
								<Box>{tokenDetails.tokenSymbol}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								fontSize={matches ? "13px" : "17px"}
								fontWeight="500"
							>
								<Box>Token Decimals</Box>
								<Box>{tokenDetails.tokenDecimals}</Box>
							</Stack>
						</Box>
					</Container>
				</Box>

				<Box
					my={2}
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						fontSize={matches ? "16px" : "20px"}
						fontWeight="600"
						py={2}
						px={2}
						color="#fff"
						textAlign="left"
					>
						Lock Records
					</Box>
					<Divider color="#383A41" />
					<Container maxWidth="lg">
						<TableContainer>
							<Table sx={{ minWidth: 600 }}>
								<TableHead>
									<TableRow>
										<TableCell
											sx={{
												color: "#fff",
												fontWeight: "600",

												fontSize: "16px",
											}}
										>
											Address
										</TableCell>
										<TableCell
											align="center"
											sx={{
												color: "#fff",
												fontWeight: "600",

												fontSize: "16px",
											}}
										>
											Amount
										</TableCell>
										<TableCell
											align="center"
											sx={{
												color: "#fff",
												fontWeight: "600",

												fontSize: "16px",
											}}
										>
											Time
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{userLockedTokensInfo.length > 0 ? (
										userLockedTokensInfo.map(
											(
												{ claimableamount, owner, unlockDate, lockID },
												index
											) => (
												<TableRow
													key={index}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<TableCell
														component="th"
														scope="row"
														sx={{
															color: "#fff",
															fontWeight: "300",

															fontSize: matches ? "13px" : "16px",
														}}
													>
														<a
															style={{ color: "#a6cfff" }}
															href={`https://testnet.bscscan.com/address/${owner}`}
															target="_blank"
															rel="noreferrer"
														>
															{matches
																? owner.slice(0, 5) + "..." + owner.slice(-4)
																: owner}
														</a>
													</TableCell>
													<TableCell
														align="center"
														sx={{
															color: "#fff",
															fontWeight: "300",

															fontSize: matches ? "13px" : "16px",
														}}
													>
														{formatUnits(
															claimableamount,
															tokenDetails.tokenDecimals
														)}
													</TableCell>
													<TableCell
														align="center"
														sx={{
															color: "#fff",
															fontWeight: "300",

															fontSize: matches ? "13px" : "16px",
														}}
													>
														{moment.unix(unlockDate).format("LL")}
													</TableCell>
												</TableRow>
											)
										)
									) : loading ? (
										loading
									) : (
										<TableRow>
											<TableCell
												align="center"
												style={{
													color: "#ffffff",
													fontSize: "14px",
												}}
												colSpan={5}
											>
												There is no Lock Token yet
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Container>
				</Box>
			</Container>
		</Box>
	);
}
