import { parseUnits } from "@ethersproject/units";
import {
	Button,
	Grid,
	IconButton,
	InputBase,
	Stack,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { AppContext } from "../../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ethers } from "ethers";
import deflationaryAbi from "../../ConnectivityAss/deflationaryTokenAddress.json";
import DeflationaryByte from "../../ConnectivityAss/AbiByte/deflationaryTokenByte.json";

import { admin } from "../../ConnectivityAss/environment";

function DeflationaryToken({ setloading }) {
	const mobile = useMediaQuery("(max-width:800px)");
	const { account, signer } = useContext(AppContext);

	// TOKEN INFORMATION
	const [ownerAddress, setOwnerAddress] = useState("");
	const [reffrelAddress, setRefrelAddress] = useState("");
	const [tokenSymbol, setTokenSymbol] = useState("");
	const [tokenDecimel, setTokenDecimel] = useState(18);
	const [tokenName, settokenName] = useState("");
	const [totalSupply, settotalSupply] = useState("");
	const [txhash, settxhash] = useState("");
	const [taxFee, settaxFee] = useState("30");
	const [ipFee, setipFee] = useState("30");
	const [color, setColor] = useState("white");
	// Appprove Fee

	//hooks contract

	// const tokenDeployerContract = useDeflationaryTokenContract(signer);

	const init = async () => {
		try {
			// setloading(true);
			// let fee = await tokenDeployerContract.adminFee();
			// setAdminFee(fee.toString());
			// // console.log("fee", fee.toString());
			// let feeToken = await tokenDeployerContract.refFee();
			// console.log("feeToken", feeToken.toString());
			// setfeeToken(feeToken.toString());
			// setloading(false);
		} catch (error) {
			setloading(false);
			console.log(error, "error");
		}
	};
	useEffect(() => {
		if (window.location.href.includes("?ref=")) {
			let getAddress = window.location.href.split("?ref=")[1];
			let final = getAddress.slice(0, 42);

			setRefrelAddress(final.toString());
			localStorage.setItem("META_STRTER", final);
		}
		const init = async () => {
			let ref = await localStorage.getItem("META_STRTER");
			console.log("ref ==>", ref);
			setRefrelAddress(ref);
		};
		init();
	}, []);

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	const createToken = async () => {
		if (!account) {
			toast.error("Error! Please connect your wallet .");
		} else if (!ownerAddress) {
			toast.error("Error! Please fill ownerAddress field");
		} else if (!tokenName) {
			toast.error("Error! Please fill tokenName field");
		} else if (!tokenSymbol) {
			toast.error("Error! Please fill tokenSymbol field");
		} else if (!totalSupply) {
			toast.error("Error! Please fill totalSupply field");
		} else if (!tokenDecimel) {
			toast.error("Error! Please fill tokenDecimel field");
		} else if (!taxFee) {
			toast.error("Error! Please fill taxFee field");
		} else if (!ipFee) {
			toast.error("Error! Please fill ipFee field");
		} else {
			try {
				setloading(true);

				const factory = new ethers.ContractFactory(
					new ethers.utils.Interface(deflationaryAbi),
					DeflationaryByte,
					signer
				);
				// let refAddress = localStorage.getItem("META_STRTER");

				let fe = parseUnits("0.05");

				let data = [
					ownerAddress,
					tokenName,
					tokenSymbol,
					tokenDecimel,
					totalSupply,
					taxFee,
					ipFee,
					admin,
					fe,
					reffrelAddress?.length > 1 ? reffrelAddress : admin,
					fe,
				];
				let fees = "0.1";
				const contract = await factory.deploy(
					...[
						...data,
						// parseUnits(fe),
						{
							value: parseUnits(fees),
						},
					]
				);
				let tx = await contract.deployTransaction.wait();

				settxhash(tx.contractAddress);
				// --------------------minted token post------------------------

				// let mintedPost = await axios.post(
				// 	url + "MintedTokenRouter/mintedTokens",
				// 	{
				// 		ownerAddress: ownerAddress,
				// 		tokenAddress: tx.contractAddress,
				// 		tokenName: tokenName,
				// 		tokenSymbol: tokenSymbol,
				// 		totalSupply: totalSupply,
				// 	}
				// );

				// let refPost =
				// 	reffrelAddress?.length > 1
				// 		? await axios.post(url + "RefRouter/refpost", {
				// 				walletAddress: reffrelAddress,
				// 				amount: 0.05,
				// 		  })
				// 		: null;

				toast.success("Token Created");
				setloading(false);
			} catch (error) {
				setloading(false);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				console.log("e", error);
			}
		}
	};

	return (
		<div>
			<Grid container py={5} spacing={5}>
				<Grid item xs={12} md={6}>
					<Box
						my={mobile ? "10px" : "20px"}
						fontSize={{ md: "22px", sm: "16px" }}
						sx={{
							width: "100%",
							fontFamily: "josefin sans",
							fontWeight: "600",
							textAlign: "left",
							color: "white",
						}}
					>
						Deflationary token Minting
					</Box>
					<Box
						sx={{
							width: "100%",
							// height: "100%",
							backgroundClip:
								"content-box, border-box, border-box, border-box, border-box",
							borderRadius: "15px",
							border: "1px solid transparent",
							backgroundImage:
								"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
							backgroundOrigin:
								"content-box, border-box, border-box, border-box, border-box",
							backgroundRepeat: "no-repeat",
							backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
							backgroundPosition:
								"top left, top left, top right, bottom left, bottom right",
						}}
					>
						<Box p={4}>
							{/* <Box
				mt={mobile ? "30px" : "40px"}
				fontSize={{ md: "26px", sm: "20px" }}
				sx={{
					width: "100%",
					fontFamily: "open sans",
					fontWeight: "600",
					textAlign: "center",
					color: "white",
				}}
			>
				Fee with Ref: 0.05 BNB
				<Box>Fee without Ref: 0.1 BNB</Box>
			</Box> */}

							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Owner*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setOwnerAddress(e.target.value)}
									placeholder={"Ex: 0xF4674B8eD8e9D140610C654095C5EFAE3805C95b"}
								/>
							</Box>
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Name*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => settokenName(e.target.value)}
									placeholder={"Ex: Ethereum"}
								/>
							</Box>
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Symbol*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setTokenSymbol(e.target.value)}
									placeholder={"Ex: ETH"}
								/>
							</Box>

							{/* <Box
        mt={mobile ? "30px" : "40px"}
        fontSize={{ md: "26px", sm: "20px" }}
        sx={{
          width: "100%",
          fontFamily: "open sans",
          fontWeight: "600",
          textAlign: "center",
          color: "white",
        }}
      >
        Total Supply (Excluding decimals e.g. 100 tokens)
      </Box> */}
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Supply*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => settotalSupply(e.target.value)}
									placeholder={"Token supply"}
								/>
								{+totalSupply > 100000000000000 ? (
									<Box color="red" fontSize="12px" fontFamily="open sans">
										100000000000000 is the maximum
									</Box>
								) : (
									""
								)}
							</Box>
							{/* <Box
        mt={mobile ? "30px" : "40px"}
        fontSize={{ md: "26px", sm: "20px" }}
        sx={{
          width: "100%",
          fontFamily: "open sans",
          fontWeight: "600",
          textAlign: "center",
          color: "white",
        }}
      >
        Decimals ( 8 - 18 reccomended )
      </Box> */}
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Decimals*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setTokenDecimel(e.target.value)}
									placeholder={"Ex: 18"}
								/>
								{+tokenDecimel > 18 || +tokenDecimel < 8 ? (
									<Box color="red" fontSize="12px" fontFamily="open sans">
										Please Enter Decimals Between 8 is min and 18 is max
									</Box>
								) : (
									""
								)}
							</Box>
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Tax*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => settaxFee(e.target.value)}
									placeholder={"Tax Fee"}
								/>
								{+taxFee + +ipFee > 25 || +taxFee + +ipFee < 0 ? (
									<Box color="red" fontSize="12px" fontFamily="open sans">
										Please Enter IP Fee less than 25% (max){" "}
									</Box>
								) : (
									""
								)}
							</Box>
							{/* <Box
        mt={mobile ? "30px" : "40px"}
        fontSize={{ md: "26px", sm: "20px" }}
        sx={{
          width: "100%",
          fontFamily: "open sans",
          fontWeight: "600",
          textAlign: "center",
          color: "white",
        }}
      >
        Fees the will be provided to the token holders it includes liquidity
        fee.
      </Box> */}
							<Box mt={1} width={"100%"}>
								<Box my={1} color="#fff">
									Ip*
								</Box>
								<InputBase
									sx={{
										bgcolor: "#090e27",
										width: "100%",
										padding: " 0px 10px",
										borderRadius: " 5px",
										fontFamily: "open sans",
									}}
									onChange={(e) => setipFee(e.target.value)}
									placeholder={"IP Fee"}
								/>
								{+taxFee + +ipFee > 25 || +taxFee + +ipFee < 0 ? (
									<Box color="red" fontSize="12px" fontFamily="open sans">
										Please Enter IP Fee less than 25% (max){" "}
									</Box>
								) : (
									""
								)}
							</Box>
							<Stack
								mt={2}
								direction={"row"}
								alignItems="center"
								justifyContent={"space-evenly"}
							>
								<Button
									onClick={createToken}
									sx={{
										padding: "10px 20px",
										background:
											"linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
										boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
										borderRadius: "56px",
										color: "white",
										fontFamily: "Poppins",
										fontWeight: "500",
										fontSize: mobile ? "12px" : "18px",
										"&:hover": {
											color: "#8454EB",
											background: " white",
										},
										"&:active": {
											color: "#8454EB",
											background: " white",
										},
									}}
								>
									Deflationary token
								</Button>
							</Stack>
							<Box
								mt={2}
								fontSize={{ md: "16px", sm: "12px" }}
								sx={{
									width: "100%",
									fontFamily: "open sans",
									fontWeight: "600",
									textAlign: "center",
									color: "white",
								}}
							>
								{txhash.length > 1 ? (
									<>
										{" "}
										Address :{txhash}
										<IconButton
											onClick={() => {
												if (color === "white") {
													setColor("lightblue");
												}
											}}
										>
											<CopyToClipboard
												text={txhash.toString()}
												onCopy={() => {
													if (account) {
														toast.success("Address Copied");
													}
												}}
											>
												<Box
													sx={{
														color: color,
														display: "flex",
														alignItems: "center",
													}}
												>
													<i class="fa-solid fa-copy"></i>
												</Box>
											</CopyToClipboard>
										</IconButton>
									</>
								) : (
									<> Creat a token for Token Address </>
								)}
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box
						my={mobile ? "10px" : "20px"}
						fontSize={{ md: "22px", sm: "16px" }}
						sx={{
							width: "100%",
							fontFamily: "josefin sans",
							fontWeight: "600",
							textAlign: "left",
							color: "white",
						}}
					>
						Deflationary token Features
					</Box>
					<Box
						color="#7C7B97"
						textAlign="justify"
						fontFamily="open sans"
						fontSize={"15px"}
					>
						Select deflationary token. Write the owner's address, then the token
						name and token symbol. Write the total supply, The total supply must
						exclude decimals, e.g., 10000 tokens. Then the decimals option must
						be filled in as recommended for 8-18. The tax fee +LP fee must be
						25% and must not exceed 25%, Click on Deflationary Token Button.
					</Box>
				</Grid>
			</Grid>
		</div>
	);
}

export default DeflationaryToken;
