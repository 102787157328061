import React from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import wallet1 from "../../images/wallet4.png";
import wallet2 from "../../images/wallet2.png";
import wallet3 from "../../images/wallet3.png";
import line from "../../images/Line1.png";
import { Slide } from "react-reveal";

function LaunchIDO() {
	return (
		<Box py={10}>
			<Container>
				<Box
					fontSize={{ xs: "30px", sm: "40px" }}
					fontWeight={700}
					color="text.primary"
					letterSpacing="2px"
					textAlign="center"
					fontFamily="Josefin Sans"
					mb={5}
				>
					Participate in IDO
				</Box>

				<Box
					position="relative"
					top="50px"
					sx={{ zIndex: -1 }}
					width="100%"
					pl={{ lg: 17, md: 15 }}
					// pr={13}
					display={{ xs: "none", sm: "none", md: "block" }}
				>
					<img src={line} alt="" />
				</Box>
				<Grid
					container
					spacing={{ xs: 0, sm: 4 }}
					justifyContent={{ xs: "center", sm: "space-between" }}
				>
					<Grid xs={12} sm={11} md={3} sx={{ marginTop: "30px" }}>
						<Box
							display="flex"
							justifyContent="center"
							flexDirection="column"
							alignItems="center"
						>
							<img src={wallet1} width="90px" alt="" />
							<Slide top>
								<Box
									fontSize={{ xs: "20px", sm: "24px" }}
									fontWeight={600}
									color="text.primary"
									fontFamily="Josefin Sans"
									my={2}
								>
									Connect Wallet
								</Box>
								<Box
									fontSize={{ xs: "16px", sm: "16px" }}
									fontWeight={400}
									color="#7C7B97"
									fontFamily="open Sans"
									textAlign="center"
									lineHeight="32px"
								>
									To participate in an IDO, simply connect your wallet to the Binance Smart Chain network.
								</Box>
							</Slide>
							<Slide bottom>
								<Button
									sx={{
										height: "48px",
										borderRadius: "56px",
										width: "150px",
										border: "2px transparent",
										backgroundImage:
											"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "2px",
										marginTop: "22px",
										fontSize: "14px",
										letterSpacing: "2%",
										fontWeight: 500,
										fontFamily: "Poppins",
										color: "#fff",
										textTransform: "capitalize",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									Learn More
								</Button>
							</Slide>
						</Box>
					</Grid>
					<Grid xs={12} sm={11} md={3} sx={{ marginTop: "30px" }}>
						<Box
							display="flex"
							justifyContent="center"
							flexDirection="column"
							alignItems="center"
						>
							<img src={wallet2} width="90px" alt="" />
							<Slide top>
								<Box
									fontSize={{ xs: "20px", sm: "24px" }}
									fontWeight={600}
									color="text.primary"
									fontFamily="Josefin Sans"
									my={2}
								>
									Buy Token
								</Box>
								<Box
									fontSize={{ xs: "16px", sm: "16px" }}
									fontWeight={400}
									color="#7C7B97"
									fontFamily="open Sans"
									textAlign="center"
									lineHeight="32px"
								>
									All you need is USDT in your wallet, simply click the 'Buy' button to purchase tokens.
								</Box>
							</Slide>

							<Slide bottom>
								<Button
									sx={{
										height: "48px",
										borderRadius: "56px",
										width: "150px",
										border: "2px transparent",
										backgroundImage:
											"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "2px",
										marginTop: "22px",
										fontSize: "14px",
										letterSpacing: "2%",
										fontWeight: 500,
										fontFamily: "Poppins",
										color: "#fff",
										textTransform: "capitalize",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									Learn More
								</Button>
							</Slide>
						</Box>
					</Grid>
					<Grid xs={12} sm={11} md={3} sx={{ marginTop: "30px" }}>
						<Box
							display="flex"
							justifyContent="center"
							flexDirection="column"
							alignItems="center"
						>
							<img src={wallet3} width="90px" alt="" />
							<Slide top>
								<Box
									fontSize={{ xs: "20px", sm: "24px" }}
									fontWeight={600}
									color="text.primary"
									fontFamily="josefin Sans"
									my={2}
								>
									Claim Tokens
								</Box>
								<Box
									fontSize={{ xs: "16px", sm: "16px" }}
									fontWeight={400}
									color="#7C7B97"
									fontFamily="open Sans"
									textAlign="center"
									lineHeight="32px"
								>
									You can claim your tokens once the project launcher has finalized the Presale.
								</Box>
							</Slide>

							<Slide bottom>
								<Box>
									<Button
										sx={{
											height: "48px",
											borderRadius: "56px",
											width: "150px",
											border: "2px transparent",
											backgroundImage:
												"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
											backgroundOrigin: "border-box",
											backgroundClip: "content-box, border-box",
											padding: "2px",
											marginTop: "22px",
											fontSize: "14px",
											letterSpacing: "2%",
											fontWeight: 500,
											fontFamily: "Poppins",
											color: "#fff",
											textTransform: "capitalize",
											"&:hover": {
												background:
													"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
											},
										}}
									>
										Learn More
									</Button>
								</Box>
							</Slide>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default LaunchIDO;
