import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import RMTL from "../../images/RMTL.png";
import RMBL from "../../images/RMBL.png";
import bg from "../../images/background4.png";

export default function Roadmap() {
	return (
		<>
			<Box
				py={10}
				sx={{
					background: `url(${bg})`,
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}
			>
				<Typography
					fontSize={{ xs: "30px", sm: "40px" }}
					fontWeight={700}
					fontFamily="Josefin Sans"
					color="text.primary"
					textAlign="center"
				>
					Plan of action
				</Typography>
				<Typography
					sx={{
						textTransform: "uppercase",
						textAlign: "center",
						fontSize: "20px",
						color: "#667394",
					}}
					pt={2}
				>
					HOW WE ARE BUILDING THE FUTURE OS ECONTRACT
				</Typography>
				<hr
					style={{
						width: "20%",
						border: "2px transparent",
						backgroundImage:
							"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
						backgroundOrigin: "border-box",
						backgroundClip: "content-box, border-box",
						padding: "1px",
					}}
				/>
				<Container maxWidth="lg">
					<Grid container py={8}>
						<Grid item xs={6}>
							<Box
								sx={{
									position: "relative",
									width: "60%",
									height: "100%",
									backgroundClip:
										"content-box, border-box, border-box, border-box, border-box",
									borderRadius: "15px",
									boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
									border: "1px solid transparent",
									backgroundImage:
										"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
									backgroundOrigin:
										"content-box, border-box, border-box, border-box, border-box",
									backgroundRepeat: "no-repeat",
									backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
									backgroundPosition:
										"top left, top left, top right, bottom left, bottom right",
								}}
							>
								<Box
									sx={{
										textAlign: "left",
									}}
									p={5}
								>
									<Box
										sx={{
											fontFamily: "Josefin Sans",
											fontStyle: "normal",
											fontWeight: "700",
											fontSize: "24px",
											lineHeight: "24px",
											color: "#fff",
										}}
									>
										Q1 2022
									</Box>
									<Box
										sx={{
											fontFamily: "Open Sans",
											fontStyle: "normal",
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "20px",
											color: "#7C7B97",
										}}
										pt={2}
									>
										<ul style={{ listStyleType: "disc" }}>
											<li>
											Market Research 
											</li>
											<li>
												Created Idea
											</li>
											<li>
												Creating Framework
											</li>
											<li>
												Implemented the Idea
											</li>
											<li>
												Hired Team
											</li>
											<li>
												Smart Contracts Development
											</li>
											<li>
												Deployment on Testnet
											</li>
											<li>
												Project Designing
											</li>
											<li>
												Frontend Development
											</li>
											<li>
												Backend Development
											</li>
											<li>
												Tested & Bugs Fixing
											</li>
										</ul>
									</Box>
								</Box>
								<Box height="120%" sx={{ position: "absolute", top: "20%" }}>
									<img src={RMTL} alt="" />
								</Box>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								sx={{
									position: "relative",
									width: "60%",
									height: "100%",
									backgroundClip:
										"content-box, border-box, border-box, border-box, border-box",
									borderRadius: "15px",
									boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
									border: "1px solid transparent",
									backgroundImage:
										"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
									backgroundOrigin:
										"content-box, border-box, border-box, border-box, border-box",
									backgroundRepeat: "no-repeat",
									backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
									backgroundPosition:
										"top left, top left, top right, bottom left, bottom right",
								}}
							>
								<Box
									sx={{
										textAlign: "left",
									}}
									p={5}
								>
									<Box
										sx={{
											fontFamily: "Josefin Sans",
											fontStyle: "normal",
											fontWeight: "700",
											fontSize: "24px",
											lineHeight: "24px",
											color: "#fff",
										}}
									>
										Q2 2023
									</Box>
									<Box
										sx={{
											fontFamily: "Open Sans",
											fontStyle: "normal",
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "20px",
											color: "#7C7B97",
										}}
										pt={2}
									>
										<ul style={{ listStyleType: "disc" }}>
											<li>
												Social Channels Live
											</li>
											<li>
												Hired Marketing Team
											</li>
											<li>
												Article Publishing
											</li>
											<li>
												Influencer Campaign
											</li>
											<li>
												Ad Campaign
											</li>
											<li>
												MetasGate Token (MOLS) Presale
											</li>
											<li>
												MOLS Launching On DEX & CEX
											</li>
											<li>
												MOLS Buy Back Brun Feature Activation
											</li>
										</ul>
									</Box>
								</Box>
								<Box height="120%" sx={{ position: "absolute", top: "20%" }}>
									<img src={RMTL} alt="" />{" "}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<Box
					ml="auto"
					width={{ lg: "95%" }}
					style={{
						border: "2px transparent",
						backgroundImage:
							"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
						backgroundOrigin: "border-box",
						backgroundClip: "content-box, border-box",
						padding: "1px",
					}}
				></Box>
				<Container maxWidth="lg">
					<Grid container py={8.7}>
						<Grid item xs={6}>
							<Box
								ml="auto"
								sx={{
									position: "relative",
									width: "60%",
									height: "100%",
									backgroundClip:
										"content-box, border-box, border-box, border-box, border-box",
									borderRadius: "15px",
									boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
									border: "1px solid transparent",
									backgroundImage:
										"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
									backgroundOrigin:
										"content-box, border-box, border-box, border-box, border-box",
									backgroundRepeat: "no-repeat",
									backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
									backgroundPosition:
										"top left, top left, top right, bottom left, bottom right",
								}}
							>
								<Box
									sx={{
										textAlign: "left",
									}}
									p={5}
								>
									<Box
										sx={{
											fontFamily: "Josefin Sans",
											fontStyle: "normal",
											fontWeight: "700",
											fontSize: "24px",
											lineHeight: "24px",
											color: "#fff",
										}}
									>
										Q1 2023
									</Box>
									<Box
										sx={{
											fontFamily: "Open Sans",
											fontStyle: "normal",
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "20px",
											color: "#7C7B97",
										}}
										pt={2}
									>
										<ul style={{ listStyleType: "disc" }}>
											<li>
												Deployment On Mainnet
											</li>
											<li>
												Guide Book
											</li>
											<li>
												Documentation of Project
											</li>
											<li>
												Aduit Of Smart Contracts
											</li>
											<li>
												Launchpad Live
											</li>
											<li>
												Token Minter Live
											</li>
											<li>
												Token Locker Live
											</li>
											<li>
												Liquidity Loocker Live
											</li>
											<li>
												Share to Earn Live
											</li>
											<li>
												MetasGate (Mols) Token Deployment
											</li>
										
											<li>
												Website Live
											</li>
											</ul>
									</Box>
								</Box>
								<Box height="120%" sx={{ position: "absolute", bottom: "20%" }}>
									<img src={RMBL} alt="" />
								</Box>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								ml="auto"
								sx={{
									position: "relative",
									width: "60%",
									height: "100%",
									backgroundClip:
										"content-box, border-box, border-box, border-box, border-box",
									borderRadius: "15px",
									boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
									border: "1px solid transparent",
									backgroundImage:
										"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
									backgroundOrigin:
										"content-box, border-box, border-box, border-box, border-box",
									backgroundRepeat: "no-repeat",
									backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
									backgroundPosition:
										"top left, top left, top right, bottom left, bottom right",
								}}
							>
								<Box
									sx={{
										textAlign: "left",
									}}
									p={5}
								>
									<Box
										sx={{
											fontFamily: "Josefin Sans",
											fontStyle: "normal",
											fontWeight: "700",
											fontSize: "24px",
											lineHeight: "24px",
											color: "#fff",
										}}
									>
										Q4 2023
									</Box>
									<Box
										sx={{
											fontFamily: "Open Sans",
											fontStyle: "normal",
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "20px",
											color: "#7C7B97",
										}}
										pt={2}
									>
										<ul style={{ listStyleType: "disc" }}>
											<li>
												Marketing Campaign
											</li>
											<li>
												 Multi Chain Deployment
											</li>
											<li>
												NFT Marketplace Development
											</li>
											<li>
												Staking Pool Development
											</li>
											<li>
												Exchange Development
											</li>
											<li>
												Airdrop Compaign Development
											</li>
											<li>
												 Bridge Development
											</li>
											<li>
												 CEX Listings
											</li>
											<li>
												 Ad Compaing
											</li>
											<li>
												 App Development
											</li>
											<li>
												 Play to Earn Games Development
											</li>
											<li>
												 Blockchain Development
											</li>
											<li>
												 Partnerships
											</li>
											<li>
												Disclosing Later!
											</li>
										</ul>
									</Box>
								</Box>
								<Box height="120%" sx={{ position: "absolute", bottom: "20%" }}>
									<img src={RMBL} alt="" />
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
}
