import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import RMLL from "../../images/RMLL.png";
import bg from "../../images/background4.png";

export default function RoadmapMobile() {
	return (
		<>
			<Box
				my={5}
				sx={{
					background: `url(${bg})`,
					backgroundPosition: "top",
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
				}}
			>
				<Typography
					fontSize={{ xs: "30px", sm: "40px" }}
					fontWeight={700}
					fontFamily="Josefin Sans"
					color="text.primary"
					textAlign="center"
				>
					Roadmap
				</Typography>
				<Typography
					sx={{
						textTransform: "uppercase",
						textAlign: "center",
						fontSize: "20px",
						color: "#667394",
					}}
					pt={2}
				>
					HOW WE ARE BUILDING THE FUTURE OS ECONTRACT
				</Typography>
				<hr
					style={{
						width: "20%",
						border: "2px transparent",
						backgroundImage:
							"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
						backgroundOrigin: "border-box",
						backgroundClip: "content-box, border-box",
						padding: "1px",
					}}
				/>
				<Stack direction="row" justifyContent="center" px={5}>
					<Box
						style={{
							border: "0px transparent",
							backgroundImage:
								"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
							backgroundOrigin: "border-box",
							backgroundClip: "content-box, border-box",
							paddingLeft: "2px",
						}}
					>
						<Box
							my={4}
							ml={4.6}
							sx={{
								position: "relative",
								width: "80%",
								backgroundClip:
									"content-box, border-box, border-box, border-box, border-box",
								borderRadius: "15px",
								boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
								border: "1px solid transparent",
								backgroundImage:
									"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
								backgroundOrigin:
									"content-box, border-box, border-box, border-box, border-box",
								backgroundRepeat: "no-repeat",
								backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
								backgroundPosition:
									"top left, top left, top right, bottom left, bottom right",
							}}
						>
							<Box sx={{ position: "absolute", left: "-65px" }}>
								<img src={RMLL} alt="" />{" "}
							</Box>
							<Box
								sx={{
									textAlign: "left",
								}}
								pl={5}
								pr={2}
								py={5}
							>
								<Box
									sx={{
										fontFamily: "'Josefin Sans'",
										fontStyle: "normal",
										fontWeight: "700",
										fontSize: "24px",
										lineHeight: "24px",
										color: "#fff",
									}}
								>
									Q1 2022
								</Box>
								<Box
									sx={{
										fontFamily: "'Open Sans'",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "12px",
										lineHeight: "20px",
										color: "#fff",
									}}
									pt={2}
								>
									<ul style={{ listStyleType: "disc" }}>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
									</ul>
								</Box>
							</Box>
						</Box>
						<Box
							my={4}
							ml={4.6}
							sx={{
								position: "relative",
								width: "80%",
								backgroundClip:
									"content-box, border-box, border-box, border-box, border-box",
								borderRadius: "15px",
								boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
								border: "1px solid transparent",
								backgroundImage:
									"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
								backgroundOrigin:
									"content-box, border-box, border-box, border-box, border-box",
								backgroundRepeat: "no-repeat",
								backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
								backgroundPosition:
									"top left, top left, top right, bottom left, bottom right",
							}}
						>
							<Box sx={{ position: "absolute", left: "-65px" }}>
								<img src={RMLL} alt="" />{" "}
							</Box>
							<Box
								sx={{
									textAlign: "left",
								}}
								pl={5}
								pr={2}
								py={5}
							>
								<Box
									sx={{
										fontFamily: "'Josefin Sans'",
										fontStyle: "normal",
										fontWeight: "700",
										fontSize: "24px",
										lineHeight: "24px",
										color: "#fff",
									}}
								>
									Q1 2022
								</Box>
								<Box
									sx={{
										fontFamily: "'Open Sans'",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "12px",
										lineHeight: "20px",
										color: "#fff",
									}}
									pt={2}
								>
									<ul style={{ listStyleType: "disc" }}>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
									</ul>
								</Box>
							</Box>
						</Box>
						<Box
							my={4}
							ml={4.6}
							sx={{
								position: "relative",
								width: "80%",
								backgroundClip:
									"content-box, border-box, border-box, border-box, border-box",
								borderRadius: "15px",
								boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
								border: "1px solid transparent",
								backgroundImage:
									"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
								backgroundOrigin:
									"content-box, border-box, border-box, border-box, border-box",
								backgroundRepeat: "no-repeat",
								backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
								backgroundPosition:
									"top left, top left, top right, bottom left, bottom right",
							}}
						>
							<Box sx={{ position: "absolute", left: "-65px" }}>
								<img src={RMLL} alt="" />{" "}
							</Box>
							<Box
								sx={{
									textAlign: "left",
								}}
								pl={5}
								pr={2}
								py={5}
							>
								<Box
									sx={{
										fontFamily: "'Josefin Sans'",
										fontStyle: "normal",
										fontWeight: "700",
										fontSize: "24px",
										lineHeight: "24px",
										color: "#fff",
									}}
								>
									Q1 2022
								</Box>
								<Box
									sx={{
										fontFamily: "'Open Sans'",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "12px",
										lineHeight: "20px",
										color: "#fff",
									}}
									pt={2}
								>
									<ul style={{ listStyleType: "disc" }}>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
									</ul>
								</Box>
							</Box>
						</Box>
						<Box
							my={4}
							ml={4.6}
							sx={{
								position: "relative",
								width: "80%",
								backgroundClip:
									"content-box, border-box, border-box, border-box, border-box",
								borderRadius: "15px",
								boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
								border: "1px solid transparent",
								backgroundImage:
									"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
								backgroundOrigin:
									"content-box, border-box, border-box, border-box, border-box",
								backgroundRepeat: "no-repeat",
								backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
								backgroundPosition:
									"top left, top left, top right, bottom left, bottom right",
							}}
						>
							<Box sx={{ position: "absolute", left: "-65px" }}>
								<img src={RMLL} alt="" />{" "}
							</Box>
							<Box
								sx={{
									textAlign: "left",
								}}
								pl={5}
								pr={2}
								py={5}
							>
								<Box
									sx={{
										fontFamily: "'Josefin Sans'",
										fontStyle: "normal",
										fontWeight: "700",
										fontSize: "24px",
										lineHeight: "24px",
										color: "#fff",
									}}
								>
									Q1 2022
								</Box>
								<Box
									sx={{
										fontFamily: "'Open Sans'",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "12px",
										lineHeight: "20px",
										color: "#fff",
									}}
									pt={2}
								>
									<ul style={{ listStyleType: "disc" }}>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
										<li>
											Lorem Ipsum is simply dummy text of the printing and
											industry.
										</li>
									</ul>
								</Box>
							</Box>
						</Box>
					</Box>
				</Stack>
			</Box>
		</>
	);
}
