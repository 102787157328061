import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext, url } from "../../utils";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import Loader from "../../loading";
import { useLiquidityLockerContract } from "../../ConnectivityAss/hooks";
import { formatUnits } from "@ethersproject/units";
import dummyico from "../../images/dummyico.png";

export default function AllLockLiqiuidity({ again }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const { signer } = useContext(AppContext);
	const liquidityLockerContract = useLiquidityLockerContract(signer);
	const [loading, setloading] = useState(true);

	let totalLockedAmount = 0;
	const [liquidityList, setliquidityList] = useState([]);
	const [totalAmount, setTotalAmount] = useState([]);
	const initData = async () => {
		try {
			// setloading(true);
			const { data } = await axios.get(`${url}liquidityRouter/liquidityData`);
			setliquidityList(data.newLiquidity);
			setloading(false);
			if (data.newLiquidity.length > 0) {
				let amountArry = [];
				let mainArry = [];
				for (let i = 0; i < data.newLiquidity.length; i++) {
					totalLockedAmount = 0;
					const count = await liquidityLockerContract.getUserNumLocksForToken(
						data.newLiquidity[i].account,
						data.newLiquidity[i].tokenAddress
					);
					for (let j = 0; j < +count; j++) {
						let rec = data?.newLiquidity[i];
						const lockDetail =
							await liquidityLockerContract.getUserLockForTokenAtIndex(
								data.newLiquidity[i].account,
								data.newLiquidity[i].tokenAddress,
								j.toString()
							);
						totalLockedAmount += +formatUnits(
							lockDetail[1].toString(),
							data.newLiquidity[i].tokenDecimals.toString()
						);
						let obj = {
							...rec,
							totalLockedAmount,
						};
						mainArry.push(obj);
					}
					if (totalLockedAmount > 0) {
						amountArry.push(...mainArry);
					}
				}
				setTotalAmount(amountArry);
			}
			setloading(false);
		} catch (error) {}
	};
	// console.log(liquidityList, "liquidityList");
	useEffect(() => {
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [again]);
	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalAmount.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	let navigate = useNavigate();
	const routeChange = (path) => {
		navigate(path);
	};

	return (
		<Box>
			<Loader loading={loading} />
			<TableContainer>
				<Table style={{ minWidth: "300px" }}>
					<TableBody>
						{totalAmount.length > 0 ? (
							(rowsPerPage > 0
								? totalAmount.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: totalAmount
							).map(
								(
									{
										tokenName,
										lockedAmount,
										_id,
										tokenSymbol,
										totalLockedAmount,
									},
									index
								) => (
									<TableRow
										key={index}
										sx={{
											borderBottom: " 1px solid #383A41",
										}}
										hover
										onClick={() =>
											routeChange(`/Dashboard/LockLiqiuidityRecord/${_id}`)
										}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
										>
											<Stack direction="row" spacing={2} alignItems="center">
												<img src={dummyico} alt="" width="50px" height="50px" />
												<Box>
													<Typography
														sx={{
															color: "#fff",
															fontWeight: "700",
															fontSize: "15px",
															fontFamily: "josefin Sans",
														}}
													>
														{tokenName}
													</Typography>
													<Typography
														sx={{
															fontWeight: "400",
															fontSize: "15px",
															fontFamily: "open sans",
														}}
													>
														{tokenSymbol}
													</Typography>
												</Box>
											</Stack>
										</TableCell>
										<TableCell
											align="center"
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
										>
											<Typography
												sx={{
													color: "#fff",
													fontWeight: "400",
													fontSize: "15px",
													fontFamily: "open sans",
												}}
											>
												{totalAmount?.length > 0
													? parseFloat(totalLockedAmount).toFixed(2)
													: "Loading..."}
											</Typography>
										</TableCell>
									</TableRow>
								)
							)
						) : loading ? (
							loading
						) : (
							<TableRow>
								<TableCell
									align="center"
									style={{
										color: "#ffffff",
										fontSize: "14px",
										fontFamily: "open sans",

										borderBottom: " 1px solid #383A41",
									}}
									colSpan={5}
								>
									There is no Locked Liquidity yet
								</TableCell>
							</TableRow>
						)}
						{emptyRows > 0 && (
							<TableRow
								style={{
									height: 82 * emptyRows,
								}}
							>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
				{totalAmount.length > 5 && (
					<TablePagination
						rowsPerPageOptions={[5, 8, 10]}
						component="div"
						count={totalAmount.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</TableContainer>
		</Box>
	);
}
