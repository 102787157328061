import React, { useState } from "react";
import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import AllLockTokens from "../TokenLocker/LockTokens";
import MyLockToken from "../TokenLocker/MyLockTokens";
import AllLockLiqiuidity from "../LiquidityLocker/AllLockLiqiuidity";
import MyLockLiqiuidity from "../LiquidityLocker/MyLockLiqiuidity";
import ParticipatedProject from "../Home/ParticipatedProject";
import MintedTable from "./MintedTable";
import MyLaunchedProjects from "../Home/MyLaunchedProject";

function Dashboard() {
	const [value, setValue] = useState("1");
	const [value1, setValue1] = useState("1");
	const [value2, setValue2] = useState("1");
	const [again, setagain] = useState(true);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setagain(!again);
	};
	const handleChange1 = (event, newValue) => {
		setValue1(newValue);
		setagain(!again);
	};
	const handleChange2 = (event, newValue) => {
		setValue2(newValue);
		setagain(!again);
	};

	return (
		<Box pt={12}>
			<Container maxWidth="lg">
				<Box
					fontFamily="Josefin Sans"
					fontSize={{ xs: "30px", sm: "40px" }}
					fontWeight={700}
					textAlign="center"
					color="text.primary"
				>
					Dashboard
				</Box>
				<Box display="flex" justifyContent="center" mt={2}>
					{/* <Box
						fontFamily="open sans"
						fontSize={{ xs: "14px", sm: "16px" }}
						fontWeight={400}
						textAlign="center"
						width={{ xs: "100%", sm: "60%" }}
						color="#7C7B97"
					>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
						obcaecati dignissimos quae quo ad iste ipsum officiis deleniti
						asperiores sit.
					</Box> */}
				</Box>
				<Box pt={3} pb={10}>
					<Grid container spacing={5}>
						<Grid item md={6} sm={12} xs={12}>
							<Box height="100%" p={2}>
								<Box
									fontFamily="josefin Sans"
									fontSize={{ xs: "12px", sm: "18px" }}
									fontWeight={400}
									color="text.primary"
								>
									Projects
								</Box>
								<Box
									sx={{
										width: "100%",
										typography: "body1",
										height: "100%",
										backgroundClip:
											"content-box, border-box, border-box, border-box, border-box",
										borderRadius: "15px",
										boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.30)",
										border: "1px solid transparent",
										backgroundImage:
											"linear-gradient(#090e27, #090e27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
										backgroundOrigin:
											"content-box, border-box, border-box, border-box, border-box",
										backgroundRepeat: "no-repeat",
										backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
										backgroundPosition:
											"top left, top left, top right, bottom left, bottom right",
									}}
									mt={2}
								>
									<Box p={3}>
										<TabContext value={value2}>
											<Tabs
												onChange={handleChange2}
												value={value2}
												sx={{
													"& .Mui-selected": {
														color: "#fff !important",
														border: " 1.5px solid #5142FC",
														borderRadius: "6px",
														background: "#1E1E65",
														fontWeight: "400",
														fontSize: { sm: "16px", xs: "12px" },
													},
													"& .MuiTabs-indicator": {
														display: "none",
													},
												}}
											>
												<Tab
													indicatorColor="secondary"
													textColor="inherit"
													sx={{ fontFamily: "josefin sans" }}
													label="Participated"
													value="1"
												/>
												<Tab
													indicatorColor="#FFFFF"
													textColor="inherit"
													sx={{ fontFamily: "josefin sans" }}
													label="Created"
													value="2"
												/>
											</Tabs>
											<TabPanel sx={{ padding: "30px 0px" }} value="1">
												<ParticipatedProject again={again} />
											</TabPanel>
											<TabPanel sx={{ padding: "30px 0px" }} value="2">
												<MyLaunchedProjects again={again} />
											</TabPanel>
										</TabContext>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							{/* Minted Tokens */}
							<Box height="100%" p={2}>
								<Box
									fontSize={{ xs: "12px", sm: "18px" }}
									fontWeight={400}
									fontFamily="josefin Sans"
									color="text.primary"
								>
									Minted Tokens
								</Box>
								<Box
									sx={{
										width: "100%",
										typography: "body1",
										height: "100%",
										backgroundClip:
											"content-box, border-box, border-box, border-box, border-box",
										borderRadius: "15px",
										boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.30)",
										border: "1px solid transparent",
										backgroundImage:
											"linear-gradient(#090e27, #090e27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
										backgroundOrigin:
											"content-box, border-box, border-box, border-box, border-box",
										backgroundRepeat: "no-repeat",
										backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
										backgroundPosition:
											"top left, top left, top right, bottom left, bottom right",
									}}
									mt={2}
								>
									<Box p={3}>
										<MintedTable again={again} />
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							{/* Token Locked */}
							<Box height="100%" p={2}>
								<Box
									fontFamily="josefin Sans"
									fontSize={{ xs: "12px", sm: "18px" }}
									fontWeight={400}
									color="text.primary"
								>
									Token Locked
								</Box>
								<Box
									sx={{
										width: "100%",
										typography: "body1",
										height: "100%",
										backgroundClip:
											"content-box, border-box, border-box, border-box, border-box",
										borderRadius: "15px",
										boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.30)",
										border: "1px solid transparent",
										backgroundImage:
											"linear-gradient(#090e27, #090e27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
										backgroundOrigin:
											"content-box, border-box, border-box, border-box, border-box",
										backgroundRepeat: "no-repeat",
										backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
										backgroundPosition:
											"top left, top left, top right, bottom left, bottom right",
									}}
									mt={2}
								>
									<Box p={3}>
										<TabContext value={value}>
											<Box>
												<Tabs
													onChange={handleChange}
													value={value}
													sx={{
														"& .Mui-selected": {
															color: "#fff !important",
															border: " 1.5px solid #5142FC",
															borderRadius: "6px",
															background: "#1E1E65",
															fontWeight: "400",
															fontSize: { sm: "16px", xs: "12px" },
														},
														"& .MuiTabs-indicator": {
															display: "none",
														},
													}}
												>
													<Tab
														indicatorColor="secondary"
														textColor="inherit"
														sx={{ fontFamily: "josefin sans" }}
														label="All"
														value="1"
													/>
													<Tab
														indicatorColor="#FFFFF"
														textColor="inherit"
														sx={{ fontFamily: "josefin sans" }}
														label="My Lock"
														value="2"
													/>
												</Tabs>
											</Box>
											<TabPanel sx={{ padding: "30px 0px" }} value="1">
												<AllLockTokens again={again} />
											</TabPanel>
											<TabPanel sx={{ padding: "30px 0px" }} value="2">
												<MyLockToken again={again} />
											</TabPanel>
										</TabContext>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item md={6} sm={12} xs={12}>
							{/* Liquidity Locked */}
							<Box height="100%" p={2}>
								<Box
									fontFamily="josefin Sans"
									fontSize={{ xs: "12px", sm: "18px" }}
									fontWeight={400}
									color="text.primary"
								>
									Liquidity Locked
								</Box>
								<Box
									sx={{
										width: "100%",
										typography: "body1",
										height: "100%",
										backgroundClip:
											"content-box, border-box, border-box, border-box, border-box",
										borderRadius: "15px",
										boxShadow: "0px 0px 14px 0px rgba(0, 0, 0, 0.30)",
										border: "1px solid transparent",
										backgroundImage:
											"linear-gradient(#090e27, #090e27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
										backgroundOrigin:
											"content-box, border-box, border-box, border-box, border-box",
										backgroundRepeat: "no-repeat",
										backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
										backgroundPosition:
											"top left, top left, top right, bottom left, bottom right",
									}}
									mt={2}
								>
									<Box p={3}>
										<TabContext value={value1}>
											<Tabs
												onChange={handleChange1}
												value={value1}
												sx={{
													"& .Mui-selected": {
														color: "#fff !important",
														border: " 1.5px solid #5142FC",
														borderRadius: "6px",
														background: "#1E1E65",
														fontWeight: "400",
														fontSize: { sm: "16px", xs: "12px" },
													},
													"& .MuiTabs-indicator": {
														display: "none",
													},
												}}
											>
												<Tab
													indicatorColor="secondary"
													textColor="inherit"
													sx={{ fontFamily: "josefin sans" }}
													label="All"
													value="1"
												/>
												<Tab
													indicatorColor="#FFFFF"
													textColor="inherit"
													sx={{ fontFamily: "josefin sans" }}
													label="My Lock"
													value="2"
												/>
											</Tabs>
											<TabPanel sx={{ padding: "30px 0px" }} value="1">
												<AllLockLiqiuidity again={again} />
											</TabPanel>
											<TabPanel sx={{ padding: "30px 0px" }} value="2">
												<MyLockLiqiuidity again={again} />
											</TabPanel>
										</TabContext>
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	);
}

export default Dashboard;
