import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import bg1 from "../../images/background71.png";
import bg2 from "../../images/background72.png";
import AddIcon from "@mui/icons-material/Add";

const faqData = [
	{
		question: "What is Metasgate?",
		answer:
			" Metasgate decentralized launchpad is a platform for launching new blockchain-based projects and offerings, such as Initial Coin Offerings (ICOs) or Initial Exchange Offerings (IEOs), in a decentralized and trustless manner. It eliminates the need for intermediaries, giving greater control and security to both project creators and participants. With the power of smart contracts and blockchain technology, a decentralized launchpad provides a transparent and secure way to launch and invest in new projects.",
	},
	{
		question: "Which blockchains does Metasgate support?",
		answer:
			"Metasgate is currently utilizing the Binance Smart Chain blockchain, but there are plans to expand to additional blockchain networks in the future.",
	},
	{
		question: "What are benefits of holding Metasgate Token (MOLS)?",
		answer:
			"MOLS is a deflationary token with a fixed total supply of 1 billion, meaning no new tokens will be minted. The Metasgate DEX has a buyback and burn system that increases the price and decreases the supply of MOLS tokens simply by holding them",
	},
	{
		question: "Where can I buy Metasgate Token (MOLS)?",
		answer:
			"Our token is not yet available for purchase as we have not launched it onto the market. However, you can keep an eye out for its official release and be among the first to buy it once it becomes available.",
	},
	{
		question: "Is it necessary to complete the whitelisting process before participating in an Initial DEX Offering (IDO)?",
		answer:
			"Whitelisting is not a requirement for every Initial DEX Offering (IDO) on our DEX platform. The necessity of whitelisting is determined by the project owner and may or may not be a requirement, depending on their preference.",
	},
	{
		question: "How do i participate in Token launch?",
		answer:
			"To take part in an Initial DEX Offering (IDO), you must first connect your wallet to the Binance Smart Chain network. Before purchasing tokens, ensure that you have USDT in your wallet. Then, click on the 'Buy' button to initiate the transaction. Once the IDO has completed the presale phase, you can claim your purchased tokens.",
	},
];

const FAQs = () => {
	return (
		<>
			<Box
				py={10}
				sx={{
					background: `url(${bg1}),url(${bg2})`,
					backgroundPosition: "top left, bottom right",
					backgroundRepeat: "no-repeat, no-repeat",
					// backgroundSize: "",
				}}
			>
				<Container maxWidth="lg">
					<Typography
						fontSize={{ xs: "30px", sm: "40px" }}
						fontWeight={700}
						fontFamily="Josefin Sans"
						color="text.primary"
						textAlign="center"
						pb={6}
					>
						Frequently Ask Question
					</Typography>
					<Box pb={8}>
						<Grid container spacing={3}>
							{faqData.map((data, index) => (
								<Grid item md={6} sm={12} xs={12}>
									<Accordion
										sx={{
											backgroundColor: "#090e27",
											boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
										}}
										key={index}
									>
										<AccordionSummary
											expandIcon={<AddIcon sx={{ color: "#634fe4" }} />}
											aria-controls={index}
										>
											<Typography
												sx={{
													fontWeight: 400,
													fontSize: "14px",
													fontFamily: "josefin Sans",
												}}
											>
												{data.question}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography
												sx={{
													fontWeight: 400,
													fontSize: "14px",
													fontFamily: "open sans",
												}}
											>
												{data.answer}
											</Typography>
										</AccordionDetails>
									</Accordion>
								</Grid>
							))}
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default FAQs;
