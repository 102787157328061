import React, { useState } from "react";
import { Box, Container, Button } from "@mui/material";
import bg from "../../images/background1.png";
import bg1 from "../../images/background11.png";
// import img from "../../images/main-img.png";
import launch from "../../images/launch.png";
import note from "../../images/note.png";
import text from "../../images/main-text.png";
import { Link } from "react-router-dom";
// import AnimatedImg from "../AnimatedImg/AnimatedImg";
import Bounce from "react-reveal/Bounce";
import { Slide } from "react-reveal";
import Whitelist from "../Whitelist";
import logo1 from "../../images/logos/logo1.png";
import logo2 from "../../images/logos/logo2.png";
import logo3 from "../../images/logos/logo3.png";
import logo4 from "../../images/logos/logo4.png";
import logo5 from "../../images/logos/logo5.png";

function MainSection() {
	const [open, setOpen] = useState(false);

	return (
		<Box
			sx={{
				background: { md: `url(${bg})`, sm: `url(${bg1})`, xs: `url(${bg1})` },
				// backgroundPosition: "center center",
				backgroundRepeat: { md: "no-repeat", sm: "no-repeat", xs: "no-repeat" },
				backgroundSize: { md: "100%", sm: "cover", xs: "100%" },
			}}
			pt={15}
		>
			<Whitelist setOpen={setOpen} open={open} />
			<Container maxWidth="lg">
				{/* <Grid
					container
					spacing={3}
					justifyContent={{ xs: "center", sm: "space-between" }}
					pt={3}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						sx={{ display: "flex", alignItems: "center" }}
					> */}
				<Box>
					<Bounce left cascade>
						<Box sx={{ textAlign: "center" }}>
							<img
								src={text}
								alt=""
								style={{
									width: "100%",
									maxWidth: "650px",
								}}
							/>
						</Box>
						<Box
							fontSize={{ xs: "33px", sm: "60px" }}
							fontWeight={500}
							color="text.primary"
							fontFamily="josefin Sans"
							textAlign="center"
							// textAlign={{ xs: "center", sm: "left" }}
						>
							Dedicated Metaverse
						</Box>
						<Box
							fontSize={{ xs: "33px", sm: "60px" }}
							fontWeight={500}
							color="text.primary"
							fontFamily="josefin Sans"
							textAlign="center"
							// textAlign={{ xs: "center", sm: "left" }}
						>
							Launchpad
						</Box>
						<Box
							fontSize={{ xs: "14px", sm: "20px" }}
							fontWeight={400}
							color="#fff"
							fontFamily="Urbanist"
							my={3}
							textAlign="center"
						>
							Most secure IDO Launchpad on BSC network
						</Box>
					</Bounce>
					<Box
						my={4}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Slide bottom>
							<Link style={{ textDecoration: " none" }} to={"/ido"}>
								<Button
									sx={{
										width: "149px",
										height: "56px",
										borderRadius: "25px",
										border: "1px transparent",
										backgroundImage:
											"linear-gradient(92.51deg, #11112e 3.3%, #11112e 93.12%),linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
										backgroundOrigin: "border-box",
										backgroundClip: "content-box, border-box",
										padding: "1px",
										fontSize: "14px",
										letterSpacing: "2%",
										fontWeight: 500,
										fontFamily: "open sans",
										color: "#fff",
										zIndex: 1,
										marginRight: "20px",
										"&:hover": {
											background:
												"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
										},
									}}
								>
									{" "}
									<img src={launch} style={{ marginRight: "5px" }} alt="" />
									Launch IDO
								</Button>
							</Link>
							<Button
								sx={{
									width: "134px",
									borderRadius: "30px",
									background: "#fff",
									border: "1.5px solid #FFFFFF",
									height: "56px",
									fontSize: "14px",
									fontWeight: 700,
									color: "#000",
									textTransform: "capitalize",
									"&:hover": {
										color: "#fff",
									},
								}}
								onClick={() => setOpen(true)}
							>
								<img
									src={note}
									width="20px"
									style={{ marginRight: "5px" }}
									alt=""
								/>
								Whitelist
							</Button>
						</Slide>
					</Box>
				</Box>
				{/* </Grid>
					<Grid item xs={12} sm={12} md={6}>
						<Box width="100%" height="100%"> */}
				{/* <img src={img} height="540px" alt="" /> */}
				{/* <Bounce right>
								<AnimatedImg />
							</Bounce>
						</Box>
					</Grid>
				</Grid> */}
				{/* <Marquee pauseOnHover={true} gradient={false}> */}
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						justifyContent: "space-evenly",
						flexWrap: "wrap",
						gap: "25px",
					}}
					py={12}
				>
					<img src={logo1} alt="logo" width="170px" />
					<img src={logo2} alt="logo" width="170px" />
					<img src={logo3} alt="logo" width="170px" />
					<img src={logo4} alt="logo" width="170px" />
					<img src={logo5} alt="logo" width="170px" />
				</Box>
				{/* </Marquee> */}
			</Container>
		</Box>
	);
}

export default MainSection;
