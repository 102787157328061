import {
	Container,
	Stack,
	IconButton,
	useMediaQuery,
	InputBase,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";

import one from "./assets/copy.png";
import two from "./assets/share.png";
import three from "./assets/earn.png";
import line from "./assets/line.png";

import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { AppContext } from "../../utils";

function Earn() {
	const mobile = useMediaQuery("(max-width:899px)");
	const mobileline = useMediaQuery("(max-width:550px)");

	const { account } = useContext(AppContext);
	// const [refData, setrefData] = useState(null);

	// useEffect(() => {
	//   const getData = async () => {
	//     let { data } = await axios.get(url + "RefRouter/all");

	//     let topValues = data.sort((a, b) => b.amount - a.amount).slice(0, 5);

	//     setrefData(topValues);
	//   };

	//   getData();
	// }, []);

	return (
		<Box py={12}>
			<Container maxWidth="lg">
				<Box
					fontWeight="700"
					fontSize={{ md: "40px", xs: "30px" }}
					fontFamily="Josefin Sans"
					lineHeight="56px"
					letterSpacing="2px"
					color="#FFFFFF"
					textAlign={"center"}
					// pt="12"
				>
					Share to Earn
				</Box>
				<Stack
					width="100%"
					// direction={{ md: "row", sm: "row", xs: "column" }}
					direction={"row"}
					alignItems="center"
					justifyContent={"space-between"}
				>
					<Box
						mt={4}
						fontWeight="400"
						fontSize={{ xs: "12px", md: "18px" }}
						color="white"
						fontFamily="Open Sans"
					>
						Step 1: Copy
					</Box>
					<Box
						mt={4}
						fontWeight="400"
						fontSize={{ xs: "12px", md: "18px" }}
						color="white"
						fontFamily="Open Sans"
					>
						Step 2: Share
					</Box>
					<Box
						mt={4}
						fontWeight="400"
						fontSize={{ xs: "12px", md: "18px" }}
						color="white"
						fontFamily="Open Sans"
					>
						Step 3: Earn
					</Box>
				</Stack>
				<Stack
					// direction={{ md: "row", sm: "row", xs: "column" }}
					direction={"row"}
					alignItems="center"
					justifyContent={"space-between"}
					mt={1}
				>
					{" "}
					<img
						style={{ maxWidth: mobile ? "50px" : "10%", width: "100%" }}
						src={one}
						alt=""
					/>{" "}
					<img
						style={{ width: mobileline ? "20%" : "35%" }}
						src={line}
						alt=""
					/>{" "}
					<img
						style={{ maxWidth: mobile ? "50px" : "10%", width: "100%" }}
						src={two}
						alt=""
					/>{" "}
					<img
						style={{ width: mobileline ? "20%" : "35%" }}
						src={line}
						alt=""
					/>{" "}
					<img
						style={{ maxWidth: mobile ? "50px" : "10%", width: "100%" }}
						src={three}
						alt=""
					/>{" "}
				</Stack>
				<Box
					mt={3}
					fontWeight="600"
					fontSize={{ xs: "18px", md: "24px" }}
					lineHeight={{ xs: "17px", md: "26px" }}
					color="white"
					fontFamily="Open Sans"
				>
					Copy Ref link
				</Box>
				<Stack
					width="100%"
					mt="20px"
					direction={"row"}
					alignItems="center"
					justifyContent={"center"}
					bgcolor="#090e27"
					borderRadius="15px"
					boxShadow="0 0 10px 2px #00002025"
					p={1}
					color="#7C7B97"
				>
					<Box width={{ md: "95%", sm: "90%", xs: "80%" }} pl={1}>
						<InputBase
							readOnly
							sx={{ width: "100%", color: "#7C7B97" }}
							value={
								account
									? window.location.origin + "/?ref=" + account
									: "Please connect your wallet"
							}
						/>
					</Box>
					<Box width={{ md: "5%", sm: "10%", xs: "20%" }} pl={1}>
						<IconButton>
							<CopyToClipboard
								text={
									account
										? window.location.origin + "/?ref=" + account
										: "Please connect your wallet"
								}
								onCopy={() => {
									if (account) {
										toast.success("Ref Link Copied");
									} else {
										toast.error("Connect your wallet first");
									}
								}}
							>
								<ContentCopyIcon sx={{ color: "#fff" }} />
							</CopyToClipboard>
						</IconButton>
					</Box>
				</Stack>
				<Box
					mt={4}
					fontWeight="600"
					fontSize={{ xs: "18px", md: "24px" }}
					lineHeight={{ xs: "18px", md: "22px" }}
					color="white"
					fontFamily="Josefin Sans"
				>
					Description
				</Box>
				<Box
					py="30px"
					fontWeight="400"
					fontFamily="Open Sans"
					fontSize={{ xs: "14px", md: "16px" }}
					lineHeight={{ xs: "17px", md: "26px" }}
					color="#7C7B97"
					textAlign={"justify"}
				>
					How to cooperate to get work. You only need to connect your wallet to
					acquire your shareable links; it's that easy. You will now receive 50%
					of the basic launch fee and 2% of all growing money when someone uses
					your affiliate link (referral link) to access the launch project
					Presale/ICO on the metagame dex. In other words, if someone raises 100
					BNBs, you get 2 BNBs. Due to its decentralized nature, it will be
					automatically paid into your wallet as soon as the ICO's owner is
					determined during the presale.
				</Box>
				{/* 
        <Box mt={{ md: "200px", xs: "50px" }}>
          <Box
            fontWeight="600"
            fontSize={{ xs: "18px", md: "24px" }}
            lineHeight={{ xs: "16px", md: "26px" }}
            color="white"
            fontFamily="Urbanist"
            textAlign={"center"}
          >
            Top Earners
          </Box>

          <Container maxWidth="sm">
            <Box
              mt={{ md: "80px", xs: "40px" }}
              sx={{
                background: "#090e27",
                border: "1px solid #5A5A5A",
                boxSizing: "border-box",
                boxShadow: "-11px 17px 28px #1D1736",
                borderRadius: "30px",
              }}
              fontFamily="Urbanist"
              py={"27px"}
              px={mobile2 ? "10px" : "54px"}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Box
                          fontWeight="700"
                          fontSize={{ xs: "14px", md: "18px" }}
                          lineHeight={{ xs: "17px", md: "26px" }}
                          color="white"
                          fontFamily="Urbanist"
                          textAlign={"left"}
                        >
                          Wallet Address
                        </Box>{" "}
                      </TableCell>
                      <TableCell align="right">
                        <Box
                          fontWeight="700"
                          fontSize={{ xs: "14px", md: "18px" }}
                          lineHeight={{ xs: "17px", md: "26px" }}
                          color="white"
                          fontFamily="Urbanist"
                          textAlign={"right"}
                        >
                          Earnings
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refData
                      ? refData.map((item) => (
                          <TableRow>
                            {" "}
                            <TableCell align="left">
                              {" "}
                              <Box
                                fontWeight="600"
                                fontSize={{ xs: "15px", md: "23px" }}
                                lineHeight={{ xs: "17px", md: "26px" }}
                                color="white"
                                fontFamily="Urbanist"
                                textAlign={"lefy"}
                              >
                                {item.walletAddress.slice(0, 5) +
                                  "...." +
                                  item.walletAddress.slice(-5)}
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                fontWeight="600"
                                fontSize={{ xs: "15px", md: "23px" }}
                                lineHeight={{ xs: "17px", md: "26px" }}
                                color="white"
                                fontFamily="Urbanist"
                                textAlign={"right"}
                              >
                                {item.amount.toFixed(2)} BNB
                              </Box>
                            </TableCell>{" "}
                          </TableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </Box> */}
			</Container>
		</Box>
	);
}

export default Earn;
