import {
	Box,
	Container,
	Divider,
	Grid,
	InputBase,
	Typography,
} from "@mui/material";
import React from "react";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import logo from "../images/mainlogo1.png";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<>
			<Box
				sx={{
					backgroundColor: "#000020",
				}}
				pt={{ xs: 20, sm: 10 }}
				pb={{ xs: 10, sm: 5 }}
			>
				<Container maxWidth="lg">
					<Grid container spacing={5} sx={{ paddingBottom: "20px" }}>
						<Grid item xs={12} sm={12} md={4}>
							<Box display="flex" width="100%" flexDirection={"column"}>
								<Box
									display={"flex"}
									justifyContent={{ xs: "center", sm: "flex-start" }}
								>
									<Link to="/" style={{ cursor: "pointer" }}>
										<img width="80%" src={logo} alt="logo" />
									</Link>
								</Box>

								<Typography
									fontWeight="400"
									fontFamily="open sans"
									fontSize="14px"
									lineHeight="22px"
									textAlign={{ xs: "center", md: "start" }}
									color="#FFFFFF"
									py={2}
								>
									The Largest IDO Launchpad in the crypto realm with 3 supported
									wallets connect.
								</Typography>
								<Typography
									fontWeight="400"
									fontSize="20px"
									lineHeight="26px"
									fontFamily="josefin Sans"
									color="#FFFFFF"
									my={1}
								>
									Subscribe Now
								</Typography>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										height: "45px",
										background: "#333",
										borderRadius: "10px",
									}}
									pl={2}
								>
									<InputBase
										sx={{
											width: "100%",
											fontSize: "18px",
											height: "100%",
											borderRadius: "10px 0px 0px 10px",
										}}
										placeholder="info@mail.com"
									/>
									<Box
										alignItems="center"
										display="flex"
										justifyContent="center"
										sx={{
											background: "rgba(81, 66, 252, 1)",
											borderRadius: "0px 10px 10px 0px",
											color: "white",
											height: "100%",
											margin: 0,
											padding: 0,
											width: "77px",
										}}
									>
										<SendRoundedIcon />
									</Box>
								</Box>

								{/* <Box color="white" display="flex" mb={2}>
									<OutlinedInput
										placeholder=" Info@yourgmail.com"
										id="outlined-adornment-password"
										sx={{
											border: "1px solid #343444",
											borderRadius: "10px",
										}}
										endAdornment={
											<InputAdornment
												position="end"
												sx={{
													width: "19%",
													height: "10px",
												}}
											>
												<IconButton aria-label="toggle password visibility">
													<Box
														alignItems="center"
														display="flex"
														justifyContent="center"
														sx={{
															background: "rgba(81, 66, 252, 1)",
															borderRadius: "0px 10px 10px 0px",
															color: "white",
															height: "57px",
															width: matches ? "77px" : "60px",
														}}
													>
														<SendRoundedIcon />
													</Box>
												</IconButton>
											</InputAdornment>
										}
									/>
								</Box> */}
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={2}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: { md: "start", sm: "center" },
							}}
						>
							<Box
								fontWeight="700"
								fontSize="18px"
								lineHeight="26px"
								color="#FFFFFF"
								fontFamily="josefin Sans"
								my={1}
							>
								Product
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								DAO Launchpad
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								fontFamily="open sans"
								color="#FFFFFF"
								my={1}
							>
								Stake DAO
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								Community voted
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								fontFamily="open sans"
								color="#FFFFFF"
								my={1}
							>
								Farms and Vestings
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								Governance
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={2}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: { md: "start", sm: "center" },
							}}
						>
							<Box
								fontWeight="700"
								fontSize="18px"
								lineHeight="21px"
								color="#FFFFFF"
								fontFamily="josefin Sans"
								my={1}
							>
								Resources
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								fontFamily="open sans"
								color="#FFFFFF"
								my={1}
							>
								Blog
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								FAQ
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								Sitemap
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								fontFamily="open sans"
								lineHeight="22px"
								color="#FFFFFF"
								my={1}
							>
								Support
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={2}
							sm={6}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: { md: "start", sm: "center" },
							}}
						>
							<Box
								fontWeight="700"
								fontSize="18px"
								lineHeight="21px"
								color="#FFFFFF"
								fontFamily="josefin Sans"
								my={1}
							>
								Social
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								fontFamily="open sans"
								lineHeight="22px"
								color="#FFFFFF"
								my={1}
							>
								Twitter
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								fontFamily="open sans"
								color="#FFFFFF"
								my={1}
							>
								Telegram
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								Instagram
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								TikTok
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								Youtube
							</Box>
							<Box
								fontWeight="400"
								fontSize="14px"
								lineHeight="22px"
								color="#FFFFFF"
								fontFamily="open sans"
								my={1}
							>
								Discord
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={2}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: { md: "start", sm: "center" },
							}}
						>
							<Box>
								<Box
									fontWeight="700"
									fontSize="18px"
									lineHeight="21px"
									fontFamily="josefin Sans"
									color="#FFFFFF"
									my={1}
								>
									Legal
								</Box>
								<Box
									fontWeight="400"
									fontSize="14px"
									lineHeight="22px"
									color="#FFFFFF"
									fontFamily="open sans"
									my={1}
								>
									Terms
								</Box>
								<Box
									fontWeight="400"
									fontSize="14px"
									lineHeight="22px"
									color="#FFFFFF"
									fontFamily="open sans"
									my={1}
								>
									Privacy
								</Box>
								<Box
									fontWeight="400"
									fontSize="14px"
									lineHeight="22px"
									color="#FFFFFF"
									fontFamily="open sans"
									my={1}
								>
									Cookies
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<Divider sx={{ background: "#677495", height: "1px" }} />
				<Container maxWidth="lg" sx={{ textAlign: "justify" }}>
					<Typography
						sx={{
							fontWeight: "400",
							fontSize: "20px",
							lineHeight: "27px",
							color: "#fff",
							fontFamily: "open sans",
						}}
						pt={3}
						pb={2}
					>
						* Past performances do not indicate future success.
					</Typography>
					<Typography
						variant="p"
						sx={{
							fontWeight: "400",
							fontSize: "15px",
							lineHeight: "20px",
							color: "#5C6785",
							fontFamily: "open sans",
						}}
					>
						Participants/Citizens from the following countries are strictly
						excluded/not allowed to participate in the IDOs: Indonesia, Iran,
						Iraq, Belarus, Libya, Sudan, Syria, Zimbabwe, Myanmar, North Korea,
						The Crimea, Russian Federation, and or the United States of America
						(USA), as well as Puerto Rico and the Virgin Islands and any other
						US possessions (the “Prohibited Jurisdictions”).
						<br />
						By using this Website You agree that use of metasdate is at your own
						risk. Buying cryptocurrencies and NFTs is an inherently risky
						activity, so it is essential that you do your own due diligence
						before buying or selling any cryptocurrency or NFT and come to your
						own conclusions. metasdate is not in charge of your profit or loss.
						<br />© Copyright metasdate 2021. All rights reserved. Terms &
						Conditions
					</Typography>
				</Container>
			</Box>
		</>
	);
}

export default Footer;
