import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useDeviceDetect from "../../components/isMobile";
import Heading from "../../components/Heading";
import TextField from "../../components/TextField";
import RangeSlider from "../../components/RangeSlider";

import TextAreaField from "../../components/TextAreaField";

import "./createSale.scss";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import axios from "axios";

import { parseUnits } from "@ethersproject/units";
import moment from "moment";
import { Box, Container, useMediaQuery } from "@mui/material";

// images

import e1 from "./assets/e1.svg";
import e2 from "./assets/e2.svg";
import { usePresaleContract, voidAccount } from "../../ConnectivityAss/hooks";
import { useContext } from "react";
import { AppContext, gasEstimationPayable, url } from "../../utils";
import Loading from "../../loading";
import { Contract } from "@ethersproject/contracts";
import tokenAbi from "../../ConnectivityAss/tokenAbi.json";
import { admin, presaleAddress } from "../../ConnectivityAss/environment";

const tabsData = [
	{
		label: "Token information",
		step: 1,
		disabled: false,
	},

	{
		label: "Sales information limit ",
		step: 2,
		disabled: true,
	},
	{
		label: "Per wallet allocation limit",
		step: 3,
		disabled: true,
	},

	{
		label: "Pancakeswap listing",
		step: 4,
		disabled: true,
	},
	{
		label: "Additional information",
		step: 5,
		disabled: true,
	},
];

function IDO() {
	// states

	let navigate = useNavigate();

	const matches = useMediaQuery("(max-width:960px)");
	const { isMobile } = useDeviceDetect();
	//const salesContract = UseSalesContract();
	const [TokenContract, setTokenContract] = useState("");
	const [tabs, setTabs] = useState(tabsData);
	const [activeTab, setActiveTab] = useState(1);

	// TOKEN INFORMATION
	const [tokenAddress, setTokenAddress] = useState("");
	const [tokenSymbol, setTokenSymbol] = useState("");
	const [tokenDecimel, setTokenDecimel] = useState();
	const [tokenName, settokenName] = useState("");
	const [isApproved, setisApproved] = useState(false);

	// const [isnotSmallScreen] = useMediaQuery("(min-width:600px)");

	//  SALES INFORMATION
	const [hardCap, sethardCap] = useState("10");
	let tokensOnSale = "10000";
	const [softCap, setsoftCap] = useState("0.01");
	const [priceOfTokenOnSales, setPriceOfTokenOnSales] = useState("1000");
	const [startTime, setstartTime] = useState(moment().unix());
	const [endTime, setendTime] = useState(moment().unix());
	const [startTime1, setstartTime1] = useState(new Date());
	const [endTime1, setendTime1] = useState(new Date());

	// WALLET ALLOCATION
	let limit = "nolimit";
	const [minLimit, setMinLimit] = useState("");
	const [maxLimit, setMaxLimit] = useState("");

	// SALES TYPE
	// const [sale, setSale] = useState("public");
	// const [fileName, setfileName] = useState("No file chosen");
	// const [fileData, setfileData] = useState([]);

	// Referral Percentage
	// const [referralPercent, setreferralPercent] = useState(10);
	// const [referral, setreferral] = useState("no");
	const [reffrelAddress, setRefrelAddress] = useState("");

	// VESTING SCHEDLE
	// const [releasePercent, setreleasePercent] = useState("50");
	// const [month, setMonth] = useState("4");

	// PANCAKE LISTING
	const [liquidityPercent, setliquidityPercent] = useState("70");
	const [pancakeListingPrice, setPancakeListingPrice] = useState("1000");
	const [lockDuration, setlockDuration] = useState("");
	// ERROR STATE
	const [tokenError, settokenError] = useState(false);
	const [loading, setloading] = useState(false);

	// Additional info
	const [logoLink, setlogoLink] = useState("https://test.com");
	const [webLink, setwebLink] = useState("https://test.com");
	const [githubLink, setgithubLink] = useState("https://test.com");
	const [twitterLink, settwitterLink] = useState("https://test.com");
	const [redditLink, setredditLink] = useState("https://test.com");
	const [telegramLink, settelegramLink] = useState("https://test.com");
	const [projectDescription, setprojectDescription] =
		useState("https://test.com");
	const [updateyouwant, setupdateyouwant] = useState("https://test.com");
	const [projectName, setprojectName] = useState("test");
	const [txHash, settxHash] = useState("test");
	// VALIDATION FUNCTIONS STARTS FROM HERE
	const [totalNumber0fTokens, settotalNumber0fTokens] = useState("");
	//Connectivity
	const { account, signer } = useContext(AppContext);
	const [fee, setFee] = useState("");
	const [liquiditylockduration, setLiquiditylockduration] = useState("");
	//contrcts

	const presaleContract = usePresaleContract(signer);
	useEffect(() => {
		const init = async () => {
			let ref = await localStorage.getItem("META_STRTER");
			console.log("ref ==>", ref);
			setRefrelAddress(ref);

			let fee = await presaleContract.deploymentFee();

			setFee(fee.toString());
			let lockDuration = await presaleContract._liquiditylockduration();
			// console.log("lockDuration", +lockDuration);
			setLiquiditylockduration(+lockDuration);
		};
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);
	//token address handler

	const tokenAddressHandler = async (address) => {
		if (!account) {
			toast.error("Please connect your wallet .");
		} else {
			try {
				setloading(true);
				const Contracts = new Contract(
					address,
					tokenAbi,
					signer ? signer : voidAccount
				);

				setTokenContract(Contracts);
				let dec = await Contracts.decimals();

				setTokenDecimel(dec);

				let name = await Contracts.name();
				settokenName(name);
				let sym = await Contracts.symbol();

				setTokenSymbol(sym);
				setActiveTab(1);
				setloading(false);
				settokenError(false);
			} catch (error) {
				setTokenDecimel("");
				setTokenSymbol("");
				toast.error("Error! Invalid token Address");
				settokenError(true);
				setloading(false);
			}
		}
	};

	//totl number of tokens

	const numberOfTokens = async () => {
		if (!priceOfTokenOnSales) {
			toast.error("Error! Please enter Price of tokens on sale ");
		} else if (!pancakeListingPrice) {
			toast.error("Error! Please enter Price of tokens on Pancakeswap ");
		} else if (!tokensOnSale) {
			toast.error("Error! Please enter Tokens for sale .");
		} else if (!+liquidityPercent > 0) {
			toast.error(
				"Error! Please select percentage of liquidity to be added to pool greater than zero"
			);
		} else {
			try {
				setloading(true);
				let numOfToken = await presaleContract.getTotalNumberOfTokens(
					priceOfTokenOnSales,
					pancakeListingPrice,
					parseUnits(hardCap).toString(),
					liquidityPercent
				);

				settotalNumber0fTokens(numOfToken.toString());

				setloading(false);
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	const tokenApprovalHandler = async () => {
		console.log("num ", totalNumber0fTokens.toString());
		if (!account) {
			toast.error("Error! Please connect your wallet .");
		} else if (isApproved) {
			toast.error("Already Approved");
		} else {
			try {
				setloading(true);
				let tx = await TokenContract.approve(
					presaleAddress.toString(),
					parseUnits(
						totalNumber0fTokens.toString(),
						tokenDecimel.toString()
					).toString()
				);
				await tx.wait();
				setisApproved(true);
				setloading(false);
				toast.success("Approved");
				return null;
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	// create BNB pre sale handler
	// console.log("fee", fee);
	const createPresaleHandler = async () => {
		if (!account) {
			toast.error("Error! Please connect your wallet");
		} else if (!isApproved) {
			toast.error("Error! Please Approve First");
		} else if (!tokenAddress) {
			toast.error("Error! Please enter token Adrress");
		} else if (tokenError) {
			toast.error("Error! Please enter valid token Adrress");
		} else if (!softCap) {
			toast.error("Error! Please enter Soft Cap.");
		} else if (!tokensOnSale) {
			toast.error("Error! Please enter tokens on sale.");
		} else if (limit === "limit" && (!minLimit || !maxLimit)) {
			toast.error("Error! Please enter all values of maxlimit and minlimit.");
		} else if (limit === "limit" && +maxLimit < +minLimit) {
			toast.error(
				"Error! Maximum Contribution limit must be greater than minimun contribution limit"
			);
		} else if (
			limit === "limit" &&
			(isNaN(+minLimit) || isNaN(+maxLimit) || +minLimit < 0 || +maxLimit < 0)
		) {
			toast.error("Error! Please enter valid values of maxlimit and minlimit.");
		} else {
			try {
				setloading(true);
				let data = [
					priceOfTokenOnSales,
					startTime.toString(),
					endTime.toString(),
					parseUnits(minLimit).toString(),
					parseUnits(maxLimit).toString(),
					parseUnits(hardCap).toString(),
					parseUnits(softCap).toString(),
					pancakeListingPrice.toString(),
					liquidityPercent.toString(),
				];
				// let data1 = [c)];
				const _ref = reffrelAddress ? reffrelAddress : admin;
				console.log(
					"data here ==> ",
					tokenAddress,
					_ref,
					+lockDuration * 86400,
					data
				);
				let fn = presaleContract.estimateGas.createPreSaleBNB;
				let params = [tokenAddress, _ref, +lockDuration * 86400, data];
				let tx = await presaleContract.createPreSaleBNB(...params, {
					value: fee?.toString(),
					gasLimit: gasEstimationPayable(account, fn, params, fee?.toString()),
				});
				await tx.wait();
				settxHash("https://testnet.bscscan.com/tx/" + tx.hash);
				let getPresaleCount = await presaleContract.getAllPreSalesLength();
				let presaleAddress;
				if (getPresaleCount) {
					presaleAddress = await presaleContract.allPreSales(
						getPresaleCount.toString()
					);
				}
				axios
					.post(url + "presaleRouter/newTransation", {
						tokenAddress,
						priceOfTokenOnSales,
						presaleAddress,
						hardCap,
						softCap,
						minLimit,
						maxLimit,
						liquidityPercent,
						pancakeListingPrice,
						logoLink,
						webLink,
						githubLink,
						twitterLink,
						redditLink,
						telegramLink,
						projectDescription,
						updateyouwant,
						tokenName,
						tokenSymbol,
						tokenDecimel,
						startTime,
						endTime,
						projectName,
						userWalletAddress: account,
						txHash,
						kyc: false,
						projectStatus: "upcoming",
					})
					.then((res) => {
						if (res.data) {
							toast.success("Success! IDO successfully created.");
							setTimeout(() => {
								navigate(
									`/projects/projectoverview/${res.data._id}/${res.data.projectStatus}`,
									{
										replace: true,
									}
								);
							}, 1500);
						}
					})
					.catch((error) => {
						setloading(false);

						if (error?.data?.message) {
							toast.error(error?.data?.message);
						} else if (error?.reason) {
							toast.error(error?.reason);
						} else {
							toast.error(error?.message);
						}
					});
				setloading(false);
			} catch (error) {
				console.log("error", error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	useEffect(() => {}, [activeTab]);

	const switchTab = (step) => {
		setTabs(
			tabs.map((item) =>
				item.step === step ? { ...item, disabled: false } : item
			)
		);
		setActiveTab(step);
	};

	const clickBack = () => {
		if (activeTab === 1) return;
		switchTab(activeTab - 1);
	};

	const clickNext = async () => {
		if (activeTab === 5) {
			return;
		} else if (activeTab === 1 && !tokenAddress && !tokenDecimel) {
			toast.error("Error! Please enter token Adrress");
		} else if (activeTab === 1 && !tokenDecimel) {
			toast.error("Error! Please enter valid token Adrress");
			// } else if (
			// 	(activeTab === 2 && +endTime < +startTime + 1800) ||
			// 	+endTime === +startTime + 1800
			// ) {
			// 	toast.error(
			// 		"Error! There must be a gap more than 30 minutes between sale start and end time."
			// 	);
		} else if (activeTab === 3) {
			if (
				isNaN(+minLimit) ||
				isNaN(+maxLimit) ||
				+minLimit < 0 ||
				+maxLimit < 0 ||
				minLimit === "" ||
				maxLimit === ""
			) {
				toast.error(
					"Error! Please enter valid values of maxlimit and minlimit."
				);
			} else {
				switchTab(activeTab + 1);
			}
		} else if (activeTab === 4) {
			if (lockDuration === "") {
				toast.error("Error! please enter Lockduration");
			} else if (+lockDuration * 86400 < +liquiditylockduration) {
				toast.error("Error! Please enter Lockduration Greater than 90 days");
			} else {
				switchTab(activeTab + 1);
				await numberOfTokens();
			}
		} else {
			switchTab(activeTab + 1);
		}
	};
	const buttonHtml = (
		<div className="createSaleNext dFA jcSB">
			{activeTab === 1 ? null : (
				<span
					style={{
						fontFamily: "open sans",
						fontStyle: "normal",
						fontWeight: "700",
						fontSize: matches ? "16px" : "20px",
						color: "white",
						cursor: "pointer",
						zIndex: "20",
					}}
					onClick={clickBack}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						{" "}
						<i class="fa-solid fa-arrow-left-long"></i>{" "}
						<span style={{ paddingLeft: "10px" }}>Back</span>
					</Box>
				</span>
			)}
			{activeTab === 5 ? null : (
				<Box
					style={{
						background:
							" linear-gradient(92.51deg, #3461ff 3.3%, #8454eb 93.12%)",
						cursor: "pointer",
						borderRadius: "56px",
						fontFamily: "open sans",
					}}
					onClick={clickNext}
					width={matches ? "120px" : "132px"}
					height="48px"
					fontWeight="500"
					fontSize={matches ? "16px" : "20px"}
					color="#fff"
					display="flex"
					paddingBottom="3px"
					justifyContent="center"
					alignItems="center"
				>
					Next
				</Box>
			)}
		</div>
	);

	return (
		<div style={{ overflow: "hidden" }}>
			<Box position="relative">
				<img src={e1} style={{ position: "absolute" }} alt="" />
			</Box>
			<Loading loading={loading} />
			<Box pt="100px">
				<Box pb={15} mb={-10}>
					<Container maxWidth="lg">
						<div className="container pdt50 createSale">
							<Heading className="saleHeading" headingText="Create sale" />
							<div className={`createSaleBox ${isMobile ? "" : "oH"}`}>
								<div className="tabs pR">
									<Tabs
										className={`${!isMobile ? "dF" : ""}`}
										selectedIndex={activeTab - 1}
										onSelect={(index) => setActiveTab(index + 1)}
									>
										<div className="tabsPanels w100">
											{/* Token information */}
											<TabPanel>
												<div className="createSaleFields">
													<div className="fieldTextRow">
														<TextField
															type="text"
															placeholder="Enter here"
															label="Token address"
															className="fieldText"
															value={tokenAddress}
															onChange={(e) => {
																if (e.target.value.length === 42) {
																	tokenAddressHandler(e.target.value);
																	setTokenAddress(e.target.value);
																} else {
																	toast.error("Invaled token Address");
																	setTokenAddress(e.target.value);
																	setTokenDecimel("");
																	setTokenSymbol("");
																}
															}}
															maxLength={42}
														/>
														<TextField
															type="text"
															placeholder="Enter here"
															label="Token symbol"
															className="fieldText"
															value={tokenSymbol}
															readOnly
														/>

														<TextField
															type="text"
															placeholder="Enter here"
															label="Token decimal"
															className="fieldText"
															value={tokenDecimel}
															readOnly
														/>
													</div>
												</div>
											</TabPanel>

											<TabPanel>
												<div className="createSaleFields">
													<div className={`${isMobile ? "" : "dF"}`}>
														<div className="fieldTextRow">
															<TextField
																type="text"
																placeholder="Enter here"
																label="Price per BNB"
																className="fieldText"
																value={priceOfTokenOnSales}
																onChange={(e) =>
																	setPriceOfTokenOnSales(e.target.value)
																}
															/>
															<TextField
																type="text"
																placeholder="Enter here"
																label="Soft cap"
																className="fieldText"
																QuestionIcon
																value={softCap}
																onChange={(e) => setsoftCap(e.target.value)}
															/>
															<TextField
																type="text"
																placeholder="Enter here"
																label="Hardcap"
																className="fieldText"
																QuestionIcon
																value={hardCap}
																onChange={(e) => sethardCap(e.target.value)}
															/>
														</div>
														<div className="fieldTextRow">
															<label
																style={{
																	fontFamily: "open sans",
																	fontStyle: "normal",
																	fontWeight: "700",
																	fontSize: "20px",
																	color: "#ffffff",
																	textAlign: "left",
																	paddingBottom: "10px",
																}}
																className="dB"
															>
																Sale start
															</label>
															<div className="textFields pR">
																<input
																	type="datetime-local"
																	placeholder="Enter here"
																	label="Sale start"
																	className="fieldText "
																	value={startTime1}
																	// onChange={(e)=> setsoftCap(e.target.value)}
																	onChange={(e) => {
																		setstartTime1(e.target.value);
																		const date = (
																			new Date(e.target.value).getTime() / 1000
																		).toFixed(0);

																		setstartTime(date);
																	}}
																/>
															</div>
															<label
																style={{
																	fontFamily: "open sans",
																	fontStyle: "normal",
																	fontWeight: "700",
																	fontSize: "20px",
																	color: "#ffffff",
																	textAlign: "left",
																	paddingBottom: "10px",
																}}
																className="dB"
															>
																Sale end
															</label>
															<div className="textFields pR">
																<input
																	type="datetime-local"
																	placeholder="Enter here"
																	label="Sale end"
																	className="fieldText"
																	value={endTime1}
																	onChange={(e) => {
																		setendTime1(e.target.value);
																		const date = (
																			new Date(e.target.value).getTime() / 1000
																		).toFixed(0);
																		setendTime(date);
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											</TabPanel>

											{/* Per wallet allocation limit */}
											<TabPanel>
												<div className="createSaleFields">
													<div className="includeReferral">
														<div className="insideDiv dFA jcSB">
															<span>Limit </span>
															{/* <OnOff
                                onChange={(e) => setLimit(e.target.checked)}
                              /> */}
														</div>
													</div>
													<div className="fieldTextRow">
														<TextField
															fontFamily="open Sans"
															type="text"
															placeholder="Enter Amount In BNB"
															label="Minimum"
															className="fieldText"
															value={minLimit}
															onChange={(e) => setMinLimit(e.target.value)}
														/>

														<TextField
															fontFamily="open Sans"
															type="text"
															placeholder="Enter Amount In BNB"
															label="Maximum"
															className="fieldText"
															onChange={(e) => setMaxLimit(e.target.value)}
															value={maxLimit}
														/>
													</div>
												</div>
											</TabPanel>

											{/* Pancakeswap listing */}
											<TabPanel>
												<div className="createSaleFields">
													<div className="includeReferral">
														<div className="dFA titleTokens">
															<p>% of liquidity</p>
														</div>
													</div>

													<RangeSlider
														min="0"
														max="100"
														value={liquidityPercent}
														onChange={(e) => {
															setliquidityPercent(e.target.value);
														}}
													/>
													<div className="fieldTextRow pdt50 priceO">
														<TextField
															type="text"
															placeholder="Enter here"
															label="Listing Price"
															className="fieldText"
															QuestionIcon
															value={pancakeListingPrice}
															onChange={(e) =>
																setPancakeListingPrice(e.target.value)
															}
														/>
													</div>
													<div className="fieldTextRow pdt50 priceO">
														<TextField
															type="number"
															placeholder="Enter here"
															label="Lock Duration days"
															className="fieldText"
															QuestionIcon
															value={lockDuration}
															onChange={(e) => setlockDuration(e.target.value)}
														/>
													</div>
												</div>
											</TabPanel>

											{/* Additional information */}
											<TabPanel>
												<div className="createSaleFields">
													<div className={`${isMobile ? "" : "dF"}`}>
														<div className="fieldTextRow">
															<TextField
																type="text"
																placeholder="Enter here"
																label="Project name"
																className="fieldText"
																QuestionIcon
																value={projectName}
																onChange={(e) => setprojectName(e.target.value)}
															/>
															<TextField
																type="text"
																placeholder="Enter here"
																label="Medium link"
																className="fieldText"
																QuestionIcon
																value={githubLink}
																onChange={(e) => setgithubLink(e.target.value)}
															/>
															<TextField
																type="text"
																placeholder="Enter here"
																label="Reddit link"
																className="fieldText"
																QuestionIcon
																value={redditLink}
																onChange={(e) => setredditLink(e.target.value)}
															/>
														</div>
														<div className="fieldTextRow">
															<TextField
																type="text"
																placeholder="Enter here"
																label="Website link"
																className="fieldText"
																QuestionIcon
																value={webLink}
																onChange={(e) => setwebLink(e.target.value)}
															/>
															<TextField
																type="text"
																placeholder="Enter here"
																label="Logo link"
																className="fieldText"
																QuestionIcon
																value={logoLink}
																onChange={(e) => setlogoLink(e.target.value)}
															/>
														</div>
														<div className="fieldTextRow">
															<TextField
																type="text"
																placeholder="Enter here"
																label="Telegram link"
																className="fieldText"
																QuestionIcon
																value={telegramLink}
																onChange={(e) =>
																	settelegramLink(e.target.value)
																}
															/>
															<TextField
																type="text"
																placeholder="Enter here"
																label="Twitter link"
																className="fieldText"
																QuestionIcon
																value={twitterLink}
																onChange={(e) => settwitterLink(e.target.value)}
															/>
														</div>
													</div>
													<div className="projectDescription">
														<div className={`${isMobile ? "" : "dF"}`}>
															<TextAreaField
																type="textarea"
																placeholder="Enter here"
																label="Project description"
																className="textAreas"
																QuestionIcon
																value={projectDescription}
																onChange={(e) =>
																	setprojectDescription(e.target.value)
																}
															/>
															<div className="fieldTextRow wChange">
																<TextField
																	type="text"
																	placeholder="Enter here"
																	label="Updates you want"
																	className="fieldText"
																	QuestionIcon
																	value={updateyouwant}
																	onChange={(e) =>
																		setupdateyouwant(e.target.value)
																	}
																/>
															</div>
														</div>
														<div
															className={`${
																isMobile ? "" : "dF"
															} textGradients`}
														>
															<div className="fieldTextRow wChange">
																{!isApproved && (
																	<Box
																		style={{
																			background:
																				" linear-gradient(92.51deg, #3461ff 3.3%, #8454eb 93.12%)",
																			cursor: "pointer",
																			borderRadius: "56px",
																			fontFamily: "open sans",
																			marginBottom: "20px",
																			// opacity: 0.5,
																		}}
																		width={matches ? "80px" : "120px"}
																		height="42px"
																		fontWeight="700"
																		fontSize={matches ? "16px" : "20px"}
																		color="#fff"
																		display="flex"
																		paddingBottom="3px"
																		justifyContent="center"
																		alignItems="center"
																		fontFamily="Gemunu Libre"
																		onClick={() => tokenApprovalHandler()}
																	>
																		Approve
																	</Box>
																)}
																{isApproved && (
																	<Box
																		style={{
																			background:
																				" linear-gradient(92.51deg, #3461ff 3.3%, #8454eb 93.12%)",
																			cursor: "pointer",
																			borderRadius: "56px",
																			fontFamily: "open sans",
																			// opacity: 0.5,
																		}}
																		width={matches ? "160px" : "220px"}
																		height="42px"
																		fontWeight="700"
																		fontSize={matches ? "16px" : "20px"}
																		color="#fff"
																		display="flex"
																		paddingBottom="3px"
																		justifyContent="center"
																		alignItems="center"
																		fontFamily="Gemunu Libre"
																		onClick={() => createPresaleHandler()}
																	>
																		Deploy sale contract
																	</Box>
																)}
															</div>
															<div className="fieldTextRow wChange"></div>
														</div>
													</div>
												</div>
											</TabPanel>
										</div>
										<TabList className="leftSide w100">
											{tabs.map((item, i) => {
												const tabProps = {
													disabled: item.disabled,
													className: !item.disabled ? "active" : "",
												};
												return (
													<Tab key={i} {...tabProps}>
														<div className="mSite">{item.label}</div>
														<span>
															<b>{item.step}</b>
														</span>
													</Tab>
												);
											})}
										</TabList>
									</Tabs>
									{buttonHtml}
								</div>
							</div>
						</div>
					</Container>
				</Box>
			</Box>{" "}
			{matches ? (
				<></>
			) : (
				<>
					{" "}
					<Box position="relative">
						<img
							src={e2}
							style={{
								position: "absolute",
								left: "800px",
								top: "-600px",
								zIndex: "0",
							}}
							alt=""
						/>
					</Box>
				</>
			)}
		</div>
	);
}

export default IDO;
