import React, { useEffect } from "react";

import "./range.scss";

const RangeSliderValue = ({ min, max, onChange, value, readOnly }) => {
	useEffect(() => {
		let rangeSlider = document.getElementById("rs-range-line");
		let rangeBullet = document.getElementById("rs-bullet");
		let rangeRangeBg = document.getElementById("rs-range-bg");
		rangeSlider.addEventListener("input", showSliderValue, false);
		function showSliderValue() {
			rangeBullet.innerHTML = rangeSlider.value;
			let bulletPosition = rangeSlider.value / rangeSlider.max;

			rangeBullet.style.left = bulletPosition * 260 + "px";
			rangeRangeBg.style.width = bulletPosition * 270 + "px";
		}
		let bulletPosition = value / 100;

		rangeBullet.style.left = bulletPosition * 260 + "px";
		rangeRangeBg.style.width = bulletPosition * 270 + "px";
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="rangeSliderDiv">
			<div className="range-slider pR">
				<span id="rs-bullet" className="rs-label pA">
					{value}
				</span>
				<span id="rs-range-bg" className="rs-range-bg pA"></span>
				<input
					id="rs-range-line"
					className="rs-range"
					type="range"
					defaultValue={value}
					min={min}
					max={max}
					onChange={onChange}
					readOnly={readOnly}
				/>
			</div>
			<div className="box-minmax">
				<span>0</span>
				<span>100</span>
			</div>
		</div>
	);
};

export default RangeSliderValue;
