import React from "react";
import { Dialog, DialogContent, Box, Slide } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function NetworkChange({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  const networkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        // params: [{ chainId: "0x38" }], //BSC Mainnet
        params: [{ chainId: "0x61" }], //BSC Testnet
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="dialoge__content__section">
            <Box component="h3" color="#000">
              <Box component="span" color="red" fontSize="30px">
                Error!
              </Box>{" "}
              You are on wrong network please switch your network.
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent="center">
              <Box
                style={{
                  clipPath:
                    "polygon(21% 0 , 94% 0, 100% 20%, 79% 100%, 6% 100%, 0 80%)",
                  background:
                    " radial-gradient(241.67% 3206.67% at -4.58% -26.67%, #AD00FF 8.51%, #0029FF 100%)",
                  cursor: "pointer",
                }}
                onClick={() => networkHandler()}
                width={"300px"}
                height="38px"
                fontWeight="700"
                fontSize={"20px"}
                color="#fff"
                display="flex"
                paddingBottom="3px"
                justifyContent="center"
                alignItems="center"
                fontFamily="Gemunu Libre"
              >
                Switch Network
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default NetworkChange;
