import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
	palette: {
		primary: {
			main: "#5142FC",
			light: "#343444",
			contrastText: "#fff",
		},
		secondary: {
			main: "#7C7B97",
			light: "#0D0D11",
			contrastText: "#000020",
		},
		text: {
			primary: "#fff",
			secondary: "#EBEBEB",
			tertiary: "#8A8AA0",
		},
	},
});

theme.overrides = {
	MuiCssBaseline: {
		"@global": {
			body: {
				fontFamily: "open sans",
				backgroundColor: "#11112e !important",
				color: "#ffffff",
			},
			".img-fluid": {
				maxWidth: "100%",
				height: "auto",
			},
		},
	},
};

theme = responsiveFontSizes(theme);

export default theme;
