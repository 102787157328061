import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";

import Projects from "./Projects";
import axios from "axios";
import { url } from "../../utils";

function UpCommingProjects() {
	const [serverData, setServerData] = useState([]);

	const getUnixTime = () => {
		return Math.floor(new Date().getTime() / 1000);
	};

	const currentTime = getUnixTime();

	useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await axios.get(url + "presaleRouter/projects");
				setServerData(data);
			} catch (error) {
				console.log(error);
			}
		};
		getData();
	}, []);

	useEffect(() => {
		const upcomin =
			serverData.length > 0 &&
			serverData.filter(
				(item) => +item.startTime < currentTime && +item.endTime > currentTime
			);
		return upcomin;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverData]);

	return (
		<Box pt={10}>
			<Container maxWidth="lg">
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box
						fontSize={{ xs: "27px", sm: "36px" }}
						fontWeight={700}
						fontFamily="Josefin Sans"
						color="text.primary"
					>
						Upcomming Projects
					</Box>
					<Box
						fontSize={{ xs: "12px", sm: "14px" }}
						fontWeight={700}
						fontFamily="Urbanist"
						color="text.primary"
						sx={{
							textTransform: "uppercase",
							borderBottom: "1px solid",
							borderImage:
								"linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%)",
							borderImageSlice: 1,
							letterSpacing: "10%",
						}}
					>
						Explore more
					</Box>
				</Box>
				<Projects />
			</Container>
		</Box>
	);
}

export default UpCommingProjects;
