import React from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "../../images/left.png";
import right from "../../images/right.png";
import Bg from "../../images/Bg3.png";
import share from "../../images/share.png";

const useStyles = makeStyles((theme) => ({
  slider: {
    margin: "20px",
    "& .slick-list": {
      "& .slick-track": {
        display: "flex",
        "& .slick-slide": {
          marginRight: "18px",
          [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
          },
        },
      },
    },
  },
}));
function Roadmap() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <Box
        component="img"
        src={right}
        style={{
          position: "absolute",
          top: "140%",
          width: matches ? "50px" : "68px",
          height: matches ? "50px" : "68px",
        }}
        className={className}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div>
        <Box
          component="img"
          src={left}
          style={{
            position: "absolute",
            top: "140%",
            width: matches ? "50px" : "68px",
            height: matches ? "50px" : "68px",
            left: matches ? "65%" : "87%",
          }}
          className={className}
          onClick={onClick}
        />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box
      sx={{
        backgroundImage: `url(${Bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      pt={20}
      pb={27}
    >
      <Container maxWidth="lg">
        <Box
          fontSize={{ xs: "30px", sm: "56px" }}
          fontWeight={700}
          textAlign="center"
          color="text.primary"
          mb={5}
        >
          Our Roadmap
        </Box>
        <Slider {...settings} className={classes.slider}>
          <div>
            <Box
              sx={{
                background:
                  "linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
                boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
              }}
              height="235px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={share} width="28px" alt="" />
              <Box ml={3} color="text.primary">
                <Box
                  fontSize={{ xs: "20px", sm: "25px" }}
                  fontFamily="Urbanist"
                  fontWeight={600}
                  letterSpacing="2%"
                >
                  Q1 2022
                </Box>
                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  TGE GMI TOKEN
                </Box>
              </Box>
            </Box>
          </div>

          <div>
            <Box
              sx={{
                background:
                  "linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
                boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
              }}
              height="235px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={share} width="28px" alt="" />
              <Box ml={3} color="text.primary">
                <Box
                  fontSize={{ xs: "20px", sm: "25px" }}
                  fontFamily="Urbanist"
                  fontWeight={600}
                  letterSpacing="2%"
                >
                  Q2 2022
                </Box>
                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  Early Access NFT Release
                </Box>

                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  IDO Platform Launch
                </Box>
              </Box>
            </Box>
          </div>

          <div>
            <Box
              sx={{
                background:
                  "linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
                boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
              }}
              height="235px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={share} width="28px" alt="" />
              <Box ml={3} color="text.primary">
                <Box
                  fontSize={{ xs: "20px", sm: "25px" }}
                  fontFamily="Urbanist"
                  fontWeight={600}
                  letterSpacing="2%"
                >
                  Q3 2022
                </Box>
                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  Staking Pools on Platform
                </Box>

                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  Multi-Chain Support
                </Box>
              </Box>
            </Box>
          </div>

          <div>
            <Box
              sx={{
                background:
                  "linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
                boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
              }}
              height="235px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={share} width="28px" alt="" />
              <Box ml={3} color="text.primary">
                <Box
                  fontSize={{ xs: "20px", sm: "25px" }}
                  fontFamily="Urbanist"
                  fontWeight={600}
                  letterSpacing="2%"
                >
                  Q4 2022
                </Box>
                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  Staking Pools on Platform
                </Box>
                <Box
                  fontSize={{ xs: "18px", sm: "20px" }}
                  fontFamily="Urbanist"
                  fontWeight={500}
                  letterSpacing="2%"
                >
                  Multi-Chain Support
                </Box>
              </Box>
            </Box>
          </div>
        </Slider>
      </Container>
    </Box>
  );
}

export default Roadmap;
