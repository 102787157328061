export const stakingAddress = "0x9064aF692C5c0ee7bb3FDBEdB65c30508Dd96B45";
export const tokenAddress = "0x0201f78356fd7F293F532307202151d57F78E2C1";
// export const presaleAddress = "0x2399ad11eD627A4b72C75A34511Ff5B61382ABBc";
export const presaleAddress = "0xC63BD7ca2A818CFA1Ac90659593121b38e6A033e";
// export const presaleAddress = "0xc0e491CDb0db819097Cf302AcB0ce74ea7D20B89";
export const UsdcAddress = "0x39Bd2c364bD2EbA1CEf62C33A8e17a20153930bE";
export const tokenDeployerAddress =
	"0x6F644cb89D17b2DD049e3e2c5D96D549727b1939";
// export const mintTokenAddress = "0x4B8BE760906fbcA2897f71b3721dd13054CbeAbE";
export const mintTokenAddress = "0x9401ca6215AEeDab66bCC9d2C217097529f684FF";

//// Lockers ////
export const tokenLocker = "0x7191669B61815FD5bd24eEC77cb53b795E2Da86b";
// export const liquidityLocker = "0x9faA7d15457E7355ECB87669874e9aF56d6eE288";
export const liquidityLocker = "0x3b36b29aE5ee418160e3C357a0FF7b6e9b1A0880";
export const deflationaryTokenAddress =
	"0x1CFA819bAeFc2334a00392B7eF267dB90066ADBF";
// export const admin = "0x1bF99f349eFdEa693e622792A3D70833979E2854";
export const admin = "0x8A501d5960f0dF2f01887d8b010fDc8dBC9Ff3DE";
