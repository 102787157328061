import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import blog from "../../images/blog.png";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import logo from "../../images/mainlogo1.png";
import bg1 from "../../images/background6.png";

const Blogs = () => {
	return (
		<>
			<Box
				py={10}
				sx={{
					background: `url(${bg1})`,
					backgroundPosition: "top right",
					backgroundRepeat: "no-repeat",
					// backgroundSize: "",
				}}
			>
				<Container maxWidth="lg">
					<Typography
						fontSize={{ xs: "30px", sm: "40px" }}
						fontWeight={700}
						fontFamily="Josefin Sans"
						color="text.primary"
						textAlign="center"
						pb={4}
					>
						News and resources
					</Typography>

					<Grid container spacing={3}>
						<Grid item md={4} sm={6} xs={12}>
							<Box
								sx={{
									background: "#090E27",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									position: "relative",
									borderRadius: "15px",
									overflow: "hidden",
									boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
								}}
							>
								<img
									src={logo}
									alt="logo"
									style={{
										position: "absolute",
										width: "30%",
										top: 20,
										left: 30,
									}}
								/>
								<img src={blog} alt="blog" />
								<Box px={4} pt={3}>
									<Typography
										sx={{
											fontWeight: "600",
											fontSize: "20px",
											lineHeight: "18px",
											color: "#fff",
											fontFamily: "Josefin Sans",
										}}
									>
										Metastage launches a smart contract generator
									</Typography>
									<Typography
										sx={{
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "18px",
											color: "#C1C3E8",
											fontFamily: "Open Sans",
										}}
										pt={2}
										pb={5}
									>
										METASTAGE smart contract generator is a cutting-edge
										technology that allows innovators to build their products
										without having to hire coders.
									</Typography>
								</Box>
								<Box
									sx={{
										width: "100%",
										boxShadow: "0px -2px 5px 0px rgba(0,0,0,0.3)",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
									py={1}
									px={4}
								>
									<Typography
										sx={{
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "18px",
											color: "#C1C3E8",
										}}
									>
										By Writer Name 3 Days ago
									</Typography>
									<ArrowCircleRightIcon />
								</Box>
							</Box>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<Box
								sx={{
									background: "#090E27",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									position: "relative",
									borderRadius: "15px",
									overflow: "hidden",
									boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
								}}
							>
								<img
									src={logo}
									alt="logo"
									style={{
										position: "absolute",
										width: "30%",
										top: 20,
										left: 30,
									}}
								/>
								<img src={blog} alt="blog" />
								<Box px={4} pt={3}>
									<Typography
										sx={{
											fontWeight: "600",
											fontSize: "20px",
											lineHeight: "18px",
											color: "#fff",
											fontFamily: "Josefin Sans",
										}}
									>
										Metastage launches a smart contract generator
									</Typography>
									<Typography
										sx={{
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "18px",
											color: "#C1C3E8",
											fontFamily: "Open Sans",
										}}
										pt={2}
										pb={5}
									>
										METASTAGE smart contract generator is a cutting-edge
										technology that allows innovators to build their products
										without having to hire coders.
									</Typography>
								</Box>
								<Box
									sx={{
										width: "100%",
										boxShadow: "0px -2px 5px 0px rgba(0,0,0,0.3)",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
									py={1}
									px={4}
								>
									<Typography
										sx={{
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "18px",
											color: "#C1C3E8",
										}}
									>
										By Writer Name 3 Days ago
									</Typography>
									<ArrowCircleRightIcon />
								</Box>
							</Box>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<Box
								sx={{
									background: "#090E27",
									height: "100%",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									position: "relative",
									borderRadius: "15px",
									overflow: "hidden",
									boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
								}}
							>
								<img
									src={logo}
									alt="logo"
									style={{
										position: "absolute",
										width: "30%",
										top: 20,
										left: 30,
									}}
								/>
								<img src={blog} alt="blog" />
								<Box px={4} pt={3}>
									<Typography
										sx={{
											fontWeight: "600",
											fontSize: "20px",
											lineHeight: "18px",
											color: "#fff",
											fontFamily: "Josefin Sans",
										}}
									>
										Metastage launches a smart contract generator
									</Typography>
									<Typography
										sx={{
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "18px",
											color: "#C1C3E8",
											fontFamily: "Open Sans",
										}}
										pt={2}
										pb={5}
									>
										METASTAGE smart contract generator is a cutting-edge
										technology that allows innovators to build their products
										without having to hire coders.
									</Typography>
								</Box>
								<Box
									sx={{
										width: "100%",
										boxShadow: "0px -2px 5px 0px rgba(0,0,0,0.3)",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
									py={1}
									px={4}
								>
									<Typography
										sx={{
											fontWeight: "400",
											fontSize: "12px",
											lineHeight: "18px",
											color: "#C1C3E8",
										}}
									>
										By Writer Name 3 Days ago
									</Typography>
									<ArrowCircleRightIcon />
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
};

export default Blogs;
