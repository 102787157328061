import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Container,
	Button,
	useMediaQuery,
	Stack,
	InputBase,
	Grid,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { AppContext, url } from "../../../utils";
import { useTokenLockerContract } from "../../../ConnectivityAss/hooks";
import { Contract } from "ethers";
import tokenABI from "../../../ConnectivityAss/tokenAbi.json";
import { toast } from "react-toastify";
import { formatUnits, parseUnits } from "@ethersproject/units";
import Loading from "../../../loading";
import moment from "moment";
import { tokenLocker } from "../../../ConnectivityAss/environment";
import axios from "axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const { account, signer, connect } = useContext(AppContext);
	const tokenLockerContract = useTokenLockerContract(signer);
	const matches = useMediaQuery("(min-width:800px)");
	const [lockerType, setlockerType] = useState("vest");
	const [loading, setloading] = useState(false);
	const [tokenAddress, settokenAddress] = useState("");
	const [tokenBalance, settokenBalance] = useState("");
	const [tokenContract, settokenContract] = useState("");
	const [tokenDecimals, settokenDecimals] = useState("");
	const [lockedTokens, setlockedTokens] = useState("");
	const [startTime, setstartTime] = useState(0);
	const [endTime, setendTime] = useState(new Date().getTime());
	const [tokenName, settokenName] = useState("");
	const [tokenSymbol, settokenSymbol] = useState("");
	const [lockedData, setLockedData] = useState(false);

	const handleChange = (event) => {
		setlockerType(event.target.value);
	};
	async function check() {
		const {
			data: { data },
		} = await axios.get(`${url}tokenRouter/getUserToken/${account}`);
		if (data.length > 0) {
			let result = data.find((o, i) => {
				if (o.tokenAddress === tokenAddress) {
					return false;
				} else {
					return true;
				}
			});
			setLockedData(result);
		}
	}
	const tokenAddressHandler = async (address) => {
		try {
			setloading(true);
			settokenAddress(address);
			let ch = await check(address);
			setLockedData(ch);
			if (!account) {
				toast.error("Please connect your wallet first.");
			} else if (ch) {
				toast.error("Tokens already Locked from this account.");
				setloading(false);
			} else {
				setloading(true);
				const TokenContract = await new Contract(address, tokenABI, signer);
				const decimals = await TokenContract.decimals();
				const name = await TokenContract.name();
				const symbol = await TokenContract.symbol();
				settokenName(name);
				settokenSymbol(symbol);
				const tNumberOftokens = await TokenContract.balanceOf(account);
				settokenDecimals(decimals);
				settokenContract(TokenContract);
				settokenBalance(
					formatUnits(
						tNumberOftokens.toString(),
						decimals.toString()
					).toString()
				);
				// setloading(false);
			}
			setloading(false);
		} catch (error) {
			setloading(false);
			toast.error("invalid token address");
		}
	};
	useEffect(() => {
		if (account && tokenAddress) {
			tokenAddressHandler(tokenAddress);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenAddress]);

	const LockTokenHandler = async () => {
		if (!account) {
			toast.error("Error! Please connect your wallet.");
		} else if (!lockedTokens) {
			toast.error("Error! Please Enter  tokens.");
		} else if (!lockerType) {
			toast.error("Error! Please Select Locker Type, Vest or Lock Token");
		} else if (!tokenAddress) {
			toast.error("Please Enter Token Address");
		} else if (lockedData) {
			toast.error("Tokens already Locked from this account.");
			setloading(false);
		} else {
			try {
				setloading(true);
				const approve = await tokenContract.approve(
					tokenLocker,
					parseUnits(
						lockedTokens.toString(),
						tokenDecimals.toString()
					).toString()
				);
				await approve.wait();
				let zeroAddress = "0x0000000000000000000000000000000000000000";
				const tx = await tokenLockerContract.lock(
					tokenAddress,
					account,
					parseUnits(
						lockedTokens.toString(),
						tokenDecimals.toString()
					).toString(),
					startTime,
					endTime,
					zeroAddress
				);
				await tx.wait();
				let tokenData = {
					tokenAddress,
					tokenName,
					tokenSymbol,
					tokenDecimals,
					account: account.toLowerCase(),
					lockedTokens,
					startTime,
					endTime,
					zeroAddress,
				};

				const { data } = await axios.post(
					`${url}tokenRouter/TokenLocker`,
					tokenData
				);
				if (data.success) {
					toast.success("Transection Confirmed");
					setloading(false);
					window.location.reload();
				} else {
					toast.error(data.message);
					setloading(false);
				}
				setloading(false);
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	//   const ApproveTokens = async () => {
	//     if (!userWalletAddress) {
	//       toast.error("Error! Please connect your wallet .");
	//     } else {
	//       try {
	//         setloading(true);
	//         let tx = await SecondaryTokenContract.approve(
	//           Environment.LiquidityLocker,
	//           Gfee
	//         );
	//         setloading(false);
	//         toast.success("Token Approve Successfully")
	//       } catch (error) {
	//         console.log("err", error);
	//         if (error?.data?.message) {
	//           toast.error(error?.data?.message);
	//         } else {
	//           toast.error(error?.message);
	//         }
	//         setloading(false);
	//       }
	//     }
	//   };
	const list = [
		"Lock Splitting",
		"Liquidity migration",
		"Relocking",
		"Lock ownership trasfer",
	];
	return (
		<Box pt={12} pb={10} fontFamily="open sans">
			<Loading loading={loading} />
			<Container>
				<Box bgcolor="#090e27" borderRadius="15px" p={6}>
					<Box
						textAlign="center"
						fontSize={{ md: "40px", xs: "34px" }}
						fontFamily="Josefin Sans"
						fontWeight="700"
						color="#ffffff"
						mb={5}
					>
						Metastater V2 Locker
					</Box>
					<Grid container spacing={5}>
						<Grid item xs={12} md={6}>
							<Box
								color="#ffffff"
								fontSize={{ md: "20px", xs: "16px" }}
								textAlign="left"
								fontFamily="open sans"
								fontWeight="500"
								// pt={2.5}
							>
								Enter the address{" "}
							</Box>
							<Box
								mt={1}
								component="form"
								sx={{
									"& > :not(style)": { width: "100%" },
								}}
								noValidate
								autoComplete="off"
							>
								<InputBase
									sx={{
										bgcolor: "#11112e",
										borderRadius: "4px",
										padding: "10px 20px",
										fontFamily: "open sans",
									}}
									placeholder="METASGATE V2 Pair Address"
									onChange={(e) => {
										if (e.target.value.length === 42) {
											tokenAddressHandler(e.target.value);
										}
										settokenAddress(e.target.value);
									}}
								/>
							</Box>
							<Box
								textAlign="center"
								color="#ffffff"
								fontSize={{ md: "15px", xs: "10px" }}
								fontWeight="400"
								pt={1}
								fontFamily="open sans"
							>
								eg: 0xDc546cd7BF529d7A6a1767e263eEa34C3178a704
							</Box>
							<Box
								px={matches ? 4 : 2}
								py={2}
								mt={2}
								bgcolor="#090e27"
								border="1px solid #5A5A5A"
								borderRadius="20px"
								boxShadow="-11px 17px 28px #1D1736"
								fontSize={{ md: "20px", xs: "16px" }}
							>
								<Box
									py={2}
									sx={{
										display: "flex",
										color: "white",
										// justifyContent: "space-between",
										flexDirection: "column",
										width: "100%",
									}}
								>
									{/* <Box width="100%"> */}
									<InputBase
										sx={{
											color: "#fff !important",
											fontSize: { md: "20px", xs: "16px" },
											bgcolor: "#11112e",
											borderRadius: "4px",
											padding: "3px 5px",
											width: "100%",
											fontFamily: "open sans",
										}}
										placeholder="0.0"
										value={lockedTokens}
										onChange={(e) => setlockedTokens(e.target.value)}
									/>
									{/* </Box> */}
									<Box
										fontSize={{ md: "20px", xs: "16px" }}
										pt={1}
										fontFamily="open sans"
									>
										Balance:{" "}
										{tokenBalance
											? parseFloat(tokenBalance).toFixed(2)
											: "0.00"}
									</Box>
								</Box>

								<Box
									mt={2}
									sx={{
										display: "flex",
										color: "white",
										justifyContent: "space-between",
										flexDirection: matches ? "row" : "column",
									}}
								>
									<Box
										display="flex"
										flexWrap="wrap"
										gap="10px"
										justifyContent="space-evenly"
										width="100%"
									>
										<Button
											sx={{
												borderRadius: "15px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "16px", xs: "12px" },
												// padding: "1px 15px",
												fontWeight: "500, Medium",
											}}
											onClick={() => setlockedTokens((tokenBalance * 25) / 100)}
										>
											25%
										</Button>
										<Button
											sx={{
												borderRadius: "15px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "16px", xs: "12px" },
												// padding: "5px 20px",
												fontWeight: "500, Medium",
												// marginLeft: "10px",
											}}
											onClick={() => setlockedTokens((tokenBalance * 50) / 100)}
										>
											50%
										</Button>
										<Button
											sx={{
												borderRadius: "15px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "16px", xs: "12px" },
												// padding: "5px 20px",
												fontWeight: "500, Medium",
												// marginLeft: "10px",
											}}
											onClick={() => setlockedTokens((tokenBalance * 75) / 100)}
										>
											75%
										</Button>
										<Button
											sx={{
												borderRadius: "15px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "16px", xs: "12px" },
												// padding: "5px 20px",
												fontWeight: "500, Medium",
												// marginLeft: "10px",
											}}
											onClick={() => setlockedTokens(tokenBalance)}
										>
											100%
										</Button>
									</Box>
								</Box>
								<Box
									display="flex"
									mt={2}
									justifyContent="center"
									alignItems="center"
									fontFamily="open sans"
									gap="10px"
								>
									<Box>UNIV2</Box>
									<Button
										sx={{
											border: "1px transparent",
											backgroundImage:
												"linear-gradient(#090e27, #090e27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
											backgroundOrigin: "border-box",
											backgroundClip: "content-box, border-box",
											padding: "1px",

											fontSize: "20px",
											letterSpacing: "2%",
											fontWeight: 500,
											fontFamily: "Poppins",
											color: "#fff",

											"&:hover": {
												background:
													"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
											},
											textTransform: "lowercase",
										}}
										onClick={() => {
											if (account) {
												setlockedTokens(tokenBalance);
											} else {
												connect();
											}
										}}
									>
										{account ? "Max" : "Connect"}
									</Button>
								</Box>
							</Box>

							<Stack mt={2} justifyContent="center">
								<FormControl>
									<RadioGroup
										sx={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={lockerType}
										onChange={handleChange}
									>
										<FormControlLabel
											// sx={{
											// 	fontWeight: "600",
											// 	color: "white",
											// 	fontFamily: "open sans",
											// 	fontSize: "25px !important",
											// }}
											sx={{
												"& .css-ahj2mt-MuiTypography-root": {
													fontSize: "25px !important",
												},
											}}
											value="vest"
											control={<Radio />}
											label="Vest Token"
										/>

										<FormControlLabel
											// sx={{
											// 	fontWeight: "600",
											// 	color: "white",
											// 	fontSize: "25px !important",
											// 	fontFamily: "open sans",
											// }}
											sx={{
												"& .css-ahj2mt-MuiTypography-root": {
													fontSize: "25px !important",
												},
											}}
											value="lock"
											control={<Radio />}
											label="Lock Token"
										/>
									</RadioGroup>
								</FormControl>
							</Stack>

							{lockerType === "vest" ? (
								<Box mt={2}>
									<Box
										color="white"
										sx={{
											fontSize: { md: "22px", xs: "16px" },
											fontWeight: "500",
										}}
										mb={1}
									>
										Start Date
									</Box>
									<InputBase
										type="datetime-local"
										placeholder="Enter here"
										className="fieldText"
										onChange={(e) => {
											setstartTime(moment(e.target.value).format("X"));
										}}
										sx={{
											color: "#7C7B97",
											p: 1,
											borderRadius: "4px",
											width: "100%",
											bgcolor: "#11112e",
											"& ::-webkit-calendar-picker-indicator": {
												filter: "invert(1)",
											},
										}}
									/>
								</Box>
							) : null}

							<Box mt={2}>
								<Box
									color="white"
									sx={{
										fontSize: { md: "22px", xs: "16px" },
										fontWeight: "500",
									}}
									mb={1}
								>
									End Date
								</Box>

								<InputBase
									type="datetime-local"
									placeholder="Enter here"
									className="fieldText"
									onChange={(e) => {
										setendTime(moment(e.target.value).format("X"));
									}}
									sx={{
										color: "#7C7B97",
										p: 1,
										borderRadius: "4px",
										width: "100%",
										bgcolor: "#11112e",
										"& ::-webkit-calendar-picker-indicator": {
											filter: "invert(1)",
										},
									}}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box
								color="#7C7B97"
								fontSize={"20px"}
								fontFamily="Open Sans"
								fontWeight="400"
								textAlign={"justify"}
								// pt={2.5}
							>
								Here, you can lock your ERC20 team tokens and send the tokens to
								someone else that’s locked for a configurable period.
							</Box>
							<Box
								color="#fff"
								fontSize={{ md: "20px", xs: "16px" }}
								fontFamily="josefin Sans"
								fontWeight="700"
								pt={2.5}
							>
								Our lockers offer
							</Box>
							<Box color="#fff" pt={2.5}>
								{list.map((item) => {
									return (
										<Box display="flex" alignItems="center">
											<Box>
												<img src="/Dot.png" width="10px" alt="" />
											</Box>
											<Box
												fontSize={{ md: "20px", xs: "15px" }}
												fontWeight="400"
												fontFamily="open sans"
												pl={1}
											>
												{item}
											</Box>
										</Box>
									);
								})}
							</Box>
						</Grid>
					</Grid>
					<Box
						mt={4}
						sx={{
							fontSize: { md: "20px", xs: "16px" },
							fontWeight: "400",
							color: "#7C7B97",
							// width: { xs: "100%", md: "60%" },
							mx: "auto",
							textAlign: "center",
							fontFamily: "Open Sans",
						}}
					>
						Once tokens are locked they cannot be withdrawn under any
						Circumstances Until the timer has expired please Ensure the
						parameters are Correct as they are final
					</Box>

					<Stack
						mt={3}
						direction={"row"}
						alignItems="center"
						justifyContent={"center"}
					>
						{/* <Button
            sx={{
              width: matches ? "33%" : "40%",
              background:
                "linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
              boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
              borderRadius: "56px",
              color: "white",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: { md: "26px", xs: "18px" },
              marginRight: "15px",
              textTransform: "capitalize",
              "&:hover": {
                color: "#8454EB",
                background: " white",
              },
              "&:active": {
                color: "#8454EB",
                background: " white",
              },
            }}
          >
            Fee
          </Button> */}
						<Button
							sx={{
								height: "48px",
								borderRadius: "56px",
								width: "200px",
								border: "2px transparent",
								backgroundImage:
									"linear-gradient(#090e27, #090e27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
								backgroundOrigin: "border-box",
								backgroundClip: "content-box, border-box",
								padding: "2px",

								fontSize: "20px",
								letterSpacing: "2%",
								fontWeight: 500,
								fontFamily: "Poppins",
								color: "#fff",
								textTransform: "capitalize",
								"&:hover": {
									background:
										"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
								},
							}}
							onClick={() => LockTokenHandler()}
						>
							Lock
						</Button>
					</Stack>
				</Box>
			</Container>
		</Box>
	);
};
