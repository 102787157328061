import * as React from "react";
import PropTypes from "prop-types";
import { useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Button,
	Stack,
	TableHead,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import thinking from "../../images/thinkingface.png";
import { AppContext, url } from "../../utils";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import dummyico from "../../images/dummyico.png";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageIcon sx={{ color: "#fff" }} />
				) : (
					<FirstPageIcon sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight sx={{ color: "#fff" }} />
				) : (
					<KeyboardArrowLeft sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft sx={{ color: "#fff" }} />
				) : (
					<KeyboardArrowRight sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageIcon sx={{ color: "#fff" }} />
				) : (
					<LastPageIcon sx={{ color: "#fff" }} />
				)}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, f, amount) {
	return { name, f, amount };
}

const rows = [
	createData("Abii", "(A.A)", "1000.0 (A.A)"),
	createData("TS", "TST", "1000 TST"),
	createData("BSC", "Bleach", "90 Bleach"),
	createData("Alpha", "Alpha", "99 Alpha"),
	createData("ADA", "ADA", "100 ADA"),
];

const MintedTable = ({ again }) => {
	const matches = useMediaQuery("(max-width:700px)");
	const [page, setPage] = useState(0);
	const [mintedTokens, setmintedTokens] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage) : 0;
	const { account, signer, voidAccount, connect } =
		React.useContext(AppContext);

	const [color, setColor] = useState("white");

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	React.useEffect(() => {
		(async () => {
			const { data } = await axios.get(url + "MintedTokenRouter/all");
			// console.log(data);
			// console.log(account, "account");
			if (account && data.length > 0) {
				const mintData = data.filter((item) => item?.ownerAddress === account);
				setmintedTokens(mintData);
				// console.log(mintData, "account mint data");
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, again]);

	return (
		<div>
			<Box>
				{mintedTokens && mintedTokens?.length > 0 ? (
					<>
						<TableContainer>
							<Table style={{ minWidth: "300px" }}>
								<TableBody>
									{mintedTokens.map((row, index) => (
										<TableRow
											key={index}
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Stack direction="row" spacing={2} alignItems="center">
													<img
														src={dummyico}
														alt=""
														width="50px"
														height="50px"
													/>
													<Box>
														<Typography
															sx={{
																color: "#fff",
																fontWeight: "700",
																fontSize: "15px",
																fontFamily: "josefin Sans",
															}}
														>
															{row.tokenName}
														</Typography>
														<Typography
															sx={{
																fontWeight: "400",
																fontSize: "15px",
																fontFamily: "open sans",
															}}
														>
															{row.tokenSymbol}
														</Typography>
													</Box>
												</Stack>
											</TableCell>
											<TableCell
												align="center"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Typography
													sx={{
														color: "#fff",
														fontWeight: "400",
														fontSize: "15px",
														fontFamily: "open sans",
													}}
												>
													{row.totalSupply}
												</Typography>
											</TableCell>

											<TableCell
												align="center"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Typography
													sx={{
														color: "#fff",
														fontWeight: "400",
														fontSize: "15px",
													}}
												>
													{row.tokenAddress.slice(0, 4) +
														"...." +
														row.tokenAddress.slice(-4)}
													<IconButton
														onClick={() => {
															if (color === "white") {
																setColor("lightblue");
															}
														}}
													>
														<CopyToClipboard
															text={row.tokenAddress.toString()}
															onCopy={() => {
																if (account) {
																	toast.success("address Copied");
																}
															}}
														>
															<Box
																sx={{
																	color: color,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<i class="fa-solid fa-copy"></i>
															</Box>
														</CopyToClipboard>
													</IconButton>
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
								<TableFooter>
									<TableRow
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TablePagination
											style={{
												color: "#fff",
												fontSize: "15px",
												fontFamily: "Open Sans",
											}}
											rowsPerPageOptions={[
												5,
												10,
												15,
												{ label: "All", value: -1 },
											]}
											//   colSpan={3}
											count={30}
											rowsPerPage={rowsPerPage}
											page={page}
											SelectProps={{
												inputProps: {
													"aria-label": "rows per page",
												},
												native: true,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</>
				) : account ? (
					<>
						<Typography
							sx={{
								color: "#fff",
								fontWeight: "400",
								fontSize: "15px",
								textAlign: "center",
								fontFamily: "open sans",
							}}
						>
							No Records Found
						</Typography>
					</>
				) : (
					<>
						<Box
							sx={{
								fontWeight: "400",
								fontSize: "15px",
								textAlign: "center",
								color: "#FFFFFF",
								cursor: "pointer",
								fontFamily: "open sans",
							}}
							onClick={() => connect()}
						>
							Connect wallet
						</Box>
					</>
				)}
			</Box>
		</div>
	);
};

export default MintedTable;
