import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import icon1 from "../../images/icons/icons1.png";
import icon2 from "../../images/icons/icons2.png";
import icon3 from "../../images/icons/icons3.png";
import icon4 from "../../images/icons/icons4.png";
import { useRef } from "react";
import gsap from "gsap";
import bg1 from "../../images/background21.png";
import bg2 from "../../images/background22.png";

const data1 = [
	{
		title: "$49.7M",
		description: "Raised Capital",
	},
	{
		title: "111",
		description: "Idea Funded",
	},
	{
		title: "$417M",
		description: "Project MKT Cap",
	},
	{
		title: "35700",
		description: "Unique Participants",
	},
];

const data2 = [
	{
		icon: icon1,
		title: "Launch Project",
		des: "The decentralized launchpad feature allows for the launch of new projects in a secure and decentralized manner, using BSC blockchain to facilitate the launch This allows for a more democratic and community-driven approach to launching new projects, as anyone can participate in the launch and have a say in its development.",
		btn: "Launch Now",
	},
	{
		icon: icon2,
		title: "Token Minter",
		des: "The token minter feature allows for the creation and distribution of new tokens, which are used to launch new projects, raise funds, and create liquidity for trading. This is a key aspect of DeFi protocols, as liquidity is essential for the growth and success of the underlying projects.",
		btn: "Mint Now",
	},
	{
		icon: icon3,
		title: "Liquidity Locker",
		des: "The liquidity locker feature provides LP Token Lock feature, allowing users to lock their LP Tokens in the contract in exchange for LP tokens. The locked assets in the liquidity locker serve as collateral for the liquidity tokens and are hold to ensure that traders have access to the assets they need to execute trades on the DeFi platforms.",
		btn: "Lock Now",
	},
	{
		icon: icon4,
		title: "Token Locker",
		des: "The token locker feature allows users to lock their tokens for a certain period of time in exchange for a reward, such as interest, discounts or other incentives. By locking their tokens, users can earn rewards for providing liquidity to the DeFi ecosystem and supporting the growth of the underlying projects.",
		btn: "Lock Now",
	},
];

const NewFeatures = () => {
	const hoverRef = useRef([]);
	const hoveranimationStart = (e) => {
		gsap.to(hoverRef.current[e], {
			y: -20,
		});
	};
	const hoveranimationEnd = (e) => {
		gsap.to(hoverRef.current[e], {
			y: 0,
		});
	};

	return (
		<>
			<Box
				sx={{
					background: `url(${bg1}),url(${bg2})`,
					backgroundPosition: "10% 30%, 90% 30%",
					backgroundRepeat: "no-repeat, no-repeat",
					backgroundSize: "auto,auto",
				}}
			>
				<Container maxWidth="lg">
					<Grid container spacing={3} pb={5}>
						{data1.map((d, i) => (
							<Grid item md={3} sm={6} xs={12} key={i}>
								<Box
									sx={{
										width: "100%",
										height: "100%",
										backgroundClip:
											"content-box, border-box, border-box, border-box, border-box",
										borderRadius: "15px",
										boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
										border: "1px solid transparent",
										backgroundImage:
											"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
										backgroundOrigin:
											"content-box, border-box, border-box, border-box, border-box",
										backgroundRepeat: "no-repeat",
										backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
										backgroundPosition:
											"top left, top left, top right, bottom left, bottom right",
									}}
								>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											background: "#090E27",
											borderRadius: "15px",
										}}
										p={5}
									>
										<Typography
											sx={{
												fontSize: "30px",
												fontFamily: "josefin Sans",
												fontWeight: "bold",
												color: "text.primary",
												// textShadow: "0 0 4px #fff",
											}}
										>
											{d.title}
										</Typography>
										<Typography
											sx={{
												fontSize: "16px",
												fontFamily: "open sans",

												color: "#667394",
											}}
										>
											{d.description}
										</Typography>
									</Box>
								</Box>
							</Grid>
						))}
					</Grid>
					<Typography
						sx={{
							textTransform: "uppercase",
							fontFamily: "open Sans",

							textAlign: "center",
							fontSize: "20px",
							color: "#667394",
						}}
						pt={10}
					>
						Unlocking the Potential of DeFi by MetasGate's Decentralized Launchpad
					</Typography>
					<hr
						style={{
							width: "20%",
							border: "2px transparent",
							backgroundImage:
								"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
							backgroundOrigin: "border-box",
							backgroundClip: "content-box, border-box",
							padding: "1px",
						}}
					/>
					<Grid container spacing={3} pt={5}>
						{data2.map((d, i) => (
							<Grid
								item
								md={3}
								sm={6}
								xs={12}
								key={i}
								ref={(ref) => (hoverRef.current[i] = ref)}
								onMouseEnter={() => hoveranimationStart(i)}
								onMouseLeave={() => hoveranimationEnd(i)}
							>
								<Box
									sx={{
										width: "100%",
										height: "100%",
										backgroundClip:
											"content-box, border-box, border-box, border-box, border-box",
										borderRadius: "8px",
										boxShadow: "0px 0px 16px rgba(70, 60, 221, 0.34)",
										border: "1px solid transparent",
										backgroundImage:
											"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
										backgroundOrigin:
											"content-box, border-box, border-box, border-box, border-box",
										backgroundRepeat: "no-repeat",
										backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
										backgroundPosition:
											"top left, top left, top right, bottom left, bottom right",
										"&:hover": {
											"& .applybtn": {
												background:
													"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
											},
											y: 20,
										},
									}}
								>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											alignItems: "center",
											borderRadius: "15px",
											textAlign: "center",
											backgroundColor: "#090E27",
										}}
										py={5}
										px={1}
									>
										<Box
											sx={{
												width: "100%",
												height: "100%",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												textAlign: "center",
											}}
										>
											<img src={d.icon} alt="icon" width="50px" />

											<Typography
												sx={{
													fontSize: "18px",
													fontWeight: "bold",
													color: "text.primary",
													fontFamily: "josefin Sans",
												}}
												py={2}
											>
												{d.title}
											</Typography>
											<Typography
												sx={{
													fontSize: "13px",
													fontFamily: "open sans",
												}}
												pb={2}
											>
												{d.des}
											</Typography>
										</Box>
										<Button
											fontWeight="500"
											fontSize="16px"
											lineHeight="24px"
											letterSpacing="0.02em"
											sx={{
												textTransform: "capitalize",
												width: "150px",
												height: "48px",
												color: "#FFFFFF",
												borderRadius: "56px",
												border: "2px transparent",
												backgroundImage:
													"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												backgroundOrigin: "border-box",
												backgroundClip: "content-box, border-box",
												padding: "2px",
											}}
											className="applybtn"
										>
											<span>{d.btn}</span>
										</Button>
									</Box>
								</Box>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</>
	);
};

export default NewFeatures;
