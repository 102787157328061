import React from "react";
import {
	Box,
	Container,
	Divider,
	Grid,
	Stack,
	useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTokenLockerContract } from "../../ConnectivityAss/hooks";
import { useContext } from "react";
import { useState } from "react";
import { AppContext, url } from "../../utils";
import axios from "axios";
import moment from "moment";
import TextField from "../TextField";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { toast } from "react-toastify";
import Loader from "../../loading";
import tokenABI from "../../ConnectivityAss/tokenAbi.json";
import { Contract } from "ethers";
import { tokenLocker } from "../../ConnectivityAss/environment";
import TimerCountDown from "../Timer/timer";

// function createData(address, amount, time) {
// 	return { address, amount, time };
// }
// const rows = [createData("Cupcake", 305, 3.7), createData("Donut", 452, 25.0)];

export default function MyLockRecord() {
	const { id, count } = useParams();
	const matches = useMediaQuery("(max-width:750px)");

	///////////// Connectivity ////////////
	const { account, signer } = useContext(AppContext);
	const tokenLockerContract = useTokenLockerContract(signer);
	const [withdrawAmount, setwithdrawAmount] = useState("");
	const [lockTimeForUpdate, setlockTimeForUpdate] = useState(
		new Date().getTime()
	);
	const [incrementAmount, setincrementAmount] = useState();
	const [loading, setloading] = useState(false);
	const [claimableAmount, setclaimableAmount] = useState(0);
	const [unlockDateContract, setunlockDateContract] = useState(0);
	const [amountForUpdate, setamountForUpdate] = useState("");
	const [claimedAmount, setclaimedAmount] = useState(0);
	const [tokenDetails, settokenDetails] = useState({
		addedAt: "-",
		account: "-",
		lockedAmount: "-",
		tokenDecimals: "-",
		tokenAddress: "-",
		tokenName: "-",
		tokenSymbol: "-",
		lockedTime: "-",
	});
	const init = async () => {
		try {
			setloading(true);
			const {
				data: {
					data: {
						addedAt,
						tokenAddress,
						tokenName,
						tokenSymbol,
						tokenDecimals,
						account,
						lockedTokens,
						startTime,
						endTime,
					},
				},
			} = await axios.get(`${url}tokenRouter/getToken/${id}`);
			let object = {
				addedAt,
				tokenAddress,
				tokenName,
				tokenSymbol,
				tokenDecimals,
				account,
				lockedTokens,
				startTime,
				endTime,
			};
			settokenDetails(object);
			setincrementAmount(`Enter amount in ${tokenSymbol}`);
			const lockData = await tokenLockerContract.getLock(count.toString());
			setunlockDateContract(+lockData[7]);
			setclaimableAmount(formatUnits(lockData[2], tokenDecimals).toString());
			setclaimedAmount(formatUnits(lockData[3], tokenDecimals).toString());
			setloading(false);
		} catch (error) {
			console.log(error);
			setloading(false);
		}
	};
	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	useEffect(() => {
		setwithdrawAmount(claimableAmount);
		setlockTimeForUpdate(moment.unix(unlockDateContract).format().slice(0, -9));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenDetails, unlockDateContract]);

	const withdrawHandaler = async () => {
		// const format = "YYYY-MM-DD HH:mm";
		let date = new Date();
		let currentdate = moment(date);
		// console.log(currentdate);
		if (!account) {
			toast.error("Please connect your wallet");
		} else if (lockTimeForUpdate > currentdate) {
			toast.error("You cannot withdraw before lock time ends");
		} else if (withdrawAmount > tokenDetails.lockedAmount) {
			toast.error("Please enter valid amount");
		} else {
			try {
				setloading(true);
				const withdraw = await tokenLockerContract.withdraw(
					count.toString(),
					parseUnits(
						withdrawAmount.toString(),
						tokenDetails.tokenDecimals.toString()
					)
				);
				await withdraw.wait();
				toast.success("Transection Confirmed");
				init();
				window.location.reload();
			} catch (error) {
				console.log(error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	const relockHandaler = async () => {
		if (!account) {
			toast.error("Please connect your wallet");
		} else if (lockTimeForUpdate <= tokenDetails.endTime) {
			toast.error("Please enter valid amount");
		} else {
			try {
				setloading(true);
				let time = moment(lockTimeForUpdate.toString()).format("X");
				const relock = await tokenLockerContract.relock(count.toString(), time);
				await relock.wait();
				const reLockData = {
					endTime: time,
				};
				let { data } = await axios.put(
					url + `tokenRouter/UpdateLockData/${id}`,
					reLockData
				);
				if (data.success) {
					toast.success("Transection Confirmed");
					setloading(false);
					init();
				} else {
					toast.error(data.message);
					setloading(false);
				}
				init();
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	const updateHandaler = async () => {
		if (!account) {
			toast.error("Please connect your wallet");
		} else {
			try {
				setloading(true);
				const TokenContract = await new Contract(
					tokenDetails.tokenAddress,
					tokenABI,
					signer
				);
				// const decimals = await TokenContract.decimals();
				const approve = await TokenContract.approve(
					tokenLocker,
					parseUnits(
						amountForUpdate.toString(),
						tokenDetails.tokenDecimals.toString()
					).toString()
				);
				await approve.wait();
				const relock = await tokenLockerContract.incrementLock(
					count.toString(),
					parseUnits(
						amountForUpdate.toString(),
						tokenDetails.tokenDecimals.toString()
					)
				);
				await relock.wait();
				const reLockData = {
					lockedAmount: amountForUpdate,
				};
				let { data } = await axios.put(
					url + `tokenRouter/UpdateLockData/${id}`,
					reLockData
				);
				if (data.success) {
					toast.success("Transection Confirmed");
					setloading(false);
				} else {
					toast.error(data.message);
					setloading(false);
				}
				init();
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	return (
		<Box py={15}>
			<Loader loading={loading} />
			<Container maxWidth="md">
				<Box
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						sx={{
							color: "#fff",
							fontWeight: "600",
							fontSize: matches ? "16px" : "20px",
						}}
						mt={3}
						textAlign="center"
					>
						Unlock in
					</Box>
					<Box my={3} px={{ md: 20, sm: 15, xs: 5 }} textAlign="center">
						{/* {console.log(+unlockDateContract, "+unlockDateContract")} */}
						{+unlockDateContract > 0 && (
							// <WithdrawalTimer endTime={+unlockDateContract} />
							<TimerCountDown time={+unlockDateContract} label={false} />
						)}
					</Box>
				</Box>
				<Box
					py={1}
					mt={2}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						py={2}
						px={2}
						color="#fff"
						textAlign="left"
						sx={{
							color: "#fff",
							fontWeight: "600",
							fontSize: matches ? "16px" : "20px",
						}}
					>
						Token Info
					</Box>
					<Divider color="#383A41" />
					<Container maxWidth="lg">
						<Box py={2}>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
							>
								<Box
									sx={{
										color: "#fff",
										fontWeight: "500",

										fontSize: matches ? "13px" : "17px",
									}}
								>
									Token Address
								</Box>
								<Box
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									<a
										style={{ color: "#a6cfff" }}
										href={`https://testnet.bscscan.com/address/${tokenDetails.tokenAddress}`}
										target="_blank"
										rel="noreferrer"
									>
										{matches
											? tokenDetails.tokenAddress.slice(0, 5) +
											  "..." +
											  tokenDetails.tokenAddress.slice(-4)
											: tokenDetails.tokenAddress}
									</a>
								</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Token Name</Box>
								<Box> {tokenDetails.tokenName}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Token Symbol</Box>
								<Box>{tokenDetails.tokenSymbol}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Token Decimals</Box>

								<Box>{tokenDetails.tokenDecimals}</Box>
							</Stack>
						</Box>
					</Container>
				</Box>
				<Box
					my={2}
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						py={2}
						px={2}
						color="#fff"
						textAlign="left"
						sx={{
							color: "#fff",
							fontWeight: "600",

							fontSize: matches ? "16px" : "20px",
						}}
					>
						Lock Info
					</Box>
					<Divider color="#383A41" />
					<Container maxWidth="lg">
						<Box py={2}>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Total Amount Locked</Box>
								<Box>
									{" "}
									{parseFloat(claimableAmount - claimedAmount).toFixed(2)}
								</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "400",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Owner</Box>
								<Box
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									<a
										style={{ color: "#a6cfff" }}
										href={`https://testnet.bscscan.com/address/${tokenDetails.account}`}
										target="_blank"
										rel="noreferrer"
									>
										{matches
											? tokenDetails.account.slice(0, 5) +
											  "..." +
											  tokenDetails.account.slice(-4)
											: tokenDetails.account}
									</a>
								</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Lock Date </Box>
								<Box> {moment(tokenDetails.addedAt).format("LL")} </Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Unlock Date</Box>
								<Box>{moment.unix(unlockDateContract).format("LL")}</Box>
							</Stack>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={4}>
									<Box
										mt={2}
										display="flex"
										alignItems="center"
										flexDirection="column"
									>
										<TextField
											type="text"
											placeholder="Enter Amount in BNB"
											label="Withdraw Amount "
											className="fieldText"
											value={withdrawAmount}
											onChange={(e) => setwithdrawAmount(e.target.value)}
										/>
										<Box mt={1}></Box>
										<Box
											px={2}
											py={1}
											style={{ cursor: "pointer" }}
											borderRadius="5px"
											bgcolor="#a6cfff"
											color="#11112e"
											textAlign="center"
											width="120px"
											onClick={() => {
												withdrawHandaler();
											}}
										>
											Withdraw
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Box
										mt={2}
										display="flex"
										alignItems="center"
										flexDirection="column"
									>
										<TextField
											type="datetime-local"
											placeholder="Enter here"
											label="Lock Until"
											className="fieldText"
											value={
												+lockTimeForUpdate === 0
													? moment.unix(unlockDateContract).format("LL")
													: lockTimeForUpdate
											}
											onChange={(e) => setlockTimeForUpdate(e.target.value)}
										/>
										<Box mt={1}></Box>
										<Box
											px={2}
											py={1}
											style={{ cursor: "pointer" }}
											borderRadius="5px"
											bgcolor="#a6cfff"
											color="#11112e"
											textAlign="center"
											width="120px"
											onClick={() => {
												relockHandaler();
											}}
										>
											Relock
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Box
										mt={2}
										display="flex"
										alignItems="center"
										flexDirection="column"
									>
										<TextField
											type="text"
											placeholder={incrementAmount}
											label="Increment Amount"
											className="fieldText"
											value={amountForUpdate}
											onChange={(e) => setamountForUpdate(e.target.value)}
										/>
										<Box mt={1}></Box>
										<Box
											px={2}
											py={1}
											style={{ cursor: "pointer" }}
											borderRadius="5px"
											bgcolor="#a6cfff"
											color="#11112e"
											textAlign="center"
											width="120px"
											onClick={() => {
												updateHandaler();
											}}
										>
											Update
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>
			</Container>
		</Box>
	);
}
