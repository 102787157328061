/* eslint-disable import/no-anonymous-default-export */
import {
	Container,
	Box,
	Grid,
	Button,
	useMediaQuery,
	Checkbox,
	Stack,
	InputBase,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../loading";
import { AppContext, url } from "../../../utils";
import { useLiquidityLockerContract } from "../../../ConnectivityAss/hooks";
import TextField from "../../TextField";
import lptokenABI from "../../../ConnectivityAss/lpTokenAbi.json";
import tokenABI from "../../../ConnectivityAss/tokenAbi.json";
import axios from "axios";
import moment from "moment";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { liquidityLocker } from "../../../ConnectivityAss/environment";
import { Contract } from "ethers";

export default () => {
	const { account, signer, connect, balance } = useContext(AppContext);
	const liquidityLockerContract = useLiquidityLockerContract(signer);
	const matches = useMediaQuery("(min-width:700px)");
	const [withdrawState, setWithdrawState] = useState(false);
	const [loading, setloading] = useState(false);
	const [referr, setReferr] = useState(false);
	const [refAddress, setrefAddress] = useState(0);
	const [lockedLiqTokens, setlockedLiqTokens] = useState("");
	const [endTime, setendTime] = useState(new Date().getTime());
	const [tokenAddress, settokenAddress] = useState("");
	const [tokenName, settokenName] = useState("");
	const [tokenSymbol, settokenSymbol] = useState("");
	const [tokenDecimals, settokenDecimals] = useState("");
	const [tokenContract, settokenContract] = useState("");
	const [tokenBalance, settokenBalance] = useState("");
	const [withdrawerAddress, setwithdrawerAddress] = useState("");
	const [ethFeeState, setethFeeState] = useState(true);
	const [ethFeeShow, setethFeeShow] = useState("");
	const [refDiscount, setrefDiscount] = useState(0);
	const [ethFee, setethFee] = useState(0);
	const [lockedData, setLockedData] = useState(false);

	async function check(address) {
		const {
			data: { data },
		} = await axios.get(`${url}liquidityRouter/getUserLiquidity/${account}`);
		console.log(data, "previous locked data");
		if (data.length > 0) {
			let result = data.some((o) => o.tokenAddress === address);
			console.log(result);
			setLockedData(result);
			return result;
		}
	}

	const list = [
		"Lock Splitting",
		"Liquidity migration",
		"Relocking",
		"Lock ownership trasfer",
	];
	const tokenAddressHandler = async (address) => {
		try {
			setloading(true);
			settokenAddress(address);
			let ch = await check(address);
			if (!account) {
				toast.error("Please connect your wallet first.");
				setloading(false);
			} else if (ch) {
				toast.error("Tokens already Locked from this account.");
				setloading(false);
			} else {
				setloading(true);
				const TokenContract = await new Contract(address, lptokenABI, signer);
				const decimals = await TokenContract.decimals();

				const token0 = await TokenContract.token0();
				const token1 = await TokenContract.token1();
				const TokenContract0 = await new Contract(token0, tokenABI, signer);
				const TokenContract1 = await new Contract(token1, tokenABI, signer);
				const name = await TokenContract1.name();
				const symbol = await TokenContract0.name();
				settokenName(name);
				settokenSymbol(symbol);
				const tNumberOftokens = await TokenContract.balanceOf(account);
				settokenDecimals(decimals);
				settokenContract(TokenContract);
				settokenBalance(
					formatUnits(
						tNumberOftokens.toString(),
						decimals.toString()
					).toString()
				);
				const { ethFee, referralDiscount } =
					await liquidityLockerContract.gFees();
				setethFee(ethFee.toString());
				setethFeeShow(formatUnits(ethFee.toString()));
				setrefDiscount(referralDiscount.toString());
				setloading(false);
			}
		} catch (error) {
			setloading(false);
			toast.error("invalid token address");
		}
	};

	const LockLiquidityHandler = async () => {
		let stratTime = moment().format("X");
		let referral = refAddress
			? refAddress
			: "0x0000000000000000000000000000000000000000";
		let discountFee = refAddress
			? (ethFee * (1000 - refDiscount)) / 1000
			: ethFee;
		let withdrawer = withdrawerAddress ? withdrawerAddress : account;
		let ethFeeses = ethFeeState ? discountFee : 0;
		if (!account) {
			toast.error("Error! Please connect your wallet .");
		} else if (!lockedLiqTokens) {
			toast.error("Error! Please Enter  tokens.");
		} else if (!endTime || endTime < stratTime) {
			toast.error("Error! Please select valid time.");
		} else {
			try {
				setloading(true);
				const approve = await tokenContract.approve(
					liquidityLocker,
					parseUnits(
						lockedLiqTokens.toString(),
						tokenDecimals.toString()
					).toString()
				);
				await approve.wait();
				const tx = await liquidityLockerContract.lockLPToken(
					tokenAddress,
					parseUnits(
						lockedLiqTokens.toString(),
						tokenDecimals.toString()
					).toString(),
					endTime.toString(),
					referral,
					ethFeeState,
					withdrawer,
					{ value: ethFeeses }
				);
				await tx.wait();
				let tokenData = {
					tokenAddress,
					tokenName,
					tokenSymbol,
					tokenDecimals,
					account: account.toLowerCase(),
					withdrawer,
					lockedLiqTokens,
					endTime,
				};

				const { data } = await axios.post(
					`${url}liquidityRouter/liquidityLocker`,
					tokenData
				);
				console.log(data, tokenData, "hehe");
				if (data.success) {
					toast.success("Transection Confirmed");
					setloading(false);
				} else {
					toast.error(data.message);
					setloading(false);
				}
				setloading(false);
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	// const ApproveHandler = async () => {
	// if (!userWalletAddress) {
	//   toast.error("Error! Please connect your wallet .");
	// } else {
	//   try {
	//     setloading(true);
	//     let tx = await SecondaryTokenContract.approve(
	//       Environment.LiquidityLocker,
	//       Gfee
	//     );
	//     setloading(false);
	//     toast.success("Token Approve Successfully")
	//   } catch (error) {
	//     console.log("err", error);
	//     if (error?.data?.message) {
	//       toast.error(error?.data?.message);
	//     } else {
	//       toast.error(error?.message);
	//     }
	//     setloading(false);
	//   }
	// }
	// };

	return (
		<>
			<Loading loading={loading} />
			{tokenContract ? (
				<Box pb={10} pt={12} fontFamily="Urbanist">
					<Container>
						<Link to="/services">
							<Box>
								<ArrowBackIosNewIcon
									style={{ color: "white", verticalAlign: "-3px" }}
								/>
								<Box
									pl={2}
									component="span"
									sx={{
										fontSize: { md: "26px", xs: "20px" },
										color: "white",
										fontFamily: "josefin Sans",
									}}
								>
									Go Back
								</Box>
							</Box>
						</Link>

						<Box
							sx={{
								fontSize: { md: "50px", xs: "24px" },
								fontWeight: "700",
								color: "white",
								textAlign: "center",
								fontFamily: "josefin Sans",
							}}
						>
							Lock how many tokens?
						</Box>

						<Box
							px={matches ? 4 : 2}
							py={1.5}
							mt={3}
							bgcolor="#090e27"
							border="1px solid #5A5A5A"
							borderRadius="20px"
							boxShadow="-11px 17px 28px #1D1736"
							fontSize={{ md: "26px", xs: "20px" }}
						>
							<Box>
								<Box
									sx={{
										display: "flex",
										color: "white",
										justifyContent: "space-between",
									}}
								>
									<Box>
										<InputBase
											style={{ color: "#fff", fontSize: "22px" }}
											fullWidth
											id="standard-basic"
											variant="standard"
											placeholder="0.0"
											value={lockedLiqTokens}
											onChange={(e) => setlockedLiqTokens(e.target.value)}
										/>
									</Box>
									<Box fontFamily="open sans">
										Balance:{" "}
										{tokenBalance
											? parseFloat(tokenBalance).toFixed(2)
											: "0.00"}
									</Box>
								</Box>
							</Box>

							<Box
								my={1}
								sx={{
									display: "flex",
									color: "white",
									justifyContent: "space-between",
									flexDirection: matches ? "row" : "column",
								}}
							>
								<Box display="flex">
									<Box display="flex">
										<Button
											sx={{
												borderRadius: "50px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "22px", xs: "16px" },
												padding: "5px 20px",
												fontWeight: "500, Medium",
											}}
											onClick={() =>
												setlockedLiqTokens((tokenBalance * 25) / 100)
											}
										>
											25%
										</Button>
										<Button
											sx={{
												borderRadius: "50px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "22px", xs: "16px" },
												padding: "5px 20px",
												fontWeight: "500, Medium",
												marginLeft: "10px",
											}}
											onClick={() =>
												setlockedLiqTokens((tokenBalance * 50) / 100)
											}
										>
											50%
										</Button>
										<Button
											sx={{
												borderRadius: "50px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "22px", xs: "16px" },
												padding: "5px 20px",
												fontWeight: "500, Medium",
												marginLeft: "10px",
											}}
											onClick={() =>
												setlockedLiqTokens((tokenBalance * 75) / 100)
											}
										>
											75%
										</Button>
										<Button
											sx={{
												borderRadius: "50px",
												color: "white",
												border: "1px solid white",
												fontSize: { md: "22px", xs: "16px" },
												padding: "5px 20px",
												fontWeight: "500, Medium",
												marginLeft: "10px",
											}}
											onClick={() => setlockedLiqTokens(tokenBalance)}
										>
											100%
										</Button>
									</Box>
								</Box>
								<Box display="flex" mt={matches ? 0 : 1.5}>
									<Box pr={2}>UNIV2</Box>
									<Button
										sx={{
											background:
												"linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
											boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
											borderRadius: "10px",
											color: "white",
											"&:hover": {
												color: "#8454EB",
												background: " white",
											},
											"&:active": {
												color: "#8454EB",
												background: " white",
											},
											textTransform: "lowercase",
										}}
										onClick={() => {
											if (account) {
												setlockedLiqTokens(tokenBalance);
											} else {
												connect();
											}
										}}
									>
										{account ? "Max" : "Connect"}
									</Button>
								</Box>
							</Box>
						</Box>

						<Box
							mt={5}
							sx={{
								fontSize: { md: "35px", xs: "20px" },
								fontWeight: "600",
								color: "white",
								textAlign: "center",
							}}
						>
							Unlock Date
						</Box>

						<Box mt={4}>
							<Box
								color="white"
								sx={{
									fontSize: { md: "22px", xs: "16px" },
									fontWeight: "500",
								}}
								mb={2}
							>
								End Date
							</Box>

							<TextField
								type="datetime-local"
								placeholder="Enter here"
								className="fieldText"
								onChange={(e) => {
									setendTime(moment(e.target.value).format("X"));
								}}
							/>
						</Box>

						<Box
							sx={{
								fontSize: { md: "35px", xs: "20px" },
								fontWeight: "600",
								color: "white",
								textAlign: "center",
								fontFamily: "open sans",
							}}
						>
							Who can withdraw the tokens ?
						</Box>

						<Box
							mt={3}
							sx={{
								textAlign: "center",
							}}
						>
							<Button
								onClick={() => setWithdrawState(false)}
								sx={{
									bgcolor: "#090e27",
									border: "1px solid #5A5A5A",
									borderRadius: "10px",
									boxShadow: "-11px 17px 28px #1D1736",
									fontSize: { md: "20px", xs: "16px" },
									textTransform: "lowercase",
									color: "white",
									fontWeight: "300",
								}}
							>
								me
							</Button>
							<Button
								onClick={() => setWithdrawState(true)}
								sx={{
									paddingLeft: "10px",
									fontSize: { md: "20px", xs: "16px" },
									textTransform: "lowercase",
									color: "white",
									fontWeight: "300",
								}}
							>
								someone else
							</Button>
						</Box>
						{withdrawState ? (
							<Box
								mt={4}
								mx={matches ? 7 : 3}
								component="form"
								sx={{
									"& > :not(style)": { width: "100%" },
								}}
								noValidate
								autoComplete="off"
							>
								<TextField
									size="small"
									variant="outlined"
									sx={{ border: "1px solid #5A5A5A", borderRadius: "4px" }}
									placeholder="Enter withdrawer address"
									onChange={(e) => {
										if (e.target.value.length === 42) {
											setwithdrawerAddress(e.target.value);
										} else {
											toast.error("Please enter valid address");
										}
									}}
								/>
							</Box>
						) : null}

						<Box
							mt={3}
							sx={{
								fontSize: { md: "35px", xs: "20px" },
								fontWeight: "600",
								color: "white",
								textAlign: "center",
								fontFamily: "open sans",
							}}
						>
							Do you have a valid referal address
						</Box>
						<Box
							mt={3}
							sx={{
								fontSize: { md: "26px", xs: "18px" },
								fontWeight: "600",
								color: "white",
								textAlign: "center",
								fontFamily: "open sans",
							}}
						>
							Enjoy a 15% flatrate discount if so !
						</Box>

						<Box
							mt={3}
							sx={{
								textAlign: "center",
							}}
						>
							<Button
								onClick={() => setReferr(false)}
								sx={{
									bgcolor: "#090e27",
									border: "1px solid #5A5A5A",
									borderRadius: "10px",
									boxShadow: "-11px 17px 28px #1D1736",
									fontSize: { md: "20px", xs: "16px" },
									color: "white",
									fontWeight: "300",
								}}
							>
								no
							</Button>
							<Button
								onClick={() => setReferr(true)}
								sx={{
									paddingLeft: "10px",
									fontSize: { md: "20px", xs: "16px" },
									textTransform: "lowercase",
									color: "white",
									fontWeight: "300",
								}}
							>
								yes
							</Button>
						</Box>
						{referr ? (
							<Box
								mt={4}
								mx={matches ? 7 : 3}
								component="form"
								sx={{
									"& > :not(style)": { width: "100%" },
								}}
								noValidate
								autoComplete="off"
							>
								<TextField
									size="small"
									variant="outlined"
									sx={{
										border: "1px solid #5A5A5A",
										borderRadius: "4px",
										fontFamily: "open sans",
									}}
									placeholder="Enter rferral address"
									onChange={(e) => {
										if (e.target.value.length === 42) {
											setrefAddress(e.target.value);
										} else {
											toast.error("Please enter valid address");
										}
									}}
								/>
							</Box>
						) : (
							""
						)}

						<Box
							mt={3}
							sx={{
								fontSize: { md: "35px", xs: "20px" },
								fontWeight: "600",
								color: "white",
								textAlign: "center",
							}}
						>
							Fee Options
						</Box>

						<Box
							mt={3}
							sx={{
								fontSize: { md: "26px", xs: "18px" },
								fontWeight: "600",
								color: "white",
								textAlign: "center",
							}}
						>
							<Checkbox
								defaultChecked
								value={ethFeeState}
								onChange={() => setethFeeState(!ethFeeState)}
							/>
							<Box
								pl={2}
								component="span"
								sx={{
									fontSize: { md: "26px", xs: "18px" },
									fontWeight: "300",
								}}
							>
								{ethFeeShow} BNB
							</Box>
						</Box>

						<Box
							pl={matches ? 15 : 10}
							sx={{
								fontSize: { md: "26px", xs: "18px" },
								fontWeight: "300",
								color: "white",
								textAlign: "center",
							}}
						>
							(+ 1% UNIV2)
						</Box>

						<Box
							mt={3}
							sx={{
								fontSize: { md: "26px", xs: "18px" },
								fontWeight: "300",
								color: "white",
								textAlign: "center",
								fontFamily: "open sans",
							}}
						>
							Your Balance:{" "}
							{Number(+formatUnits(balance.toString(), "18")).toFixed(4)}
						</Box>

						<Box
							mt={matches ? 12 : 7}
							sx={{
								fontSize: { md: "30px", xs: "22px" },
								fontWeight: "500",
								color: "white",
								maxWidth: "80%",
								fontFamily: "open sans",
							}}
						>
							Once tokens are locked they cannot be withdrawn under any
							Circumstances Until the timer has expired please Ensure the
							parameters are Correct as they are final
						</Box>

						<Stack
							mt="100px"
							direction={"row"}
							alignItems="center"
							justifyContent={"center"}
						>
							{/* <Button
                disabled={ethFeeState}
                sx={{
                  cursor: ethFeeState ? "no-drop" : "pointer",
                  width: matches ? "33%" : "40%",
                  background:
                    "linear-gradient(92.51deg, #3461FF 3.3%, #8454EB 93.12%)",
                  boxShadow: "0px 13px 17px rgba(119, 87, 239, 0.2)",
                  borderRadius: "56px",
                  color: "white",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: { md: "26px", xs: "18px" },
                  marginRight: "15px",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: "#8454EB",
                    background: " white",
                  },
                  "&:active": {
                    color: "#8454EB",
                    background: " white",
                  },
                }}
                onClick={() => ApproveHandler()}
              >
                Approve
              </Button> */}
							<Button
								disabled={!ethFeeState}
								sx={{
									cursor: ethFeeState ? "pointer" : "no-drop",
									height: "48px",
									borderRadius: "56px",
									width: "200px",
									border: "2px transparent",
									backgroundImage:
										"linear-gradient(#090e27, #090e27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
									backgroundOrigin: "border-box",
									backgroundClip: "content-box, border-box",
									padding: "2px",
									marginTop: "22px",
									fontSize: "20px",
									letterSpacing: "2%",
									fontWeight: 500,
									fontFamily: "open sans",
									color: "#fff",
									textTransform: "capitalize",
									"&:hover": {
										background:
											"linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)!important",
									},
								}}
								onClick={() => LockLiquidityHandler()}
							>
								Lock
							</Button>
						</Stack>
					</Container>
				</Box>
			) : (
				<Box pb={10} pt={12}>
					<Container maxWidth="lg">
						<Box bgcolor="#090e27" borderRadius="15px" p={6}>
							<Box
								textAlign="center"
								fontSize={{ md: "40px", xs: "30px" }}
								fontFamily="Josefin Sans"
								fontWeight="700"
								color="#ffffff"
								my={5}
							>
								Meta's Gate Liquidity Locker
							</Box>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Box
										color="#7C7B97"
										fontSize={"20px"}
										fontFamily="Open Sans"
										fontWeight="400"
										pt={2.5}
									>
										Here you can lock the liquidity of your token
									</Box>
									<Box
										color="#fff"
										fontSize={"22px"}
										fontFamily="josefin Sans"
										fontWeight="700"
										pt={2.5}
									>
										Our lockers offer
									</Box>
									<Box color="#ffffff" pt={2.5}>
										{list.map((item) => {
											return (
												<Box display="flex" alignItems="center">
													<Box>
														<img src="/Dot.png" width="10px" alt="" />
													</Box>
													<Box
														fontSize={"20px"}
														fontWeight="400"
														fontFamily="Open Sans"
														pl={1}
													>
														{item}
													</Box>
												</Box>
											);
										})}
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<Box
										color="#ffffff"
										fontSize={{ md: "24px", xs: "20px" }}
										textAlign="left"
										fontFamily="Open Sans"
										fontWeight="700"
										pt={2.5}
									>
										Enter the address{" "}
									</Box>
									<Box
										component="form"
										sx={{
											"& > :not(style)": { width: "100%" },
										}}
										pt={3}
										noValidate
										autoComplete="off"
									>
										<InputBase
											sx={{
												bgcolor: "#11112e",
												borderRadius: "4px",
												padding: "10px 20px",
											}}
											placeholder="METASGATE V2 Pair Address"
											onChange={(e) => {
												if (e.target.value.length === 42) {
													tokenAddressHandler(e.target.value);
												}
												settokenAddress(e.target.value);
											}}
										/>
									</Box>
									<Box
										textAlign="center"
										color="#ffffff"
										fontSize={{ md: "15px", xs: "14px" }}
										fontWeight="400"
										pt={3}
										fontFamily="Urbanist"
									>
										eg: 0xDc546cd7BF529d7A6a1767e263eEa34C3178a704
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>
			)}
		</>
	);
};
