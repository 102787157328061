import React from "react";
import "./textField.scss";

const TextAreaField = ({
  type,
  required,
  placeholder,
  className,
  value,
  label,
  QuestionIcon,
  onChange,
}) => {
  return (
    <div
      className={`textAreaFields w100 pR ${className} ${
        QuestionIcon ? "icon" : ""
      }`}
    >
      {label && <label className="dB">{label}</label>}
      <textarea
        className="w100"
        type={type}
        required={required}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {/* {QuestionIcon && <img className="pA" src={Question} width={20} alt="" /> } */}
    </div>
  );
};

export default TextAreaField;
