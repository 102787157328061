import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Button, Stack, TableHead, useMediaQuery } from "@mui/material";
import { useState } from "react";
import thinking from "../../images/thinkingface.png";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageIcon sx={{ color: "#fff" }} />
				) : (
					<FirstPageIcon sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight sx={{ color: "#fff" }} />
				) : (
					<KeyboardArrowLeft sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft sx={{ color: "#fff" }} />
				) : (
					<KeyboardArrowRight sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageIcon sx={{ color: "#fff" }} />
				) : (
					<LastPageIcon sx={{ color: "#fff" }} />
				)}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, f, amount) {
	return { name, f, amount };
}

const rows = [
	createData("Abii", "(A.A)", "1000.0 (A.A)"),
	createData("TS", "TST", "1000 TST"),
	createData("BSC", "Bleach", "90 Bleach"),
	createData("Alpha", "Alpha", "99 Alpha"),
	createData("ADA", "ADA", "100 ADA"),
];

function Liquidity() {
	const matches = useMediaQuery("(max-width:700px)");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	// const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	return (
		<Box
			py={1}
			bgcolor="#090e27"
			border="1px solid #5A5A5A"
			borderRadius="30px"
			boxShadow="-11px 17px 28px #1D1736"
		>
			<TableContainer>
				<Table style={{ minWidth: "500px" }}>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									paddingLeft: "50px",
									fontWeight: "700",
									fontSize: matches ? "14px" : "26px",
									color: "#FFFFFF",
									borderBottom: " 1px solid #383A41",
								}}
							>
								Tokens
							</TableCell>
							<TableCell
								sx={{
									fontWeight: "700",
									fontSize: matches ? "14px" : "26px",
									textAlign: matches ? "start" : "center",
									color: "#FFFFFF",
									borderBottom: " 1px solid #383A41",
								}}
							>
								Amount
							</TableCell>
							<TableCell
								sx={{
									color: "#fff",
									fontWeight: "bold",
									borderBottom: " 1px solid #383A41",
								}}
								align="right"
							></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow
								key={index}
								sx={{
									borderBottom: " 1px solid #383A41",
								}}
							>
								<TableCell
									component="th"
									scope="row"
									sx={{
										color: "#fff",
										fontWeight: "500",
										fontFamily: "Urbanist",
										borderBottom: " 1px solid #383A41",
										fontSize: matches ? "12px" : "17px",
									}}
								>
									<Stack direction="row" spacing={2} alignItems="center">
										<img src={thinking} alt="" width="40px" height="40px" />
										<Box
											sx={{
												fontWeight: 600,
												fontSize: matches ? "12px" : "17px",
												fontFamily: "Urbanist",
												color: "#FFFFFF",
											}}
										>
											{row.name}
											<br />
											<span
												style={{
													fontFamily: "Urbanist",
													fontStyle: "normal",
													fontWeight: 600,
													fontSize: matches ? "10px" : "15px",
													color: "#FFFFFF",
												}}
											>
												{row.f}
											</span>
										</Box>
									</Stack>
								</TableCell>
								<TableCell
									sx={{
										textAlign: matches ? "start" : "center",
										fontWeight: 600,
										fontSize: matches ? "12px" : "17px",
										fontFamily: "Urbanist",
										borderBottom: " 1px solid #383A41",
										color: "#FFFFFF",
									}}
								>
									{row.amount}
								</TableCell>
								<TableCell
									sx={{
										borderBottom: "1px solid #383A41",
										textAlign: matches ? "start" : "end",
									}}
								>
									<Button
										sx={{
											backgroundColor: "transparent",
											border: "none",
											fontFamily: "Urbanist",
											fontWeight: "700",
											fontSize: matches ? "12px" : "23px",
											color: "#FFFFFF",
											borderRadius: "3px",
										}}
									>
										View
									</Button>
								</TableCell>
							</TableRow>
						))}
						{/* {emptyRows > 0 && (
              <TableRow style={{ height: 75 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
					</TableBody>
					<TableFooter>
						<TableRow
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TablePagination
								style={{
									color: "#fff",
									fontSize: "15px",
									fontFamily: "Open Sans",
								}}
								rowsPerPageOptions={[5, 10, 15, { label: "All", value: -1 }]}
								//   colSpan={3}
								count={30}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										"aria-label": "rows per page",
									},
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
}

export default Liquidity;
