import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	LinearProgress,
	linearProgressClasses,
	Stack,
} from "@mui/material";
import { styled } from "@mui/styles";
import cardimg from "../../images/projectcard.png";

import TimerCountDown from "../Timer/timer";
import { AppContext } from "../../utils";
import { Contract } from "@ethersproject/contracts";
import tokenPresaleAbi from "../../ConnectivityAss/tokenPresaleAbi.json";
import tokenAbi from "../../ConnectivityAss/tokenAbi.json";
import { formatUnits } from "@ethersproject/units";

function Card({
	projectName,
	tokenSymbol,
	tokenName,
	endTime,
	startTime,
	liquidityPercent,
	value,
	priceOfTokenOnSales,
	presaleAddress,
	tokenAddress,
	logo,
}) {
	const { account, signer, voidAccount } = useContext(AppContext);
	const [sold, setSold] = useState(0);
	const [percentage, setPercentage] = useState(0);
	const [totalSupply, settotalSupply] = useState(0);
	const toPlainString = (num) => {
		return ("" + +num).replace(
			/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
			function (a, b, c, d, e) {
				return e < 0
					? b + "0." + Array(1 - e - c.length).join(0) + c + d
					: b + c + d + Array(e - d.length + 1).join(0);
			}
		);
	};
	const getTokenInfo = async () => {
		try {
			const PresaleContract = new Contract(
				presaleAddress,
				tokenPresaleAbi,
				signer ? signer : voidAccount
			);
			const Contracts = new Contract(
				tokenAddress,
				tokenAbi,
				signer ? signer : voidAccount
			);

			let decimal = await Contracts.decimals();
			let TSupply = await Contracts.totalSupply();
			settotalSupply(formatUnits(TSupply, decimal));
			let soldtoken = await PresaleContract.soldTokens();
			setSold(formatUnits(soldtoken, decimal));
			let per =
				(+formatUnits(soldtoken, decimal) / +formatUnits(TSupply, decimal)) *
				100;
			setPercentage(toPlainString(per));

			//amount raised
			let raisedAmount = await PresaleContract.amountRaised();
			console.log(+raisedAmount, "raisedAmount");
		} catch (error) {
			console.log("err ==> here ", error);
		}
	};
	// console.log(presaleAddress, "presaleAddress");

	useEffect(() => {
		if (tokenAddress) {
			getTokenInfo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);
	const getUnixTime = () => {
		return Math.floor(new Date().getTime() / 1000);
	};

	const currentTime = getUnixTime();
	const [date, setDate] = useState();

	const startTimer = () => {
		if (date) {
			const countDownDate = new Date(date).getTime();

			let interval = setInterval(() => {
				const now = new Date().getTime();

				const distance = countDownDate - now;

				if (distance < 0) {
					clearInterval(interval.current);
				} else {
				}
			});
		}
	};

	useEffect(() => {
		//active projects
		if (+startTime < currentTime && +endTime > currentTime) {
			const date = new Date(endTime * 1000);
			const Closedata = date.toLocaleDateString();
			setDate(Closedata);

			//upcoming projects
		} else if (+startTime > currentTime) {
			const date = new Date(startTime * 1000);
			const upcomingdata = date.toLocaleDateString();
			setDate(upcomingdata);

			//closed
		} else if (+endTime < currentTime) {
			setDate(currentTime);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endTime]);

	useEffect(() => {
		startTimer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "#232342",
		},
	}));
	return (
		<Box
			bgcolor="primary.light"
			sx={{ borderRadius: "20px !important", overflow: "hidden" }}
			mb={2}
			width="100%"
		>
			<Box width="100%" p={{ xs: 1, sm: 2 }} position="relative">
				<Box
					display="flex"
					alignItems="center"
					flexWrap="wrap"
					position="absolute"
					top={20}
					left={20}
				>
					<Box
						sx={{
							background: "linear-gradient(180deg, #D542D6 0%, #5D4EE4 100%)",
						}}
						borderRadius="10px 0px 0px 10px"
						display="flex"
						alignItems="center"
						justifyContent="center"
						fontSize="12px"
						fontWeight={700}
						fontFamily="open sans"
						color="white"
						padding="15px"
					>
						{tokenSymbol}
					</Box>
					<Box
						fontSize={{ xs: "14px", sm: "18px", md: "24px" }}
						fontWeight={700}
						borderRadius="0px 10px 10px 0px"
						fontFamily="josefin Sans"
						color="text.primary"
						padding="0px 15px"
						height="48px"
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{ background: "rgba(0, 0, 0, 0.3)" }}
					>
						{projectName}
					</Box>
				</Box>
				<img src={cardimg} alt="img" />

				<Box>
					<Stack
						mt={1}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box color="#ffffff50" fontFamily="josefin Sans">
							Token Price
						</Box>
						<Box color="#ffffff95" fontFamily="open sans" fontWeight="bold">
							1 BNB = {priceOfTokenOnSales} {tokenSymbol}
						</Box>
					</Stack>
					<Stack
						mt={1}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box color="#ffffff50" fontFamily="josefin Sans">
							Soft/Hard
						</Box>
						<Box color="#ffffff95" fontFamily="open sans" fontWeight="bold">
							50 BNB
						</Box>
					</Stack>
					<Box mt={1} color="#ffffff50">
						Progress
					</Box>
					<BorderLinearProgress
						variant="determinate"
						value={percentage}
						sx={{
							[`& .${linearProgressClasses.bar}`]: {
								borderRadius: 5,
								backgroundColor: "#D000F2",
							},
						}}
					/>
					<Stack direction="row" justifyContent="space-between">
						<Box fontFamily="open sans">{sold}</Box>
						<Box fontFamily="open sans">{totalSupply}</Box>
					</Stack>
					<Stack
						mt={1}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box color="#ffffff50" fontFamily="josefin Sans">
							Liquidity
						</Box>
						<Box color="#ffffff95" fontFamily="open sans" fontWeight="bold">
							{liquidityPercent}%
						</Box>
					</Stack>
					<Stack
						mt={1}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box color="#ffffff50" fontFamily="josefin Sans">
							Lockup Time
						</Box>
						<Box color="#ffffff95" fontFamily="open sans" fontWeight="bold">
							365 Days
						</Box>
					</Stack>
				</Box>
			</Box>
			<Stack
				direction={{ xs: "column", md: "row" }}
				fontFamily="Urbanist"
				color="text.primary"
				bgcolor="#090e27"
				padding="0px 10px"
				justifyContent={{ xs: "center", md: "space-between" }}
				alignItems="center"
			>
				<Box
					my={{ xs: 1, sm: 2 }}
					fontSize={{ xs: "16px", sm: "20px" }}
					fontFamily="open sans"
					fontWeight={600}
				>
					{+startTime < currentTime && +endTime > currentTime ? (
						<>Presele Ends in</>
					) : +startTime > currentTime ? (
						<>Presele Starts in</>
					) : +endTime < currentTime ? (
						<>Presale Status</>
					) : (
						<></>
					)}
				</Box>
				<Box
					width={{ xs: "100%", sm: "40%" }}
					fontSize={{ xs: "16px", sm: "20px" }}
					textAlign="end"
					fontWeight={400}
					fontFamily="open sans"
				>
					{+startTime < currentTime && +endTime > currentTime ? (
						<>
							<TimerCountDown time={endTime} />
						</>
					) : +startTime > currentTime ? (
						<>
							<TimerCountDown time={startTime} />
						</>
					) : +endTime < currentTime ? (
						<>Closed</>
					) : (
						<></>
					)}
				</Box>
			</Stack>
		</Box>
	);
}

export default Card;
