import React from "react";
import MainSection from "./MainSection";
import LaunchIDO from "./LaunchIDO";
import Sideimages from "./Sideimages";
import Explore from "./Explore";
import UpCommingProjects from "./UpCommingProjects";
import NewFeatures from "./NewFeatures";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import RoadmapMobile from "./RoadmapMobile";
import { Hidden } from "@mui/material";
import Blogs from "./Blogs";
import FAQs from "./FAQs";

function Home() {
	return (
		<>
			<MainSection />
			<NewFeatures />
			<UpCommingProjects />
			<Sideimages />
			<LaunchIDO />
			<Hidden mdDown>
				<Roadmap />
			</Hidden>
			<Hidden mdUp>
				<RoadmapMobile />
			</Hidden>
			<Tokenomics />
			<Explore />
			<Blogs />
			<FAQs />
		</>
	);
}

export default Home;
