import React from "react";
import {
	Box,
	Container,
	Divider,
	Grid,
	Stack,
	useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useLiquidityLockerContract } from "../../ConnectivityAss/hooks";
import { useContext } from "react";
import { useState } from "react";
import { AppContext, url } from "../../utils";
import axios from "axios";
import moment from "moment";
// import WithdrawalTimer from "../Timer";
import TextField from "../TextField";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { toast } from "react-toastify";
import Loader from "../../loading";
import { Contract } from "ethers";
import tokenABI from "../../ConnectivityAss/tokenAbi.json";
import { liquidityLocker } from "../../ConnectivityAss/environment";
import TimerCountDown from "../Timer/timer";

export default function MyLiquidityLockRecord() {
	const { id, count, indexOfLock } = useParams();
	console.log(id, count, indexOfLock, "lock");
	const matches = useMediaQuery("(max-width:750px)");

	///////////// Connectivity ////////////
	const { signer, account } = useContext(AppContext);
	const liquidityLockerContract = useLiquidityLockerContract(signer);
	const [withdrawAmount, setwithdrawAmount] = useState("");
	const [lockTimeForUpdate, setlockTimeForUpdate] = useState(
		new Date().getTime()
	);
	const [incrementAmount, setincrementAmount] = useState();
	const [amountForUpdate, setamountForUpdate] = useState("");
	const [loading, setloading] = useState(false);
	const [claimableAmount, setclaimableAmount] = useState(0);
	const [unlockDateContract, setunlockDateContract] = useState(0);
	const [liquidityDetails, setliquidityDetails] = useState({
		addedAt: "-",
		account: "-",
		lockedLiqTokens: "-",
		tokenDecimals: "-",
		tokenAddress: "-",
		tokenName: "-",
		tokenSymbol: "-",
		endTime: "-",
	});
	const init = async () => {
		setloading(true);
		try {
			const {
				data: {
					data: {
						addedAt,
						account,
						lockedLiqTokens,
						tokenDecimals,
						tokenAddress,
						tokenName,
						tokenSymbol,
						endTime,
					},
				},
			} = await axios.get(`${url}liquidityRouter/getLiquidity/${id}`);
			let object = {
				addedAt,
				account,
				lockedLiqTokens,
				tokenDecimals,
				tokenAddress,
				tokenName,
				tokenSymbol,
				endTime,
			};
			console.log(object.lockedLiqTokens, "object");
			setliquidityDetails(object);
			const lockDetail =
				await liquidityLockerContract.getUserLockForTokenAtIndex(
					account,
					tokenAddress,
					indexOfLock.toString()
				);
			setincrementAmount(`Enter amount in ${tokenSymbol}`);

			setclaimableAmount(+formatUnits(lockDetail[1].toString(), tokenDecimals));
			setunlockDateContract(lockDetail[3].toString());
			setloading(false);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (id) {
			init();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	useEffect(() => {
		console.log(
			moment.unix(unlockDateContract).format().slice(0, -9),
			"timmer"
		);
		setlockTimeForUpdate(moment.unix(unlockDateContract).format().slice(0, -9));
		setwithdrawAmount(claimableAmount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unlockDateContract]);

	const withdrawHandaler = async () => {
		let date = new Date();
		let currentdate = moment(date);
		if (!account) {
			toast.error("Please connect your wallet");
		} else if (lockTimeForUpdate > currentdate) {
			toast.error("You cannot withdraw before lock time ends");
		} else if (+withdrawAmount > liquidityDetails.lockedLiqTokens) {
			toast.error("Please enter valid amount");
		} else {
			try {
				setloading(true);
				const withdraw = await liquidityLockerContract.withdraw(
					liquidityDetails.tokenAddress,
					indexOfLock.toString(),
					count.toString(),
					parseUnits(
						withdrawAmount.toString(),
						liquidityDetails.tokenDecimals.toString()
					)
				);
				await withdraw.wait();
				toast.success("Transection Confirmed");
				init();
				window.location.reload();
			} catch (error) {
				console.log(error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	const relockHandaler = async () => {
		if (!account) {
			toast.error("Please connect your wallet");
		} else if (lockTimeForUpdate <= liquidityDetails.endTime) {
			toast.error("Please enter valid amount");
		} else {
			try {
				setloading(true);
				let time = moment(lockTimeForUpdate.toString()).format("X");
				const relock = await liquidityLockerContract.relock(
					liquidityDetails.tokenAddress,
					indexOfLock.toString(),
					count.toString(),
					time
				);
				await relock.wait();
				const reLockData = {
					endTime: time,
				};
				let { data } = await axios.put(
					url + `liquidityRouter/UpdateLiquidityData/${id}`,
					reLockData
				);
				if (data.success) {
					toast.success("Transection Confirmed");
					setloading(false);
				} else {
					toast.error(data.message);
					setloading(false);
				}
				init();
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	const updateHandaler = async () => {
		if (!account) {
			toast.error("Please connect your wallet");
		} else {
			try {
				setloading(true);
				const TokenContract = await new Contract(
					liquidityDetails.tokenAddress,
					tokenABI,
					signer
				);
				// const decimals = await TokenContract.decimals();
				const approve = await TokenContract.approve(
					liquidityLocker,
					parseUnits(
						amountForUpdate.toString(),
						liquidityDetails.tokenDecimals.toString()
					).toString()
				);
				await approve.wait();
				const relock = await liquidityLockerContract.incrementLock(
					liquidityDetails.tokenAddress,
					indexOfLock.toString(),
					count.toString(),
					parseUnits(
						amountForUpdate.toString(),
						liquidityDetails.tokenDecimals.toString()
					)
				);
				await relock.wait();
				const reLockData = {
					lockedLiqTokens: amountForUpdate,
				};
				let { data } = await axios.put(
					url + `liquidityRouter/UpdateLiquidityData/${id}`,
					reLockData
				);
				if (data.success) {
					toast.success("Transection Confirmed");
					setloading(false);
				} else {
					toast.error(data.message);
					setloading(false);
				}
				init();
			} catch (error) {
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	return (
		<Box py={10}>
			<Loader loading={loading} />
			<Container maxWidth="md">
				<Box
					my={1}
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						sx={{
							color: "#fff",
							fontWeight: "600",

							fontSize: matches ? "16px" : "20px",
						}}
						mt={3}
						textAlign="center"
					>
						Unlock in
					</Box>
					<Box my={3} px={{ md: 20, sm: 15, xs: 5 }} textAlign="center">
						{+liquidityDetails.endTime > 0 && (
							<TimerCountDown time={+unlockDateContract} label={false} />
							// ) : (
							// <WithdrawalTimer endTime={+unlockDateContract} />
						)}
					</Box>
				</Box>
				<Box
					my={2}
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						py={2}
						px={2}
						color="#fff"
						textAlign="left"
						sx={{
							color: "#fff",
							fontWeight: "600",

							fontSize: matches ? "16px" : "20px",
						}}
					>
						Token Info
					</Box>
					<Divider color="#a6cfff" />
					<Container maxWidth="lg">
						<Box py={2}>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
							>
								<Box
									sx={{
										color: "#fff",
										fontWeight: "500",

										fontSize: matches ? "13px" : "17px",
									}}
								>
									Token Address
								</Box>
								<Box
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									<a
										style={{ color: "#a6cfff" }}
										href={`https://testnet.bscscan.com/address/${liquidityDetails.tokenAddress}`}
										target="_blank"
										rel="noreferrer"
									>
										{matches
											? liquidityDetails.tokenAddress.slice(0, 5) +
											  "..." +
											  liquidityDetails.tokenAddress.slice(-4)
											: liquidityDetails.tokenAddress}
									</a>
								</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Token1 Name</Box>
								<Box> {liquidityDetails.tokenName}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Token2 Name</Box>
								<Box>{liquidityDetails.tokenSymbol}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Token Decimals</Box>

								<Box>{liquidityDetails.tokenDecimals}</Box>
							</Stack>
						</Box>
					</Container>
				</Box>
				<Box
					my={2}
					py={1}
					bgcolor="#090e27"
					border="1px solid #5A5A5A"
					borderRadius="30px"
					boxShadow="-11px 17px 28px #1D1736"
				>
					<Box
						py={2}
						px={2}
						color="#fff"
						textAlign="left"
						sx={{
							color: "#fff",
							fontWeight: "600",

							fontSize: matches ? "16px" : "20px",
						}}
					>
						Lock Info
					</Box>
					<Divider color="#a6cfff" />
					<Container maxWidth="lg">
						<Box py={2}>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Total Amount Locked</Box>
								<Box> {claimableAmount}</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Owner</Box>
								<Box
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									<a
										style={{ color: "#a6cfff" }}
										href={`https://testnet.bscscan.com/address/${liquidityDetails.account}`}
										target="_blank"
										rel="noreferrer"
									>
										{matches
											? liquidityDetails.account.slice(0, 5) +
											  "..." +
											  liquidityDetails.account.slice(-4)
											: liquidityDetails.account}
									</a>
								</Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Lock Date</Box>
								<Box> {moment(liquidityDetails.addedAt).format("LL")} </Box>
							</Stack>
							<Stack
								py={2}
								px={2}
								color="#fff"
								direction="row"
								borderBottom="1px solid #383A41"
								justifyContent="space-between"
								sx={{
									color: "#fff",
									fontWeight: "500",

									fontSize: matches ? "13px" : "17px",
								}}
							>
								<Box>Unlock Date</Box>
								<Box>{moment.unix(liquidityDetails.endTime).format("LL")}</Box>
							</Stack>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={4}>
									<Box
										mt={2}
										display="flex"
										alignItems="center"
										flexDirection="column"
									>
										<TextField
											type="text"
											placeholder="Enter Amount in BNB"
											label="Withdraw Amount"
											className="fieldText"
											value={withdrawAmount}
											onChange={(e) => setwithdrawAmount(e.target.value)}
										/>
										<Box mt={1}></Box>
										<Box
											px={2}
											py={1}
											style={{ cursor: "pointer" }}
											borderRadius="5px"
											bgcolor="#a6cfff"
											color="#11112e"
											textAlign="center"
											width="120px"
											onClick={() => withdrawHandaler()}
										>
											Withdraw
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Box
										mt={2}
										display="flex"
										alignItems="center"
										flexDirection="column"
									>
										<TextField
											type="datetime-local"
											placeholder="Enter here"
											label="Lock Until"
											className="fieldText"
											value={
												+lockTimeForUpdate === 0
													? moment.unix(liquidityDetails.endTime).format("LL")
													: lockTimeForUpdate
											}
											onChange={(e) => setlockTimeForUpdate(e.target.value)}
										/>
										<Box mt={1}></Box>
										<Box
											px={2}
											py={1}
											style={{ cursor: "pointer" }}
											borderRadius="5px"
											bgcolor="#a6cfff"
											color="#11112e"
											textAlign="center"
											width="120px"
											onClick={() => relockHandaler()}
										>
											Relock
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Box
										mt={2}
										display="flex"
										alignItems="center"
										flexDirection="column"
									>
										<TextField
											type="text"
											placeholder={incrementAmount}
											label="Increment Amount"
											className="fieldText"
											value={amountForUpdate}
											onChange={(e) => setamountForUpdate(e.target.value)}
										/>
										<Box mt={1}></Box>
										<Box
											px={2}
											py={1}
											style={{ cursor: "pointer" }}
											borderRadius="5px"
											bgcolor="#a6cfff"
											color="#11112e"
											textAlign="center"
											width="120px"
											onClick={() => updateHandaler()}
										>
											Update
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Container>
				</Box>
			</Container>
		</Box>
	);
}
