import React, { useEffect, useState } from "react";
import { Container, Box, Stack, useMediaQuery } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "@mui/lab";
import { TabContext } from "@mui/lab";
import Card from "./Card";
import axios from "axios";
import { Link } from "react-router-dom";
import { url } from "../../utils";

function Explore() {
	const check = useMediaQuery("(max-width:1180px)");
	const [value, setValue] = useState("2");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [serverData, setServerData] = useState([]);
	const [projects, setProjects] = useState([]);

	const getUnixTime = () => {
		return Math.floor(new Date().getTime() / 1000);
	};

	const currentTime = getUnixTime();

	useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await axios.get(url + "presaleRouter/projects");
				setServerData(data);
				// console.log(data, "current");
				setProjects(data);
			} catch (error) {
				console.log(error);
			}
		};
		getData();
	}, [value]);

	const ALLPROJECTS = () => {
		// setProjects(serverData);
		return serverData;
	};

	const SETACTIVEPROJECTS = () => {
		// setProjects(serverData);
		if (projects) {
			const active = serverData.filter(
				(item) => +item.startTime < currentTime && +item.endTime > currentTime
			);

			return active;
			// setProjects(active);
		} else {
		}
	};

	const SETUPCOMMINGPROJECTS = () => {
		// setProjects(serverData);
		if (projects) {
			const upcomin = serverData.filter(
				(item) => +item.startTime > currentTime
			);

			// setProjects(upcomin);
			return upcomin;
		} else {
		}
	};

	const SETUPCLOSEDPROJECTS = () => {
		// setProjects(serverData);

		if (projects) {
			const closed = projects.filter((item) => +item.endTime < currentTime);

			return closed;
		} else {
			console.log("err", "No data");
		}
	};

	return (
		<div>
			<Container maxWidth="lg">
				<Box
					pt={10}
					pb={1}
					fontSize={{ md: "48px", sm: "30px", xs: "30px" }}
					textAlign="center"
					sx={{
						fontFamily: "Urbanist",
						fontStyle: "normal",
						fontWeight: "700",
						color: "white",
					}}
				>
					Projects
				</Box>

				<Box>
					<TabContext value={value}>
						<Box>
							<Tabs
								onChange={handleChange}
								variant="scrollable"
								scrollButtons="auto"
								allowScrollButtonsMobile
								aria-label="scrollable auto tabs example"
								value={value}
								sx={{
									"& .Mui-selected": {
										color: "#fff !important",
										border: " 1.5px solid #5142FC",
										borderRadius: "6px",
										background: "#1E1E65",
										fontWeight: "700",
										fontSize: { md: "20px", sm: "14px", xs: "12px" },
									},
									"& .MuiTabs-indicator": {
										display: "none",
									},
								}}
							>
								<Tab
									indicatorColor="secondary"
									textColor="inherit"
									sx={{ fontFamily: "Urbanist", color: "#FFFF" }}
									label="All "
									value="1"
								/>
								<Tab
									indicatorColor="#FFFFF"
									textColor="inherit"
									sx={{ fontFamily: "Urbanist", color: "#FFFF" }}
									label="Live "
									value="2"
								/>

								<Tab
									indicatorColor="#FFFFF"
									textColor="inherit"
									sx={{ fontFamily: "Urbanist", color: "#FFFF" }}
									label="Upcoming "
									value="3"
								/>
								<Tab
									indicatorColor="#FFFFF"
									textColor="inherit"
									sx={{ fontFamily: "Urbanist", color: "#FFFF" }}
									label="Closed "
									value="4"
								/>
							</Tabs>
						</Box>

						<Box py="0px">
							<TabPanel value={value > 1 ? "1" : value}>
								<Stack direction="row" flexWrap="wrap">
									{ALLPROJECTS().map((item) => (
										<>
											<Box
												width={check ? "100%" : "50%"}
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<Link
													to={`projectoverview/${item._id}/${item.projectStatus}`}
													width="100%"
												>
													<Card
														projectName={item.projectName}
														tokenSymbol={item.tokenSymbol}
														tokenName={item.tokenName}
														endTime={item.endTime}
														startTime={item.startTime}
														value={value}
														status={item.projectStatus}
														priceOfTokenOnSales={item.priceOfTokenOnSales}
														presaleAddress={item.presaleAddress}
														tokenAddress={item.tokenAddress}
														logo={item.logoLink}
														liquidityPercent={item.liquidityPercent}
													/>
												</Link>
											</Box>
										</>
									))}
								</Stack>
							</TabPanel>
							<TabPanel value="2">
								<Stack direction="row" flexWrap="wrap">
									{SETACTIVEPROJECTS().map((item) => (
										<>
											<Box
												width={check ? "100%" : "50%"}
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<Link
													to={`projectoverview/${item._id}/${item.projectStatus}`}
												>
													<Card
														projectName={item.projectName}
														tokenSymbol={item.tokenSymbol}
														tokenName={item.tokenName}
														endTime={item.endTime}
														startTime={item.startTime}
														value={value}
														status={item.projectStatus}
														priceOfTokenOnSales={item.priceOfTokenOnSales}
														presaleAddress={item.presaleAddress}
														tokenAddress={item.tokenAddress}
														logo={item.logoLink}
														liquidityPercent={item.liquidityPercent}
													/>
												</Link>
											</Box>
										</>
									))}
								</Stack>
							</TabPanel>
							<TabPanel value="3">
								<Stack direction="row" flexWrap="wrap">
									{SETUPCOMMINGPROJECTS().map((item) => (
										<>
											<Box
												width={check ? "100%" : "50%"}
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<Link
													to={`projectoverview/${item._id}/${item.projectStatus}`}
												>
													<Card
														projectName={item.projectName}
														tokenSymbol={item.tokenSymbol}
														tokenName={item.tokenName}
														endTime={item.endTime}
														startTime={item.startTime}
														value={value}
														status={item.projectStatus}
														priceOfTokenOnSales={item.priceOfTokenOnSales}
														presaleAddress={item.presaleAddress}
														tokenAddress={item.tokenAddress}
														logo={item.logoLink}
														liquidityPercent={item.liquidityPercent}
													/>
												</Link>
											</Box>
										</>
									))}
								</Stack>
							</TabPanel>
							<TabPanel value="4">
								<Stack direction="row" flexWrap="wrap">
									{SETUPCLOSEDPROJECTS().map((item) => (
										<>
											<Box
												width={check ? "100%" : "50%"}
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<Link
													to={`projectoverview/${item._id}/${item.projectStatus}`}
												>
													<Card
														projectName={item.projectName}
														tokenSymbol={item.tokenSymbol}
														tokenName={item.tokenName}
														endTime={item.endTime}
														startTime={item.startTime}
														value={value}
														status={item.projectStatus}
														priceOfTokenOnSales={item.priceOfTokenOnSales}
														presaleAddress={item.presaleAddress}
														tokenAddress={item.tokenAddress}
														logo={item.logoLink}
														liquidityPercent={item.liquidityPercent}
													/>
												</Link>
											</Box>
										</>
									))}
								</Stack>
							</TabPanel>
						</Box>
					</TabContext>
				</Box>
			</Container>
		</div>
	);
}

export default Explore;
