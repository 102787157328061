import React from "react";
import ReactEcharts from "echarts-for-react";
import {
	Box,
	Container,
	Grid,
	Hidden,
	LinearProgress,
	linearProgressClasses,
	Typography,
} from "@mui/material";
import * as echarts from "echarts";
import { styled } from "@mui/styles";
import bg1 from "../../images/background91.png";
import bg2 from "../../images/background92.png";
echarts.registerTheme("my_theme", {
	backgroundColor: "#11112e",
});
function Tokenomics() {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "#232342",
		},
	}));

	const theme1 = "my_theme";

	const option = {
		tooltip: {
			trigger: "item",
		},

		series: [
			{
				radius: ["60%", "90%"],
				color: ["#6DD0F1", "#9570FF", "#4930E4", "#8342D6", "#D000F2"],
				avoidLabelOverlap: true,
				itemStyle: {
					borderRadius: 10,
					backgroundColor: "#11112e ",
					borderColor: "#11112e",
					borderWidth: 4,
				},
				type: "pie",
				data: [
					{ value: 1, name: "Air Drop 1%" },
					{ value: 2, name: "Launchpad 1.25%" },
					{ value: 6, name: "Team 6%" },
					{ value: 31, name: "Liquidity  31.75%" },
					{ value: 60, name: "Presale 60%" },
				],
			},
		],
	};
	return (
		<Box
			sx={{
				background: `url(${bg1}),url(${bg2})`,
				backgroundPosition: "80% 0%, 10% 100%",
				backgroundRepeat: "no-repeat, no-repeat",
				backgroundSize: "auto",
			}}
		>
			<Typography
				fontSize={{ xs: "30px", sm: "40px" }}
				fontWeight={700}
				fontFamily="Josefin Sans"
				color="#fff"
				textAlign="center"
				mb={10}
			>
				Tokenomics
			</Typography>
			<Container maxWidth="lg">
				<Grid container spacing={4}>
					<Grid item md={7} sm={12} xs={12}>
						<Hidden mdDown>
							<ReactEcharts
								option={option}
								theme={theme1}
								style={{ height: 390 }}
							/>
						</Hidden>
						<Hidden mdUp>
							<ReactEcharts
								option={option}
								theme={theme1}
								style={{ height: 250 }}
							/>
						</Hidden>
					</Grid>
					<Grid item md={5} sm={12} xs={12}>
						<Box py={1}>
							<Typography
								sx={{
									fontSize: "15px",
									lineHeight: "30px",
									color: "#fff",
									fontFamily: "open sans",
								}}
							>
								Air Drop1% : 50,000,000,000$
							</Typography>
							<BorderLinearProgress
								variant="determinate"
								value={1}
								sx={{
									[`& .${linearProgressClasses.bar}`]: {
										borderRadius: 5,
										backgroundColor: "#6DD0F1",
									},
								}}
							/>
						</Box>
						<Box py={1}>
							<Typography
								sx={{
									fontSize: "15px",
									lineHeight: "30px",
									color: "#fff",
									fontFamily: "open sans",
								}}
							>
								Launchpad 1.25%
							</Typography>
							<BorderLinearProgress
								variant="determinate"
								value={2}
								sx={{
									[`& .${linearProgressClasses.bar}`]: {
										borderRadius: 5,
										backgroundColor: "#9570FF",
									},
								}}
							/>
						</Box>
						<Box py={1}>
							<Typography
								sx={{
									fontSize: "15px",
									lineHeight: "30px",
									color: "#fff",
									fontFamily: "open sans",
								}}
							>
								Team 6% : 600,000,000$
							</Typography>
							<BorderLinearProgress
								variant="determinate"
								value={6}
								sx={{
									[`& .${linearProgressClasses.bar}`]: {
										borderRadius: 5,
										backgroundColor: "#4930E4",
									},
								}}
							/>
						</Box>
						<Box py={1}>
							<Typography
								sx={{
									fontSize: "15px",
									lineHeight: "30px",
									color: "#fff",
									fontFamily: "open sans",
								}}
							>
								Liquidity 31.75% : 174,500,000,000$
							</Typography>
							<BorderLinearProgress
								variant="determinate"
								value={32}
								sx={{
									[`& .${linearProgressClasses.bar}`]: {
										borderRadius: 5,
										backgroundColor: "#8342D6",
									},
								}}
							/>
						</Box>
						<Box py={1}>
							<Typography
								sx={{
									fontSize: "15px",
									lineHeight: "30px",
									color: "#fff",
									fontFamily: "open sans",
								}}
							>
								Presale 60% : 1,750,000,000,000$
							</Typography>
							<BorderLinearProgress
								variant="determinate"
								value={60}
								sx={{
									[`& .${linearProgressClasses.bar}`]: {
										borderRadius: 5,
										backgroundColor: "#D000F2",
									},
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
export default Tokenomics;
