import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext, url } from "../../utils";
import { useTokenLockerContract } from "../../ConnectivityAss/hooks";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { formatUnits } from "@ethersproject/units";
import Loader from "../../loading";
import dummyico from "../../images/dummyico.png";

export default function LockToken({ again }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const { account, signer } = useContext(AppContext);
	const tokenLockerContract = useTokenLockerContract(signer);
	const [userLockedTokensInfo, setuserLockedTokensInfo] = useState([]);
	const [tokenArray, settokenArray] = useState([]);
	const [loading, setloading] = useState(false);
	// let owner = account?.toLowerCase();

	/////////// Connectivity ////////////
	const init = async () => {
		try {
			setloading(true);
			const {
				data: { data },
			} = await axios.get(`${url}tokenRouter/getUserToken/${account}`);
			settokenArray(data);

			let tokenInfoMain = [];
			let tokenInfo = [];
			if (data.length > 0) {
				for (let i = 0; i < data.length; i++) {
					// tokenInfo = [];
					const count = await tokenLockerContract.getUserLocksForTokenLength(
						account,
						data[i].tokenAddress
					);

					for (let j = 0; j < +count; j++) {
						const lockID =
							await tokenLockerContract.getUserLockIDForTokenAtIndex(
								data[i].account,
								data[i].tokenAddress,
								j.toString()
							);
						const lockDetail = await tokenLockerContract.getLock(
							lockID.toString()
						);
						let obj = {
							lockID: lockID.toString(),
							claimableamount: formatUnits(
								lockDetail[2].toString(),
								data[i].tokenDecimals
							),
							claimedamount: formatUnits(
								lockDetail[3].toString(),
								data[i].tokenDecimals
							),
							totalamount: lockDetail[4].toString(),
							unlockDate: lockDetail[7].toString(),
							owner: account,
							tokenInfo: data[i],
						};

						if (+obj?.claimableamount - +obj?.claimedamount > 0) {
							tokenInfo.push(obj);
						}
					}
					tokenInfoMain.push(...tokenInfo);
				}
			}
			setuserLockedTokensInfo(tokenInfoMain);
			setloading(false);
		} catch (error) {
			setloading(false);

			console.log(error);
		}
	};

	console.log(userLockedTokensInfo, "user");

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, again]);
	///////////// Pagination ///////////
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userLockedTokensInfo) : 0;
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	let navigate = useNavigate();
	const routeChange = (path) => {
		navigate(path);
	};
	return (
		<>
			<Loader loading={loading} />
			<Box>
				<TableContainer>
					<Table style={{ minWidth: "300px" }}>
						<TableBody>
							{userLockedTokensInfo.length > 0 ? (
								(rowsPerPage > 0
									? userLockedTokensInfo.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
									  )
									: userLockedTokensInfo
								).map(
									(
										{ claimableamount, lockID, tokenInfo, claimedamount },
										index
									) => (
										<TableRow
											key={index}
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
											hover
											onClick={() =>
												routeChange(
													`/Dashboard/MyLocks/${tokenInfo?._id}/${lockID}`
												)
											}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Stack direction="row" spacing={2} alignItems="center">
													<img
														src={dummyico}
														alt=""
														width="50px"
														height="50px"
													/>
													<Box>
														<Typography
															sx={{
																color: "#fff",
																fontWeight: "700",
																fontSize: "15px",
																fontFamily: "josefin Sans",
															}}
														>
															{tokenInfo?.tokenName}
														</Typography>
														<Typography
															sx={{
																fontWeight: "400",
																fontSize: "15px",
																fontFamily: "open sans",
															}}
														>
															{tokenInfo?.tokenSymbol}
														</Typography>
													</Box>
												</Stack>
											</TableCell>
											<TableCell
												align="center"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Typography
													sx={{
														color: "#fff",
														fontWeight: "400",
														fontSize: "15px",
													}}
												>
													{parseFloat(claimableamount - claimedamount).toFixed(
														2
													)}
												</Typography>
											</TableCell>
										</TableRow>
									)
								)
							) : loading ? (
								loading
							) : (
								<TableRow>
									<TableCell
										align="center"
										style={{
											color: "#ffffff",
											fontSize: "14px",
											borderBottom: " 1px solid #383A41",
											fontFamily: "open sans",
										}}
										colSpan={5}
									>
										There is no Lock Tokens yet
									</TableCell>
								</TableRow>
							)}
							{emptyRows > 0 && (
								<TableRow style={{ height: 82 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
					{userLockedTokensInfo.length > 5 && (
						<TablePagination
							rowsPerPageOptions={[5, 8, 10]}
							component="div"
							count={userLockedTokensInfo.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</TableContainer>
			</Box>
		</>
	);
}
