import React, { useContext, useEffect, useState } from "react";
import Header from "./components/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import IDO from "./components/IDO/IDO";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import AllLiqiuidity from "./components/LiquidityLocker/AllLiqiuidity";
import Roadmap from "./components/Roadmap/Roadmap";
import Explore from "./components/Projects/Explore";
import Service from "./components/Service/Service";
import ProjectsSection from "./components/Projects/ProjectDetails";
import Token from "./components/TokenDeployer/Token";
import Earn from "./components/Earn/Earn";
import LockLiquidity from "./components/Service/LockLiquidity/LockLiquidity";
import TokenLocker from "./components/Service/tokenLocker/tokenLocker";
import MyLockRecord from "./components/TokenLocker/MyLockRecord";
import { AppContext } from "./utils";
import LockTokenRecord from "./components/TokenLocker/LockTokenRecord";
import LockLiquidityRecord from "./components/LiquidityLocker/LockLiqiuidityRecord";
import MyLiquidityLockRecord from "./components/LiquidityLocker/MyLiquidityLockRecord";
import Tokenomics from "./components/Tokenomics/Tokenomics";

import Web3 from "web3";
import NetworkChange from "./networkSwitch";
const web3 = new Web3(
	Web3.givenProvider || "https://data-seed-prebsc-1-s1.binance.org:8545/"
);

function App(props) {
	const { account, connect } = useContext(AppContext);
	const [switchNetwork, setswitchNetwork] = useState(false);
	let chain = async () => {
		const chainid = await web3.eth.getChainId();
		if (chainid !== 97) {
			setswitchNetwork(true);
		}
	};
	useEffect(() => {
		if (window.location.href.includes("?ref=")) {
			let getAddress = window.location.href.split("?ref=")[1];
			let final = getAddress.slice(0, 42);
			// console.log("final", final);
			localStorage.setItem("META_STRTER", final);
			// setRefrelAddress(final.toString());
		}
	}, [account]);

	useEffect(() => {
		if (!account) {
			connect();
		}
		if (account) {
			chain();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	// useEffect(() => {
	//   window.addEventListener("load", (event) => {
	//     // const myTimeout = setTimeout(myGreeting, 3000);
	//     if (!account) {
	//       connect();
	//     }
	//   });
	// });

	// const params = new URLSearchParams(window.location.search);
	// console.log(params.get("ref"));

	return (
		<>
			<NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
			<Header />
			<Routes>
				<Route
					exact
					path="/"
					element={
						<>
							<Home />
						</>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<>
							<Dashboard />
						</>
					}
				/>
				<Route path="/liquidityLock" element={<AllLiqiuidity />} />
				<Route path="/earn" element={<Earn />} />
				<Route path="/tokenomics" element={<Tokenomics />} />
				<Route path="/services" element={<Service />} />
				<Route path="/ido" element={<IDO />} />
				<Route path="/roadmap" element={<Roadmap />} />
				<Route path="/token" element={<Token />} />
				<Route
					path="/projects"
					element={
						<>
							<Explore />
						</>
					}
				/>
				<Route
					path={"/projects/projectoverview/:id/:status"}
					element={<ProjectsSection />}
				/>
				<Route
					path={"/projects/projectoverview/:id/:status/:account"}
					element={<ProjectsSection />}
				/>
				<Route path="/services/liquidity-lock" element={<LockLiquidity />} />
				<Route path="/services/tokenLocker" element={<TokenLocker />} />
				<Route
					path="/Dashboard/MyLocks/:id/:count"
					element={account ? <MyLockRecord /> : <Navigate to="/dashboard" />}
				/>
				<Route
					path="/Dashboard/MyLiqiuidityLockRecord/:id/:count/:indexOfLock"
					element={
						account ? <MyLiquidityLockRecord /> : <Navigate to="/dashboard" />
					}
				/>
				<Route
					path="/Dashboard/LockTokenRecord/:id"
					element={<LockTokenRecord />}
				/>
				<Route
					path="/Dashboard/LockLiqiuidityRecord/:id"
					element={<LockLiquidityRecord />}
				/>
			</Routes>
			<Footer />
		</>
	);
}

export default App;
