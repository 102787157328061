import { Box, Container, Grid } from "@mui/material";
import React from "react";
import chart from "./assets/chart.svg";

function Tokenomics() {
  return (
    <div style={{ paddingBottom: "50px" }}>
      <Container maxWidth="lg">
        <Box
          fontWeight="700"
          fontSize={{ md: "56px", xs: "40px" }}
          fontFamily="Urbanist"
          lineHeight="56px"
          letterSpacing="2px"
          color="#FFFFFF"
          textAlign={"center"}
          pt="150px"
        >
          Tokenomics
        </Box>
        <Grid mt={{ md: "100px", xs: "50px" }} container spacing={6}>
          <Grid item md={6} sm={12}>
            <Box textAlign="center">
              <img src={chart} alt="" />
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              fontWeight="500"
              fontSize={{ xs: "14px", md: "18px" }}
              lineHeight={{ xs: "17px", md: "26px" }}
              color="white"
              fontFamily="Urbanist"
              textAlign={{ md: "left", sm: "center" }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Tokenomics;
