import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Container, Typography, Tabs } from "@mui/material";
import Liquidity from "./Liquidity";
import SearchIcon from "@mui/icons-material/Search";
import Bg from "../../images/Bg3.png";

export default function AllLiqiuidity() {
	const [value, setValue] = useState("1");
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box
			py={10}
			sx={{
				backgroundImage: `url(${Bg})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
			}}
		>
			<Container maxWidth="md">
				<Box display="flex" justifyContent="center" py={6}>
					<Typography
						fontWeight="700"
						fontFamily="Urbanist"
						fontSize={{ xs: "45px", md: "56px" }}
						lineHeight="56px"
						letterSpacing="2px"
						color="#FFFFFF"
					>
						liquidity lock
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent={"space-around"}
					alignItems="center"
					sx={{ border: "1px solid grey", color: "#fff" }}
					mt={{ xs: 2, md: 4 }}
				>
					<input
						placeholder="Paste your address"
						style={{
							background: "transparent",
							border: "none",
							outline: "none",
							color: "#fff",
							height: "40px",
							width: "94%",
							paddingLeft: "7px",
						}}
					/>
					<SearchIcon />
				</Box>
				<Box mt={5} sx={{ width: "100%", typography: "body1" }}>
					<TabContext value={value}>
						<Box>
							<Tabs
								onChange={handleChange}
								value={value}
								aria-label="lab API tabs example"
								sx={{
									"& .Mui-selected": {
										color: "#fff !important",
									},
									"& .MuiTabs-indicator": {
										backgroundColor: "#fff",
									},
								}}
							>
								<Tab
									indicatorColor="secondary"
									textColor="inherit"
									sx={{ fontFamily: "Urbanist", color: "#FFFF" }}
									label="All "
									value="1"
								/>
								<Tab
									indicatorColor="#FFFFF"
									textColor="inherit"
									sx={{ fontFamily: "Urbanist", color: "#FFFF" }}
									label="My Lock "
									value="2"
								/>
							</Tabs>

							<TabPanel value="1">
								<Liquidity />
							</TabPanel>
							<TabPanel value="2">
								<Liquidity />
							</TabPanel>
						</Box>
						<TabPanel sx={{ padding: "30px 0px" }} value="1">
							<Liquidity />
						</TabPanel>
						<TabPanel sx={{ padding: "30px 0px" }} value="2">
							<Liquidity />
						</TabPanel>
					</TabContext>
					<Box
						sx={{
							height: "13.11px",
							background:
								"radial-gradient(50% 50% at 50% 50%, #AB50E5 0%, rgba(171, 80, 229, 0) 100%)",
							filter: " drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
							borderRadius: "8.38949px",
						}}
						mt={{ xs: 4, md: 8 }}
					></Box>
				</Box>
			</Container>
		</Box>
	);
}
