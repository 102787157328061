import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect, useContext } from "react";
import axios from "axios";
import Loader from "../../loading";
import { AppContext, url } from "../../utils";
import { useTokenLockerContract } from "../../ConnectivityAss/hooks";
import { formatUnits } from "@ethersproject/units";
import dummyico from "../../images/dummyico.png";

export default function AllLockTokens({ again }) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const [loading, setloading] = useState(false);

	/////////// Server Data ////////////
	const [tokensList, settokensList] = useState([]);
	let totalLockedAmount = 0;
	const [totalAmount, setTotalAmount] = useState([]);
	const { signer } = useContext(AppContext);
	const tokenLockerContract = useTokenLockerContract(signer);
	const initData = async () => {
		try {
			setloading(true);
			const { data } = await axios.get(`${url}tokenRouter/TokenData`);
			settokensList(data.newToken);
			setloading(false);
			if (data?.newToken?.length > 0) {
				let amountArry = [];
				let mainArry = [];
				for (let i = 0; i < data?.newToken?.length; i++) {
					totalLockedAmount = 0;
					const count = await tokenLockerContract.getUserLocksForTokenLength(
						data.newToken[i].account,
						data.newToken[i].tokenAddress
					);
					for (let j = 0; j < +count; j++) {
						let rec = data?.newToken[i];
						const lockID =
							await tokenLockerContract.getUserLockIDForTokenAtIndex(
								data.newToken[i].account,
								data.newToken[i].tokenAddress,
								j.toString()
							);
						const lockDetail = await tokenLockerContract.getLock(
							lockID.toString()
						);
						totalLockedAmount += +formatUnits(
							lockDetail[2].toString(),
							data.newToken[i].tokenDecimals.toString()
						);
						let obj = {
							...rec,
							totalLockedAmount,
						};
						mainArry.push(obj);
					}
					if (totalLockedAmount > 0) {
						// console.log(totalLockedAmount, "totalLockedAmount");
						amountArry.push(...mainArry);
					}
				}
				setTotalAmount([...amountArry]);
			}
			setloading(false);
		} catch (error) {
			setloading(false);
			console.log(error, "<==Error");
		}
	};
	// console.log(tokensList, "tokensList");
	useEffect(() => {
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [again]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalAmount.length) : 0;
	let navigate = useNavigate();
	const routeChange = (path) => {
		navigate(path);
	};
	return (
		<>
			<Loader loading={loading} />
			<Box>
				<TableContainer>
					<Table style={{ minWidth: "300px" }}>
						<TableBody>
							{console.log(totalAmount, "dfghb")}
							{totalAmount.length > 0 ? (
								(rowsPerPage > 0
									? totalAmount.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
									  )
									: totalAmount
								).map(
									(
										{ tokenName, _id, tokenSymbol, totalLockedAmount },
										index
									) => (
										<TableRow
											key={index}
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
											hover
											onClick={() =>
												routeChange(`/Dashboard/LockTokenRecord/${_id}`)
											}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Stack direction="row" spacing={2} alignItems="center">
													<img
														src={dummyico}
														alt=""
														width="50px"
														height="50px"
													/>
													<Box>
														<Typography
															sx={{
																color: "#fff",
																fontWeight: "700",
																fontSize: "15px",
																fontFamily: "josefin Sans",
															}}
														>
															{tokenName}
														</Typography>
														<Typography
															sx={{
																fontWeight: "400",
																fontSize: "15px",
																fontFamily: "open sans",
															}}
														>
															{tokenSymbol}
														</Typography>
													</Box>
												</Stack>
											</TableCell>
											<TableCell
												align="center"
												sx={{
													borderBottom: " 1px solid #383A41",
												}}
											>
												<Typography
													sx={{
														color: "#fff",
														fontWeight: "400",
														fontSize: "15px",
													}}
												>
													{totalAmount?.length > 0
														? parseFloat(totalLockedAmount).toFixed(2)
														: "Loading..."}
												</Typography>
											</TableCell>
										</TableRow>
									)
								)
							) : loading ? (
								loading
							) : (
								<TableRow>
									<TableCell
										align="center"
										style={{
											color: "#ffffff",
											fontSize: "14px",
											borderBottom: " 1px solid #383A41",
											fontFamily: "open sans",
										}}
										colSpan={5}
									>
										There is no Lock Tokens yet
									</TableCell>
								</TableRow>
							)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 82 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
					{totalAmount.length > 5 && (
						<TablePagination
							rowsPerPageOptions={[5, 8, 10]}
							component="div"
							count={totalAmount.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</TableContainer>
			</Box>
		</>
	);
}
