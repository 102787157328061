import React, { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import heart from "../../images/heart.png";
import fire from "../../images/fire.png";
import axios from "axios";
import { url } from "../../utils";
import TimerCountDown from "../Timer/timer";
import { Link } from "react-router-dom";
import Card from "../Projects/Card";
//  lsdf
const useStyles = makeStyles((theme) => ({
	slider: {
		margin: "20px",
		"& .slick-dots": {
			zIndex: -1,
			bottom: "-33px !important",
		},
		"& .slick-list": {
			"& .slick-track": {
				display: "flex",
				"& .slick-slide": {
					// margin: "0px 18px",
					marginRight: "18px",
					[theme.breakpoints.down("sm")]: {
						marginRight: "0px",
					},
				},
			},
		},
	},
}));
function Projects() {
	const classes = useStyles();
	const matches = useMediaQuery("(max-width:600px)");
	const [serverData, setServerData] = useState([]);

	const [projects, setProjects] = useState([]);
	const getUnixTime = () => {
		return Math.floor(new Date().getTime() / 1000);
	};

	const currentTime = getUnixTime();

	useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await axios.get(url + "presaleRouter/projects");
				setServerData(data);
				setProjects(data);
			} catch (error) {
				console.log(error);
			}
		};
		getData();
	}, []);

	const SETUPCOMMINGPROJECTS = () => {
		// setProjects(serverData);

		if (projects) {
			const upcomin = serverData.filter(
				(item) => +item.startTime > currentTime
			);

			return upcomin;
		} else {
			console.log("err", "No data");
		}
	};
	function SampleNextArrow(props) {
		const { className, onClick } = props;
		return (
			<ArrowForwardIcon
				style={{
					color: "#fff",
					position: "absolute",
					top: "105%",
					right: matches ? "20%" : "43%",
				}}
				className={className}
				onClick={onClick}
			/>
		);
	}

	function SamplePrevArrow(props) {
		const { className, onClick } = props;
		return (
			<div>
				<ArrowBackIcon
					style={{
						color: "#fff",
						position: "absolute",
						top: "105%",
						left: matches ? "20%" : "43%",
					}}
					className={className}
					onClick={onClick}
				/>
			</div>
		);
	}

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		initialSlide: 0,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<Box mt={3} width="100%">
			<Slider {...settings} className={classes.slider}>
				{SETUPCOMMINGPROJECTS().length > 0 ? (
					SETUPCOMMINGPROJECTS().map((item) => {
						return (
							<Link
								to={`/projects/projectoverview/${item._id}/${item.projectStatus}`}
							>
								{/* <div>
									<Box
										bgcolor="primary.light"
										borderRadius="20px"
										// height="442px"
										p={{ xs: 1, sm: 2 }}
									>
										<Box
											style={{ backgroundImage: `url(${item.logoLink})` }}
											bgcolor="#7A798A"
											borderRadius="20px"
											height={{ xs: "230px", sm: "290px" }}
											p={{ xs: 1.5, sm: 2 }}
										>
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
											>
												<Box
													padding="3px 8px"
													borderRadius="13px"
													bgcolor="#11112e"
													fontSize={{ xs: "12px", sm: "14px" }}
													fontWeight={700}
													fontFamily="Urbanist"
													color="text.primary"
												>
													Whitelisting
												</Box>
												<Box
													padding="3px 8px"
													borderRadius="10px"
													bgcolor="#11112e"
													fontSize={{ xs: "12px", sm: "14px" }}
													fontWeight={700}
													fontFamily="Urbanist"
													color="text.primary"
													display="flex"
													alignItems="center"
												>
													<img
														src={heart}
														style={{
															marginRight: "3px",
															width: matches ? "14px" : "20px",
														}}
														alt=""
													/>
													100
												</Box>
											</Box>
										</Box>
										<Box>
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												mt={3}
												flexWrap="wrap"
											>
												<Box
													fontSize={{ xs: "12px", sm: "16px" }}
													fontWeight={700}
													fontFamily="Urbanist"
													color="text.primary"
												>
													{item.projectName}
												</Box>
												<Box
													fontSize={{ xs: "10px", sm: "14px" }}
													fontWeight={700}
													fontFamily="Urbanist"
													color="text.primary"
													bgcolor="#11112e"
													borderRadius="13px"
													padding="4px 12px"
													display="flex"
													justifyContent="center"
													alignItems="center"
													width="150px"
												>
													<img
														src={fire}
														style={{ marginRight: "3px", width: "20px" }}
														alt=""
													/>

													<TimerCountDown time={+item.startTime} />
												</Box>
												<Box
													fontSize={{ xs: "10px", sm: "12px" }}
													fontWeight={700}
													fontFamily="Urbanist"
													color="text.primary"
													bgcolor="primary.main"
													borderRadius="10px"
													padding="0px 25px"
													height="24px"
													display="flex"
													justifyContent="center"
													alignItems="center"
													sx={{
														letterSpacing: "10%",
													}}
												>
													BSC
												</Box>
											</Box>

											<Box
												display="flex"
												justifyContent={{
													xs: "space-around",
													sm: "space-between",
												}}
												alignItems="center"
												flexWrap={"wrap"}
												mt={2}
											>
												<Box display="flex" alignItems="center">
													<Box
														bgcolor="#7A798A"
														width="52px"
														height="52px"
														borderRadius="20px"
													></Box>
													<Box ml={1}>
														<Box
															fontSize={{ xs: "10px", sm: "13px" }}
															fontWeight={400}
															fontFamily="Urbanist"
															color="text.tertiary"
														>
															{item.tokenName}
														</Box>
														<Box
															fontSize={{ xs: "12px", sm: "15px" }}
															fontWeight={700}
															fontFamily="Urbanist"
															color="text.secondary"
														>
															{item.tokenSymbol}
														</Box>
													</Box>
												</Box>
												<Box>
													<Box
														fontSize={{ xs: "10px", sm: "13px" }}
														fontWeight={400}
														fontFamily="Urbanist"
														color="text.tertiary"
														textAlign="center"
													>
														Total Rise
													</Box>
													<Box
														fontSize={{ xs: "12px", sm: "15px" }}
														fontWeight={700}
														fontFamily="Urbanist"
														color="text.secondary"
														textAlign="center"
													>
														100 BNB
													</Box>
												</Box>
												<Box>
													<Box
														fontSize={{ xs: "10px", sm: "13px" }}
														fontWeight={400}
														fontFamily="Urbanist"
														color="text.tertiary"
														textAlign="center"
													>
														Token Sale
													</Box>
													<Box
														fontSize={{ xs: "12px", sm: "15px" }}
														fontWeight={700}
														fontFamily="Urbanist"
														color="text.secondary"
														textAlign="center"
													>
														100 M
													</Box>
												</Box>
												<Box>
													<Box
														fontSize={{ xs: "10px", sm: "13px" }}
														fontWeight={400}
														fontFamily="Urbanist"
														color="text.tertiary"
														textAlign="center"
													>
														Token Price
													</Box>
													<Box
														fontSize={{ xs: "12px", sm: "15px" }}
														fontWeight={700}
														fontFamily="Urbanist"
														color="text.secondary"
														textAlign="center"
													>
														1 BNB = 100B
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</div> */}
								<Card
									projectName={item.projectName}
									tokenSymbol={item.tokenSymbol}
									tokenName={item.tokenName}
									endTime={item.endTime}
									startTime={item.startTime}
									// value={value}
									status={item.projectStatus}
									priceOfTokenOnSales={item.priceOfTokenOnSales}
									presaleAddress={item.presaleAddress}
									tokenAddress={item.tokenAddress}
									logo={item.logoLink}
									liquidityPercent={item.liquidityPercent}
								/>
							</Link>
						);
					})
				) : (
					<Box textAlign="center">Comming Soon</Box>
				)}
			</Slider>
		</Box>
	);
}

export default Projects;
