import React from "react";
import { Contract } from "@ethersproject/contracts";
import tokenAbi from "./tokenAbi.json";
import presaleAbi from "./preSaleAbi.json";
import stakingAbi from "./stakingAbi.json";
import tokenDeolayerAbi from "./tokenDeolayerAbi.json";
import tokenLockerAbi from "./tokenLockerAbi.json";
import liquidityLockerAbi from "./liquidityLockerAbi.json";
import deflationaryTokenAbi from "./deflationaryTokenAddress.json";
import {
  presaleAddress,
  stakingAddress,
  UsdcAddress,
  tokenDeployerAddress,
  tokenLocker,
  liquidityLocker,
  deflationaryTokenAddress,
} from "./environment";
import usdcAbi from "./usdcAbi.json";
import { ethers } from "ethers";
import { seeder } from "../utils";
let walletAddress = "0x8ba1f109551bD432803012645Ac136ddd64DBA72";

const provider = new ethers.providers.JsonRpcProvider(seeder);

export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function useTokenContract(signer, tokenAddress) {
  return useContract(tokenAddress, tokenAbi, signer);
}
export function usePresaleContract(signer) {
  return useContract(presaleAddress, presaleAbi, signer);
}
export function useStakingContract(signer) {
  return useContract(stakingAddress, stakingAbi, signer);
}
export function useTokenDeployerContract(signer) {
  return useContract(tokenDeployerAddress, tokenDeolayerAbi, signer);
}
export function useDeflationaryTokenContract(signer) {
  return useContract(deflationaryTokenAddress, deflationaryTokenAbi, signer);
}
export function useUsdcContract(signer) {
  return useContract(UsdcAddress, usdcAbi, signer);
}
export function useTokenLockerContract(signer) {
  return useContract(tokenLocker, tokenLockerAbi, signer);
}
export function useLiquidityLockerContract(signer) {
  return useContract(liquidityLocker, liquidityLockerAbi, signer);
}
