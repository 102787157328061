import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Box, Stack } from "@mui/material";
export default function TimerCountDown({ time, lable }) {
	let interval = useRef();
	// console.log(time,'time');

	const [countTime, setCountDateTime] = useState({
		time_days: 0,
		time_Hours: 0,
		time_Minusts: 0,
		time_seconds: 0,
	});

	const startTime = async (time) => {
		// let countDownDate = moment("Oct 15,2021 17:45:00").format("x");
		interval = setInterval(() => {
			var jun = moment().format("x");
			//  let a = jun.tz("Europe/Belgrade").format("x");
			const distance = +time - +jun;
			const days = Math.floor(distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);
			if (distance < 0) {
				clearInterval(interval.current);
			} else {
				setCountDateTime({
					...countTime,
					time_days: days,
					time_Hours: hours,
					time_Minusts: minuts,
					time_seconds: seconds,
				});
			}
		}, 1000);
	};
	useEffect(() => {
		if (time) {
			startTime(+time * 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [time]);
	return (
		<>
			<Stack
				width="100%"
				direction="row"
				justifyContent="space-between"
				fontSize={lable ? "25px" : "20px"}
				color={"#fff"}
			>
				<Stack alignItems="center">
					<Box
						sx={
							lable
								? {
										background: "#232342",
										width: "45px",
										height: "55px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										borderRadius: "7px",
								  }
								: {}
						}
					>
						{countTime.time_days}
					</Box>
					<Box fontSize="12px" fontWeight="400">
						Days
					</Box>
				</Stack>
				{lable ? "" : <Box>:</Box>}
				<Stack alignItems="center">
					<Box
						sx={
							lable
								? {
										background: "#232342",
										width: "45px",
										height: "55px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										borderRadius: "7px",
								  }
								: {}
						}
					>
						{countTime.time_Hours}
					</Box>
					<Box fontSize="12px" fontWeight="400">
						Hours
					</Box>
				</Stack>
				{lable ? "" : <Box>:</Box>}
				<Stack alignItems="center">
					<Box
						sx={
							lable
								? {
										background: "#232342",
										width: "45px",
										height: "55px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										borderRadius: "7px",
								  }
								: {}
						}
					>
						{countTime.time_Minusts}
					</Box>
					<Box fontSize="12px" fontWeight="400">
						Minutes
					</Box>
				</Stack>
				{lable ? "" : <Box>:</Box>}
				<Stack alignItems="center">
					<Box
						sx={
							lable
								? {
										background: "#232342",
										width: "45px",
										height: "55px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										borderRadius: "7px",
								  }
								: {}
						}
					>
						{countTime.time_seconds}
					</Box>
					<Box fontSize="12px" fontWeight="400">
						Seconds
					</Box>
				</Stack>
			</Stack>
		</>
	);
}
