import React, { useState, useEffect, useContext } from "react";
import { AppContext, url } from "../../utils";
// import tokenPresaleAbi from "../../ConnectivityAss/tokenPresaleAbi.json";
// import { formatUnits, parseUnits } from "@ethersproject/units";
// import { Contract } from "ethers";
import {
	Box,
	Stack,
	IconButton,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	TableFooter,
	TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dummyico from "../../images/dummyico.png";
import Loader from "../../loading";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageIcon sx={{ color: "#fff" }} />
				) : (
					<FirstPageIcon sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight sx={{ color: "#fff" }} />
				) : (
					<KeyboardArrowLeft sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft sx={{ color: "#fff" }} />
				) : (
					<KeyboardArrowRight sx={{ color: "#fff" }} />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageIcon sx={{ color: "#fff" }} />
				) : (
					<LastPageIcon sx={{ color: "#fff" }} />
				)}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

export default function MyLaunchedProjects({ again }) {
	// const [YourContribution, setYourContribution] = useState(0);
	const { account } = useContext(AppContext);
	const [participated, serparticipated] = useState([]);
	// const [value, setValue] = useState("2");
	const [serverData, setServerData] = useState([]);
	const [loading, setloading] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	// const getUnixTime = () => {
	// 	return Math.floor(new Date().getTime() / 1000);
	// };
	// const currentTime = getUnixTime();

	useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await axios.get(url + "presaleRouter/projects");
				setServerData(data);
			} catch (error) {
				console.log(error);
			}
		};
		getData();
	}, []);

	useEffect(() => {
		if (Object.keys(serverData).length > 0) {
			init();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, serverData, again]);

	const init = async () => {
		try {
			let myLaunch =
				serverData.length > 0 &&
				serverData.filter((item) => item.userWalletAddress === account);
			serparticipated(myLaunch);
			setloading(false);
		} catch (error) {
			setloading(false);
			console.log(error);
		}
	};
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - serverData.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	let navigate = useNavigate();
	const routeChange = (path) => {
		navigate(path);
	};
	return (
		<Box>
			<Loader loading={loading} />
			<TableContainer>
				<Table style={{ minWidth: "300px" }}>
					<TableBody>
						{participated.length > 0 ? (
							(rowsPerPage > 0
								? participated.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: participated
							).map((item, index) => (
								<TableRow
									key={index}
									sx={{
										borderBottom: " 1px solid #383A41",
									}}
									hover
									onClick={() =>
										routeChange(
											`/projects/projectoverview/${item._id}/${item.projectStatus}`
										)
									}
								>
									<TableCell
										component="th"
										scope="row"
										sx={{
											borderBottom: " 1px solid #383A41",
										}}
									>
										<Stack direction="row" spacing={2} alignItems="center">
											<img src={dummyico} alt="" width="50px" height="50px" />
											<Box>
												<Typography
													sx={{
														color: "#fff",
														fontWeight: "700",
														fontSize: "15px",
														fontFamily: "josefin Sans",
													}}
												>
													{item.projectName}
												</Typography>
												<Typography
													sx={{
														fontWeight: "400",
														fontSize: "15px",
														fontFamily: "open sans",
													}}
												>
													{item.tokenSymbol}
												</Typography>
											</Box>
										</Stack>
									</TableCell>
									<TableCell
										align="center"
										sx={{
											borderBottom: " 1px solid #383A41",
										}}
									>
										<Typography
											sx={{
												color: "#fff",
												fontWeight: "400",
												fontSize: "15px",
												fontFamily: "open sans",
											}}
										>
											{item.priceOfTokenOnSales}
										</Typography>
									</TableCell>
								</TableRow>
							))
						) : loading ? (
							loading
						) : (
							<TableRow>
								<TableCell
									align="center"
									style={{
										color: "#ffffff",
										fontSize: "14px",
										fontFamily: "open sans",

										borderBottom: " 1px solid #383A41",
									}}
									colSpan={5}
								>
									Participated Projects will be displayed here
								</TableCell>
							</TableRow>
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 75 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
					{
						(participated.length = 0 ? (
							<TableFooter>
								<TableRow
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TablePagination
										style={{
											color: "#fff",
											fontSize: "15px",
										}}
										rowsPerPageOptions={[
											5,
											10,
											15,
											{ label: "All", value: -1 },
										]}
										//   colSpan={3}
										count={serverData.length}
										rowsPerPage={rowsPerPage}
										page={page}
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</TableRow>
							</TableFooter>
						) : (
							""
						))
					}
				</Table>
			</TableContainer>
		</Box>

		// <div>
		//   <Container>
		//     <Box py="20px">
		//       <Stack direction="row" flexWrap="wrap">
		//         {participated.length > 0 ? (
		//           participated.map((item, index) => {
		//             return (
		//               <Box
		//                 id="2"
		//                 key={index}
		//                 width={check ? "100%" : "50%"}
		//                 sx={{
		//                   display: "flex",
		//                   alignItems: "center",
		//                   justifyContent: "center",
		//                 }}
		//               >
		//                 <Link
		//                   to={`/projects/projectoverview/${item._id}/${item.projectStatus}`}
		//                 >
		//                   <Card
		//                     projectName={item.projectName}
		//                     tokenSymbol={item.tokenSymbol}
		//                     tokenName={item.tokenName}
		//                     endTime={item.endTime}
		//                     startTime={item.startTime}
		//                     status={item.projectStatus}
		//                     priceOfTokenOnSales={item.priceOfTokenOnSales}
		//                     presaleAddress={item.presaleAddress}
		//                     tokenAddress={item.tokenAddress}
		//                     logo={item.logoLink}
		//                   />
		//                 </Link>
		//               </Box>
		//             );
		//           })
		//         ) : (
		//           <p>No Record found</p>
		//         )}
		//       </Stack>
		//     </Box>{" "}
		//   </Container>
		// </div>
	);
}
