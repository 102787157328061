import {
	Box,
	Button,
	Container,
	Divider,
	Grid,
	IconButton,
	InputBase,
	LinearProgress,
	linearProgressClasses,
	Stack,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SouthIcon from "@mui/icons-material/South";
import TimerCountDown from "../Timer/timer";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Bg from "../../images/Bg3.png";
import telegram from "../../images/telegramico.png";
import fb from "../../images/fbico.png";
import icon from "../../images/icon.png";
import bnbico from "../../images/bnbico.png";
import likeico from "../../images/likeico.png";
import cover from "../../images/detailsimg.png";
import web from "../../images/webico.png";
import EditProjectModal from "./EditProjectModel";
import project from "../../images/projectLogo.png";
import chainico from "../../images/chainico.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AppContext, url, gasEstimationForAll } from "../../utils";
import VerifiedIcon from "@mui/icons-material/Verified";
import CopyToClipboard from "react-copy-to-clipboard";
import EditIcon from "@mui/icons-material/Edit";
import { Contract } from "ethers";
import tokenAbi from "../../ConnectivityAss/tokenAbi.json";
import tokenPresaleAbi from "../../ConnectivityAss/tokenPresaleAbi.json";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { toast } from "react-toastify";
import { styled } from "@mui/styles";
import Loading from "../../loading";
import { admin } from "../../ConnectivityAss/environment";

const ProjectDetails = () => {
	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: "#232342",
		},
	}));
	const getUnixTime = () => {
		return Math.floor(new Date().getTime() / 1000);
	};
	const currentTime = getUnixTime();
	const [open, setOpen] = React.useState(false);
	const [saleData, setSaleData] = useState({});
	console.log(saleData);
	const [saleDataEdit, setSaleDataEdit] = useState({});
	const [tokenName, settokenName] = useState("");
	const [sold, setSold] = useState(0);
	const [percentage, setPercentage] = useState(0);
	// const [presaleContract, setPresaleContract] = useState("");
	const [amountRaised, setamountRaised] = useState(0);
	const [totalSupply, settotalSupply] = useState(0);
	const [liquiditylockduration, setliquiditylockduration] = useState("");
	// const [softCap, setSoftCap] = useState(0);
	// const [balance, setbalance] = useState(0);
	// const [canClaim, setCanClaim] = useState();
	// const [tokenBalance, setTokenBalance] = useState(0);
	const [tokenOwner, settokenOwner] = useState("");
	const [finalizeLabel, setFinalizeLabel] = useState();
	const [decimals, setDecimals] = useState("");
	const [Supply, setSupply] = useState("");
	const [YourContribution, setYourContribution] = useState("0");
	const [userTokenBalance, setuserTokenBalance] = useState("0");
	const [maxContribution, setMaxContribution] = useState("");
	const [loading, setloading] = useState(false);
	const [amount, setAmount] = useState("");
	const [bnbToToken, setBnbToToken] = useState("");
	const [reffrelAddress, setRefrelAddress] = useState("");
	const [presaleContract, setPresaleContract] = useState("");
	// const [puchased, setpurchased] = useState(0);

	const { account, signer, voidAccount } = useContext(AppContext);

	const prams = useParams();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		const getData = async () => {
			const { data } = await axios.get(
				url + `presaleRouter/projectoverview/${prams.id}`
			);
			setSaleData(data);
		};

		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toPlainString = (num) => {
		return ("" + +num).replace(
			/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
			function (a, b, c, d, e) {
				return e < 0
					? b + "0." + Array(1 - e - c.length).join(0) + c + d
					: b + c + d + Array(e - d.length + 1).join(0);
			}
		);
	};

	const init = async () => {
		try {
			const Contracts = new Contract(
				saleData?.tokenAddress,
				tokenAbi,
				signer ? signer : voidAccount
			);

			const PresaleContract = new Contract(
				saleData.presaleAddress,
				tokenPresaleAbi,
				signer ? signer : voidAccount
			);

			// setPresaleContract(PresaleContract);
			let decimal = await Contracts.decimals();
			let supply = await Contracts.totalSupply();
			supply = formatUnits(supply.toString(), decimal.toString()).toString();
			setSupply(supply);
			setDecimals(decimal);
			if (PresaleContract) {
				let tokenOwner = await PresaleContract.tokenOwner();
				settokenOwner(tokenOwner);
				const label = await PresaleContract.canClaim();

				setFinalizeLabel(label);
				const name = await Contracts.name();
				settokenName(name);
			}
			// contribution
			let bnbBalance = await PresaleContract.bnbBalance(account);

			setYourContribution(+formatUnits(bnbBalance.toString()));

			//user token

			let usertoken = await PresaleContract.userTokenBalance(account);
			setuserTokenBalance(
				+formatUnits(usertoken.toString(), saleData.tokenDecimel.toString())
			);

			// max
			setMaxContribution(saleData.maxLimit);
			// let raisedAmount = await PresaleContract.amountRaised();
			// let softcap = await PresaleContract.softCap();
			// const label = await PresaleContract.canClaim();
			// setamountRaised(formatUnits(raisedAmount));
			// setSoftCap(formatUnits(softcap));
			// setCanClaim(label);
			// if (account) {
			// let bnbBalance = await PresaleContract.bnbBalance(account);
			// const tokenBal = await PresaleContract.tokenBalance(account);
			// setbalance(formatUnits(bnbBalance.toString()).toString());
			// setTokenBalance(formatUnits(tokenBal.toString()).toString());
			// }
			let TSupply = await Contracts.totalSupply();
			let soldtoken = await PresaleContract.soldTokens();
			setSold(formatUnits(soldtoken, decimal));
			let per =
				(+formatUnits(soldtoken, decimal) / +formatUnits(TSupply, decimal)) *
				100;
			setPercentage(toPlainString(per));
		} catch (error) {}
	};
	console.log(percentage, "percentage");
	const getTokenInfo = async () => {
		try {
			const Contracts = new Contract(
				saleData.tokenAddress,
				tokenAbi,
				signer ? signer : voidAccount
			);

			const PresaleContract = new Contract(
				saleData.presaleAddress,
				tokenPresaleAbi,
				signer ? signer : voidAccount
			);
			console.log(PresaleContract, "PresaleContract");
			setPresaleContract(PresaleContract);

			let decimal = await Contracts.decimals();

			let TSupply = await Contracts.totalSupply();

			settotalSupply(formatUnits(TSupply, decimal));

			let _liquiditylockduration =
				await PresaleContract.liquiditylockduration();
			setliquiditylockduration(_liquiditylockduration.toString());

			let soldtoken = await PresaleContract.soldTokens();

			setSold(formatUnits(soldtoken, decimal));

			//amount raised

			let raisedAmount = await PresaleContract.amountRaised();
			// let softcap = await PresaleContract.softCap();
			// let bnbBalance = await PresaleContract.bnbBalance(account);
			setamountRaised(formatUnits(raisedAmount));
			// setSoftCap(formatUnits(softcap));
			// setbalance(formatUnits(bnbBalance.toString()).toString());

			let per =
				(+formatUnits(soldtoken, decimal) / +formatUnits(TSupply, decimal)) *
				100;

			setPercentage(toPlainString(per));
			// let userToken = await PresaleContract.userTokenBalance(account);

			// let blance = await Contracts.balanceOf(account);
			// setpurchased(formatUnits(userToken, decimal));
		} catch (error) {
			console.log("err here", error);
		}
	};

	const claimHandler = async () => {
		if (!account) {
			toast.error("Connect your wallet");
		} else {
			try {
				setloading(true);
				let fn = presaleContract.estimateGas.claim;
				let data = [];
				let claim = await presaleContract.claim({
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await claim.wait();
				toast.success("Token Claimed");
				setloading(false);
				window.location.reload();
			} catch (error) {
				console.log(error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	useEffect(() => {
		if (Object?.keys(saleData).length > 0) {
			init();
		}
		getTokenInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saleData, account]);

	const buyHandler = async () => {
		if (!account) {
			toast.error("Please Connect Wallet First");
		} else if (!saleData.presaleAddress) {
			toast.error("Server Data not fetched");
		} else if (amount.toString() === "") {
			toast.error("Please Enter Amount");
		} else if (+amount === 0) {
			toast.error("Amount should be greater then zero");
		} else {
			try {
				setloading(true);
				// const PresaleContract = new Contract(
				//   saleData.presaleAddress,
				//   tokenPresaleAbi,
				//   signer ? signer : voidAccount
				// );

				// let data = [parseUnits(amount.toString())];
				// let fn = PresaleContract.estimateGas.buyToken;
				// const response = await PresaleContract.buyToken(...data, {
				//   gasLimit: gasEstimationPayable(
				//     account,
				//     fn,
				//     data,
				//     parseUnits(amount.toString())

				//   ),
				// });

				let ref = reffrelAddress ? reffrelAddress : admin;
				let buy = await presaleContract.buyToken(ref, {
					value: parseUnits(amount).toString(),
				});
				await buy.wait();
				setloading(false);
				toast.success("Transaction Confirmed");
				getTokenInfo();

				setloading(false);
			} catch (error) {
				console.log("err", error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};

	useEffect(() => {
		(async () => {
			if (amount) {
				let buy = await presaleContract.bnbToToken(
					parseUnits(amount.toString()).toString()
				);

				setBnbToToken(
					formatUnits(buy.toString(), decimals.toString()).toString()
				);
			}
		})();
	}, [amount]);

	const unlockLpHandler = async () => {
		if (!account) {
			toast.error("Connect your wallet");
		} else {
			try {
				setloading(true);
				let fn = presaleContract.estimateGas.unlocklptokens;
				let data = [];
				let claim = await presaleContract.unlocklptokens({
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await claim.wait();
				toast.success("Token Claimed");
				setloading(false);
			} catch (error) {
				console.log(error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	const presaleHandler = async () => {
		if (!account) {
			toast.error("Connect your wallet");
		} else {
			try {
				setloading(true);
				let fn = presaleContract.estimateGas.withdrawAndInitializePool;
				let data = [];
				let claim = await presaleContract.withdrawAndInitializePool({
					gasLimit: gasEstimationForAll(account, fn, data),
				});
				await claim.wait();
				toast.success("Token Claimed");
				setloading(false);
				window.location.reload();
			} catch (error) {
				console.log(error);
				if (error?.data?.message) {
					toast.error(error?.data?.message);
				} else if (error?.reason) {
					toast.error(error?.reason);
				} else {
					toast.error(error?.message);
				}
				setloading(false);
			}
		}
	};
	return (
		<>
			<Loading loading={loading} />
			<EditProjectModal
				handleOpen={handleOpen}
				open={open}
				handleClose={handleClose}
				saleDataEdit={saleDataEdit}
			/>
			<Box
				sx={{
					backgroundImage: `url(${Bg})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
				}}
				pt={12}
				pb={{ xs: 2, md: 10 }}
			>
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						<Grid item md={8} sm={12} xs={12}>
							<Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										width: "100%",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											width: "100%",
										}}
									>
										<img
											src={
												saleData
													? saleData.logoLink === "https://test.com"
														? project
														: saleData.logoLink
													: project
											}
											alt="token logo"
											width="70px"
										/>
										<Typography
											fontSize={{ xs: "18px", sm: "20px" }}
											fontFamily="Urbanist"
											fontWeight={500}
											letterSpacing="2%"
											color="#fff"
											pl={3}
											pr={1}
										>
											Social
										</Typography>
										<Box
											width="30%"
											display="flex"
											justifyContent="space-evenly"
										>
											<a
												target="_blank"
												href={saleData.webLink}
												rel="noreferrer"
											>
												<img src={web} alt="social icon" width="25px" />
											</a>
											<a target="_blank" href="google.com" rel="noreferrer">
												<img src={fb} alt="social icon" width="25px" />
											</a>
											<a
												href={saleData ? saleData?.telegramLink : "#telegram"}
												target="_blank"
												rel="noreferrer"
												style={{
													textDecoration: "none",
												}}
											>
												<img src={telegram} alt="social icon" width="25px" />
											</a>
										</Box>
									</Box>
									<Box>
										{saleData?.kyc && (
											<VerifiedIcon style={{ color: "green" }} />
										)}

										{account === saleData.userWalletAddress && (
											<Box
												pl={1}
												onClick={() => {
													handleOpen();
													setSaleDataEdit(saleData);
												}}
											>
												<EditIcon />
											</Box>
										)}
									</Box>
								</Box>
								<Box
									pt={1}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										width: "100%",
									}}
								>
									<Typography
										sx={{
											fontWeight: "700",
											fontSize: { md: "40px", sm: "30px" },
											fontFamily: "Josefin Sans",
										}}
										color="text.primary"
									>
										{saleData ? saleData?.projectName : ""}
									</Typography>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "15px",
										}}
									>
										<img
											src={likeico}
											width="25px"
											height="25px"
											alt="likeico"
										/>
										<Button
											sx={{
												height: "37px",
												borderRadius: "32px",
												width: "120px",
												border: "1px transparent",
												backgroundImage:
													"linear-gradient(#11112e, #11112e), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
												backgroundOrigin: "border-box",
												backgroundClip: "content-box, border-box",
												padding: "1px",
												fontSize: "14px",
												letterSpacing: "2%",
												fontWeight: 500,
												fontFamily: "Poppins",
												color: "#fff",
												textTransform: "capitalize",
											}}
										>
											Canceled
										</Button>
									</Box>
								</Box>
								<Box py={3}>
									<img src={cover} width="100%" alt="cover" />
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Token Name
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{tokenName}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Token Symbol
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{saleData.tokenSymbol}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Token Decimals
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{decimals}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Token Address
									</Typography>
									<Box sx={{ display: "flex" }}>
										<Typography
											sx={{
												fontWeight: 400,
												fontSize: "15px",
											}}
											pr={1}
										>
											{saleData?.tokenAddress
												? saleData?.tokenAddress.slice(0, 5) +
												  "..." +
												  saleData?.tokenAddress.slice(-5)
												: ""}
										</Typography>
										<Box style={{ cursor: "pointer" }}>
											<CopyToClipboard
												text={saleData?.tokenAddress}
												onCopy={() => {
													if (account) {
														toast.success("Token Address Copied");
													} else {
														toast.error("Please Connect Wallet");
													}
												}}
											>
												<ContentCopyIcon />
											</CopyToClipboard>
										</Box>
									</Box>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Presale Address
									</Typography>
									<Box sx={{ display: "flex" }}>
										<Typography
											sx={{
												fontWeight: 400,
												fontSize: "15px",
											}}
											pr={1}
										>
											{saleData?.presaleAddress
												? saleData?.presaleAddress.slice(0, 5) +
												  "..." +
												  saleData?.presaleAddress.slice(-5)
												: ""}
										</Typography>
										<Box style={{ cursor: "pointer" }}>
											<CopyToClipboard
												text={saleData?.presaleAddress}
												onCopy={() => {
													if (account) {
														toast.success("Presale Address Copied");
													} else {
														toast.error("Please Connect Wallet");
													}
												}}
											>
												<ContentCopyIcon />
											</CopyToClipboard>
										</Box>
									</Box>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Total Supply
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{Supply}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Tokens For Presale
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										63,000,000 FCC
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Tokens For Liquidity
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										31,920,000 FCC
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Presale Rate
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{saleData.priceOfTokenOnSales
											? saleData.priceOfTokenOnSales
											: 0}{" "}
										{saleData.tokenSymbol}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Listing Rate
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										1 BNB = 200,000 FCC
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Hard Cap
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{saleData?.hardCap}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Soft Cap
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{saleData?.softCap}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Listing On
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										Pancakeswap
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Liquidity Percent
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										{saleData?.liquidityPercent}
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
									py={2}
								>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										Liquidity Lockup Time
									</Typography>
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: "15px",
										}}
									>
										100 days after pool ends
									</Typography>
								</Box>
								<Divider sx={{ background: "#5C6785" }} />
							</Box>
						</Grid>
						<Grid item md={4} sm={12} xs={12}>
							<Grid container spacing={5}>
								<Grid item md={12} sm={6} xs={12}>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											backgroundClip:
												"content-box, border-box, border-box, border-box, border-box",
											borderRadius: "15px",
											boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
											border: "1px solid transparent",
											backgroundImage:
												"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
											backgroundOrigin:
												"content-box, border-box, border-box, border-box, border-box",
											backgroundRepeat: "no-repeat",
											backgroundSize:
												"100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
											backgroundPosition:
												"top left, top left, top right, bottom left, bottom right",
										}}
									>
										<Box px={3} py={3}>
											{+saleData.startTime < currentTime &&
											+saleData.endTime > currentTime ? (
												<Typography
													sx={{
														fontWeight: "700",
														fontSize: "15px",
														color: "#fff",
													}}
												>
													Presele Ends in
												</Typography>
											) : +saleData.startTime > currentTime ? (
												<Typography
													sx={{
														fontWeight: "700",
														fontSize: "15px",
														color: "#fff",
													}}
												>
													Presele Starts in
												</Typography>
											) : +saleData.endTime < currentTime ? (
												<Typography
													sx={{
														fontWeight: "700",
														fontSize: "15px",
														color: "#fff",
													}}
												>
													Presele Status
												</Typography>
											) : (
												<></>
											)}
											<Box width="75%" mx="auto" pt={1}>
												{+saleData.startTime < currentTime &&
												+saleData.endTime > currentTime ? (
													<>
														<TimerCountDown
															time={saleData.endTime}
															lable={true}
														/>
													</>
												) : +saleData.startTime > currentTime ? (
													<>
														<TimerCountDown
															time={saleData.startTime}
															lable={true}
														/>
													</>
												) : +saleData.endTime < currentTime ? (
													<>Closed</>
												) : (
													<></>
												)}
											</Box>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
												pt={2}
											>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
													pb={1}
												>
													Progress
												</Typography>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
												>
													{percentage
														? +percentage < 0.0001
															? 0
															: (+percentage).toFixed(3)
														: 0}
													%
												</Typography>
											</Box>
											<BorderLinearProgress
												variant="determinate"
												value={+percentage}
												sx={{
													[`& .${linearProgressClasses.bar}`]: {
														borderRadius: 5,
														backgroundColor: "#8342D6",
													},
												}}
											/>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
												pt={2}
											>
												<Typography fontWeight="400" fontSize="14px">
													{sold ? sold : 0}
												</Typography>
												<Typography fontWeight="400" fontSize="14px">
													{Supply}
												</Typography>
											</Box>
										</Box>
									</Box>
								</Grid>
								<Grid item md={12} sm={6} xs={12}>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											backgroundClip:
												"content-box, border-box, border-box, border-box, border-box",
											borderRadius: "15px",
											boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
											border: "1px solid transparent",
											backgroundImage:
												"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
											backgroundOrigin:
												"content-box, border-box, border-box, border-box, border-box",
											backgroundRepeat: "no-repeat",
											backgroundSize:
												"100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
											backgroundPosition:
												"top left, top left, top right, bottom left, bottom right",
										}}
									>
										<Box px={3} py={3}>
											<Stack
												width="100%"
												mb="20px"
												direction={"row"}
												alignItems="center"
												justifyContent={"center"}
												bgcolor="#090e27"
												borderRadius="15px"
												boxShadow="0 0 10px 2px #00002025"
												p={1}
											>
												<Box width="70%" pl={2}>
													<Typography
														sx={{
															fontWeight: "400",
															fontSize: "15px",
															color: "#fff",
														}}
													>
														From
													</Typography>
													<InputBase
														sx={{ width: "100%" }}
														placeholder="0.0"
														onChange={(e) => setAmount(e.target.value)}
													/>
												</Box>
												<Box
													width="30%"
													display="flex"
													alignItems="center"
													justifyContent="center"
												>
													Max &nbsp;{" "}
													<img src={bnbico} width="30px" alt="icon" />
												</Box>
											</Stack>
											<Box width="100%" textAlign="center">
												<SouthIcon sx={{ color: "#fff" }} mx="auto" />
											</Box>
											<Stack
												width="100%"
												mt="10px"
												direction={"row"}
												alignItems="center"
												justifyContent={"center"}
												bgcolor="#090e27"
												borderRadius="15px"
												boxShadow="0 0 10px 2px #00002025"
												p={1}
											>
												<Box width="70%" pl={2}>
													<Typography
														sx={{
															fontWeight: "400",
															fontSize: "15px",
															color: "#fff",
														}}
													>
														To
													</Typography>
													<InputBase
														sx={{ width: "100%" }}
														placeholder="0.0"
														value={bnbToToken}
														readOnly
													/>
												</Box>
												<Box
													width="30%"
													display="flex"
													alignItems="center"
													justifyContent="center"
												>
													<img src={icon} width="30px" alt="icon" />
												</Box>
											</Stack>
											{account ? (
												<Button
													sx={{
														height: "40px",
														borderRadius: "32px",
														width: "100%",
														border: "1px transparent",
														backgroundImage:
															"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
														backgroundOrigin: "border-box",
														backgroundClip: "content-box, border-box",
														padding: "1px",
														fontSize: "14px",
														letterSpacing: "2%",
														fontWeight: 500,
														fontFamily: "Poppins",
														color: "#fff",
														marginTop: "30px",
														"&:hover": {
															backgroundImage:
																" linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
														},
													}}
													onClick={() => buyHandler()}
												>
													Buy
												</Button>
											) : (
												<Button
													sx={{
														height: "40px",
														borderRadius: "32px",
														width: "100%",
														border: "1px transparent",
														backgroundImage:
															"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
														backgroundOrigin: "border-box",
														backgroundClip: "content-box, border-box",
														padding: "1px",
														fontSize: "14px",
														letterSpacing: "2%",
														fontWeight: 500,
														fontFamily: "Poppins",
														color: "#fff",
														marginTop: "30px",
													}}
												>
													Connect Wallet
												</Button>
											)}
										</Box>
									</Box>
								</Grid>
								<Grid item md={12} sm={6} xs={12}>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											backgroundClip:
												"content-box, border-box, border-box, border-box, border-box",
											borderRadius: "15px",
											boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
											border: "1px solid transparent",
											backgroundImage:
												"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
											backgroundOrigin:
												"content-box, border-box, border-box, border-box, border-box",
											backgroundRepeat: "no-repeat",
											backgroundSize:
												"100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
											backgroundPosition:
												"top left, top left, top right, bottom left, bottom right",
										}}
									>
										<Box px={3} py={3}>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Typography
													sx={{
														fontWeight: 700,
														fontSize: "15px",
													}}
													pb={1}
												>
													Max Contribution
												</Typography>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
												>
													{maxContribution ? maxContribution : 0}
												</Typography>
											</Box>
											<Divider sx={{ background: "#5C6785" }} />
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
												pt={1}
											>
												<Typography
													sx={{
														fontWeight: 700,
														fontSize: "15px",
													}}
													pb={1}
												>
													Your Contribution
												</Typography>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
												>
													{YourContribution ? YourContribution : 0}
												</Typography>
											</Box>
											<Divider sx={{ background: "#5C6785" }} />
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
												pt={1}
											>
												<Typography
													sx={{
														fontWeight: 700,
														fontSize: "15px",
													}}
													pb={1}
												>
													Remaining Contribution
												</Typography>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
												>
													{maxContribution && YourContribution
														? +maxContribution - +YourContribution
														: 0}
												</Typography>
											</Box>
											<Divider sx={{ background: "#5C6785" }} />
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
												pt={1}
											>
												<Typography
													sx={{
														fontWeight: 700,
														fontSize: "15px",
													}}
													pb={1}
												>
													Claimable Token
												</Typography>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
												>
													{userTokenBalance ? userTokenBalance : 0}
												</Typography>
											</Box>
											<Divider sx={{ background: "#5C6785" }} />
											<Button
												sx={{
													height: "40px",
													borderRadius: "32px",
													width: "100%",
													border: "1px transparent",
													backgroundImage:
														"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
													backgroundOrigin: "border-box",
													backgroundClip: "content-box, border-box",
													padding: "1px",
													fontSize: "14px",
													letterSpacing: "2%",
													fontWeight: 500,
													fontFamily: "Poppins",
													color: "#fff",
													marginTop: "30px",
													"&:hover": {
														backgroundImage:
															" linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
													},
												}}
												onClick={claimHandler}
											>
												Claim Now
											</Button>
										</Box>
									</Box>
								</Grid>
								{tokenOwner === account && (
									<Grid item md={12} sm={6} xs={12}>
										<Box
											sx={{
												width: "100%",
												height: "100%",
												backgroundClip:
													"content-box, border-box, border-box, border-box, border-box",
												borderRadius: "15px",
												boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
												border: "1px solid transparent",
												backgroundImage:
													"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
												backgroundOrigin:
													"content-box, border-box, border-box, border-box, border-box",
												backgroundRepeat: "no-repeat",
												backgroundSize:
													"100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
												backgroundPosition:
													"top left, top left, top right, bottom left, bottom right",
											}}
										>
											<Box px={3} py={3}>
												{tokenOwner === account && finalizeLabel === false && (
													<Button
														onClick={presaleHandler}
														sx={{
															height: "40px",
															borderRadius: "32px",
															width: "100%",
															border: "1px transparent",
															backgroundImage:
																"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
															backgroundOrigin: "border-box",
															backgroundClip: "content-box, border-box",
															padding: "1px",
															fontSize: "14px",
															letterSpacing: "2%",
															fontWeight: 500,
															fontFamily: "Poppins",
															color: "#fff",
															"&:hover": {
																backgroundImage:
																	" linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
															},
														}}
													>
														Finalize Presale
													</Button>
												)}
												{tokenOwner === account && (
													<Button
														onClick={unlockLpHandler}
														sx={{
															height: "40px",
															borderRadius: "32px",
															width: "100%",
															border: "1px transparent",
															backgroundImage:
																"linear-gradient(#090E27, #090E27), linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
															backgroundOrigin: "border-box",
															backgroundClip: "content-box, border-box",
															padding: "1px",
															fontSize: "14px",
															letterSpacing: "2%",
															fontWeight: 500,
															fontFamily: "Poppins",
															color: "#fff",
															marginTop: "30px",
															"&:hover": {
																backgroundImage:
																	" linear-gradient(92.51deg, #D542D6 3.3%, #8454EB 93.12%)",
															},
														}}
													>
														Unlock LP Tokens
													</Button>
												)}
											</Box>
										</Box>
									</Grid>
								)}
								<Grid item md={12} sm={6} xs={12}>
									<Box
										sx={{
											width: "100%",
											height: "100%",
											backgroundClip:
												"content-box, border-box, border-box, border-box, border-box",
											borderRadius: "15px",
											boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
											border: "1px solid transparent",
											backgroundImage:
												"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
											backgroundOrigin:
												"content-box, border-box, border-box, border-box, border-box",
											backgroundRepeat: "no-repeat",
											backgroundSize:
												"100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
											backgroundPosition:
												"top left, top left, top right, bottom left, bottom right",
										}}
									>
										<Box px={3} py={3}>
											<Typography
												sx={{
													fontWeight: "700",
													fontSize: "15px",
													color: "#fff",
												}}
											>
												Earn with Referrel
											</Typography>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
												pt={1}
											>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
													pb={1}
												>
													Token Earned
												</Typography>
												<Typography
													sx={{
														fontWeight: 400,
														fontSize: "15px",
													}}
												>
													0
												</Typography>
											</Box>
											<Divider sx={{ background: "#5C6785" }} />
											<Typography
												sx={{
													fontWeight: 400,
													fontSize: "15px",
												}}
												pt={2}
											>
												Invite your friends & family
											</Typography>
											<Stack
												width="100%"
												mt="20px"
												direction={"row"}
												alignItems="center"
												justifyContent={"center"}
												bgcolor="#090e27"
												borderRadius="15px"
												boxShadow="0 0 10px 2px #00002025"
												p={1}
											>
												<Box width="10%">
													<IconButton sx={{ marginLeft: "5px", padding: 0 }}>
														<img
															src={chainico}
															width="23px"
															// height={"20px"}
															alt=""
														/>
													</IconButton>
												</Box>
												<Box width="80%" px={1}>
													<InputBase
														sx={{ width: "100%", color: "#7C7B97" }}
														value={
															account
																? window.location.origin + "/?ref=" + account
																: "Please connect your wallet"
														}
													/>
												</Box>
												<Box width="10%">
													<IconButton>
														<CopyToClipboard
															text={
																account
																	? window.location.origin + "/?ref=" + account
																	: "Please connect your wallet"
															}
															onCopy={() => {
																if (account) {
																	toast.success("Ref Link Copied");
																} else {
																	toast.error("Connect your wallet first");
																}
															}}
														>
															<ContentCopyIcon sx={{ color: "#fff" }} />
														</CopyToClipboard>
													</IconButton>
												</Box>
											</Stack>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Box py={3}>
						<Typography
							sx={{
								fontWeight: "600",
								fontSize: { md: "36px", sm: "25px" },
								color: "#fff",
								fontFamily: "Josefin Sans",
							}}
						>
							Discription:
						</Typography>
						<Typography
							sx={{
								fontWeight: "400",
								fontSize: "20px",
								color: "#7C7B97",
								fontFamily: "open Sans",
							}}
							pt={1}
						>
							{saleData?.projectDescription}
						</Typography>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default ProjectDetails;
