import React from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";

import { createContext } from "react";
import { useState } from "react";
import WalletLink from "walletlink";
import { providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import binanceWallet from "./binance-wallet.png";
// const INFURA_ID = "460f40a260564ac4a4f4b3fffb032dad";
export const seeder = "https://data-seed-prebsc-1-s2.binance.org:8545/";

export const url =
	process.env.NODE_ENV === "production" ? "/" : "http://localhost:4000/";

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider, // required
		options: {
			networkUrl: seeder,
			rpc: {
				97: seeder,
			},
			chainId: 97,
		},
	},
	"custom-walletlink": {
		display: {
			logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
			name: "Coinbase",
			description: "Connect to Coinbase Wallet (not Coinbase App)",
		},
		options: {
			appName: "Metastarter", // Your app name
			networkUrl: seeder,
			chainId: 97,
		},
		package: WalletLink,

		connector: async (_, options) => {
			const { appName, networkUrl, chainId } = options;
			const walletLink = new WalletLink({
				appName,
			});
			const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
			await provider.enable();
			return provider;
		},
	},
	"custom-binancechainwallet": {
		display: {
			logo: binanceWallet,
			name: "Binance Chain Wallet",
			description: "Connect to your Binance Chain Wallet",
		},
		package: true,
		connector: async () => {
			let provider = null;
			if (typeof window.BinanceChain !== "undefined") {
				provider = window.BinanceChain;
				try {
					await provider.request({ method: "eth_requestAccounts" });
				} catch (error) {
					throw new Error("User Rejected");
				}
			} else {
				throw new Error("No Binance Chain Wallet found");
			}
			return provider;
		},
	},
};

const web3Modal = new Web3Modal({
	network: "mainnet", // optional
	cacheProvider: true,
	providerOptions, // required
	theme: {
		background: "#ffffff",
		main: "rgb(199, 199, 199)",
		secondary: "rgb(136, 136, 136)",
		// border: "rgba(195, 195, 195, 0.14)",
		hover: "rgb(16, 26, 32)",
	},
});
// add 10%
export function calculateGasMargin(value) {
	return +(
		(value * BigNumber.from(10000).add(BigNumber.from(1000))) /
		BigNumber.from(10000)
	).toFixed(0);
	// return value;
}
//CalculatePayableGas
export const gasEstimationPayable = async (account, fn, data, amount) => {
	if (account) {
		const estimateGas = await fn(...data, MaxUint256).catch(() => {
			return fn(...data, { value: amount.toString() });
		});
		return calculateGasMargin(estimateGas);
	}
};
export const gasEstimationForAll = async (account, fn, data) => {
	if (account) {
		const estimateGas = await fn(...data, MaxUint256).catch(() => {
			return fn(...data);
		});
		return calculateGasMargin(estimateGas);
	}
};

// export function calculateGasMargin(value) {
//   return +(
//     (value * BigNumber.from(10000).add(BigNumber.from(1000))) /
//     BigNumber.from(10000)
//   ).toFixed(0);
// }
// //CalculatePayableGas
// export const gasEstimationPayable = async (
//   account,
//   _tokenAddress,
//   _ref,
//   fn,
//   data,
//   amount
// ) => {
//   if (account) {
//     const estimateGas = await fn(_tokenAddress, _ref, data, MaxUint256).catch(
//       () => {
//         return fn(_tokenAddress, _ref, data, {
//           value: parseUnits(amount.toString()).toString(),
//         });
//       }
//     );
//     return calculateGasMargin(estimateGas);
//   }
// };
// export const gasEstimationForAll = async (account, fn, data) => {
//   if (account) {
//     const estimateGas = await fn(...data, MaxUint256).catch(() => {
//       return fn(...data);
//     });
//     return calculateGasMargin(estimateGas);
//   }
// };
let initialState = {
	provider: null,
	web3Provider: null,
	account: null,
	chainId: null,
	signer: null,
	balance: null,
};
export const AppContext = createContext(initialState);

export const AppContextProvider = ({ children }) => {
	const [state, setState] = useState(initialState);

	const connect = async () => {
		// This is the initial `provider` that is returned when
		// using web3Modal to connect. Can be MetaMask or WalletConnect.

		const provider = await web3Modal.connect();

		// We plug the initial `provider` into ethers.js and get back
		// a Web3Provider. This will add on methods from ethers.js and
		// event listeners such as `.on()` will be different.
		const web3Provider = new providers.Web3Provider(provider);

		const signer = web3Provider.getSigner();
		const account = await signer.getAddress();
		const network = await web3Provider.getNetwork();
		const balance = await web3Provider.getBalance(account);
		setState({
			...state,
			provider: provider,
			web3Provider: web3Provider,
			account: account,
			signer: signer,
			chainId: network.chainId,
			balance: balance,
		});
	};
	const disconnect = React.useCallback(
		async function () {
			await web3Modal.clearCachedProvider();
			if (
				state.provider?.disconnect &&
				typeof state.provider.disconnect === "function"
			) {
				await state.provider.disconnect();
			}
			setState({
				...state,
				provider: null,
				web3Provider: null,
				account: null,
				chainId: null,
				signer: null,
				balance: null,
			});
			// window.location.reload();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state.provider]
	);

	React.useEffect(() => {
		if (state.provider?.on) {
			const handleAccountsChanged = (accounts) => {
				setState({
					...state,
					account: accounts[0],
				});
			};

			// https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
			const handleChainChanged = (_hexChainId) => {
				window.location.reload();
			};
			const handleDisconnect = (error) => {
				// eslint-disable-next-line no-console

				disconnect();
			};

			state.provider.on("accountsChanged", handleAccountsChanged);
			state.provider.on("chainChanged", handleChainChanged);
			state.provider.on("disconnect", handleDisconnect);

			// Subscription Cleanup
			return () => {
				if (state.provider.removeListener) {
					state.provider.removeListener(
						"accountsChanged",
						handleAccountsChanged
					);
					state.provider.removeListener("chainChanged", handleChainChanged);
					state.provider.removeListener("disconnect", handleDisconnect);
				}
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.provider, disconnect]);

	return (
		<AppContext.Provider
			value={{
				account: state.account,
				signer: state.signer,
				provider: state.provider,
				balance: state.balance,
				connect,
				disconnect,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
