import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext, url } from "../../utils";
import { useLiquidityLockerContract } from "../../ConnectivityAss/hooks";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { formatUnits } from "@ethersproject/units";
import Loader from "../../loading";
import dummyico from "../../images/dummyico.png";

export default function MyLockLiqiuidity({ again }) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const { account, signer } = useContext(AppContext);
	const liquidityLockerContract = useLiquidityLockerContract(signer);

	const [userLockedLiquidityInfo, setuserLockedLiquidityInfo] = useState([]);
	// const [liquidityArray, setliquidityArray] = useState([]);

	const [loading, setloading] = useState(false);

	/////////// Connectivity ////////////
	const init = async () => {
		try {
			setloading(true);
			const {
				data: { data },
			} = await axios.get(`${url}liquidityRouter/getUserLiquidity/${account}`);
			// setliquidityArray(data);
			let tokenInfoMain = [];
			let tokenInfo = [];

			if (data.length > 0) {
				for (let i = 0; i < data.length; i++) {
					tokenInfo = [];

					const count = await liquidityLockerContract.getUserNumLocksForToken(
						account,
						data[i].tokenAddress
					);

					for (let j = 0; j < +count; j++) {
						const lockDetail =
							await liquidityLockerContract.getUserLockForTokenAtIndex(
								account,
								data[i].tokenAddress,
								j.toString()
							);
						let obj = {
							lockDate: lockDetail[0].toString(),
							claimableamount: formatUnits(
								lockDetail[1].toString(),
								data[i].tokenDecimal
							),
							unlockDate: lockDetail[3].toString(),
							lockID: lockDetail[4].toString(),
							owner: lockDetail[5],
							tokenInfo: data[i],
							indexOfLock: j.toString(),
						};
						if (+obj?.claimableamount > 0) {
							tokenInfo.push(obj);
						}
					}
					tokenInfoMain.push(...tokenInfo);
				}
				setuserLockedLiquidityInfo(tokenInfoMain);
			}
			setloading(false);
		} catch (error) {
			setloading(false);

			console.log(error);
		}
	};
	useEffect(() => {
		if (account) {
			init();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, again]);

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - userLockedLiquidityInfo.length)
			: 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};
	let navigate = useNavigate();
	const routeChange = (path) => {
		navigate(path);
	};

	return (
		<Box>
			<Loader loading={loading} />
			<TableContainer>
				<Table style={{ minWidth: "300px" }}>
					<TableBody style={{ paddingLeft: "50px" }}>
						{userLockedLiquidityInfo.length > 0 &&
							console.log(userLockedLiquidityInfo, "userLockedLiquidityInfo")}
						{userLockedLiquidityInfo.length > 0 ? (
							(rowsPerPage > 0
								? userLockedLiquidityInfo.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: userLockedLiquidityInfo
							).map(
								(
									{ claimableamount, lockID, tokenInfo, indexOfLock },
									index
								) => (
									<TableRow
										key={index}
										sx={{
											borderBottom: " 1px solid #383A41",
										}}
										hover
										onClick={() => {
											routeChange(
												`/Dashboard/MyLiqiuidityLockRecord/${tokenInfo?._id}/${lockID}/${indexOfLock}`
											);
											console.log(tokenInfo, lockID, indexOfLock, "haha data");
										}}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
										>
											<Stack direction="row" spacing={2} alignItems="center">
												<img src={dummyico} alt="" width="50px" height="50px" />
												<Box>
													<Typography
														sx={{
															color: "#fff",
															fontWeight: "700",
															fontSize: "15px",
															fontFamily: "josefin Sans",
														}}
													>
														{tokenInfo.tokenName}
													</Typography>
													<Typography
														sx={{
															fontWeight: "400",
															fontSize: "15px",
															fontFamily: "open sans",
														}}
													>
														{tokenInfo.tokenSymbol}
													</Typography>
												</Box>
											</Stack>
										</TableCell>
										<TableCell
											align="center"
											sx={{
												borderBottom: " 1px solid #383A41",
											}}
										>
											<Typography
												sx={{
													color: "#fff",
													fontWeight: "400",
													fontSize: "15px",
													fontFamily: "open sans",
												}}
											>
												{claimableamount}
											</Typography>
										</TableCell>
									</TableRow>
								)
							)
						) : loading ? (
							loading
						) : (
							<TableRow>
								<TableCell
									align="center"
									style={{
										color: "#ffffff",
										fontSize: "14px",
										borderBottom: " 1px solid #383A41",
										fontFamily: "open sans",
									}}
									colSpan={5}
								>
									{account
										? "There is no Locked Liquidity yet"
										: "Please Connet Your Wallet"}
								</TableCell>
							</TableRow>
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 82 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
				{userLockedLiquidityInfo.length > 5 && (
					<TablePagination
						rowsPerPageOptions={[5, 8, 10]}
						component="div"
						count={userLockedLiquidityInfo.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</TableContainer>
		</Box>
	);
}
