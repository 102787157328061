import React from "react";
import "./textField.scss";

const TextField = ({
	type,
	required,
	placeholder,
	className,
	value,
	SearchIcon,
	label,
	QuestionIcon,
	onChange,
	readOnly,
	defaultValue,
}) => {
	return (
		<div
			className={`textFields pR ${className} ${
				SearchIcon || QuestionIcon ? "icon" : ""
			}`}
		>
			{label && <label className="dB">{label}</label>}
			<input
				className="w100"
				type={type}
				defaultValue={defaultValue}
				required={required}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				readOnly={readOnly}
				// onChange={(e)=>console.log("e", e.target.value)}
			/>
			{/* {QuestionIcon && <img className="pA" src={Question} width={20} /> } */}
			{
				SearchIcon && "abc"
				// <img className="pA" src={Search} width={20} alt="" />
			}
		</div>
	);
};

export default TextField;
