import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Bg from "../../images/Bg3.png";
import rocket from "../../images/rocket.png";
import tokenM from "../../images/tokenMinter.png";
import LiquidityLocker from "../../images/LiquidityLocker.png";
import TokenLocker from "../../images/TokenLocker.png";
import { Link } from "react-router-dom";
function Service() {
	return (
		<Box
			py={10}
			sx={{
				backgroundImage: `url(${Bg})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "contain",
			}}
		>
			<Container maxWidth="sm">
				<Box display="flex" justifyContent="center" pt="0px" pb="10px">
					<Typography
						fontWeight="700"
						fontSize="40px"
						fontFamily="josefin Sans"
						lineHeight="56px"
						letterSpacing="2px"
						color="#FFFFFF"
					>
						Services
					</Typography>
				</Box>
				{/* Main box */}

				<Box
					sx={{
						background: "#090e27",
						border: "1px solid #5A5A5A",
						boxSizing: "border-box",
						boxShadow: "-11px 17px 28px #1D1736",
						borderRadius: "30px",
					}}
					p={{ xs: 2, md: 2 }}
				>
					<Stack spacing={"15px"}>
						{/* frst box */}

						<Link to="/ido" style={{ color: "white", textDecoration: "none" }}>
							<Box
								sx={{
									border: "1px solid #636363",
									boxSizing: "border-box",
									borderRadius: "30px",
								}}
								py={"10px"}
							>
								<Box
									display={"flex"}
									alignItems="center"
									justifyContent={"space-evenly"}
								>
									<Box>
										<img
											style={{
												width: "50px",
												height: "50px",
											}}
											src={rocket}
											alt=""
										/>
									</Box>
									<Box width={{ xs: "100%", sm: "62%" }}>
										<Box
											fontFamily="josefin Sans"
											fontWeight="500"
											fontSize={{ xs: "14px", md: "18px" }}
											lineHeight="24px"
											letterSpacing="0.02em"
											color="#FFFFFF"
										>
											Launch Project
										</Box>

										<Box
											fontFamily="open Sans"
											fontWeight="400"
											fontSize={{ xs: "10px", md: "14px" }}
											lineHeight={{ xs: "17px", md: "26px" }}
											color="#7C7B97"
											mt={"5px"}
										>
											Raise capital to kick start your blockchain project
											Decentalised
										</Box>
									</Box>
								</Box>
							</Box>
						</Link>
						{/* scnd Box */}

						<Link
							to="/token"
							style={{ color: "white", textDecoration: "none" }}
						>
							<Box
								sx={{
									border: "1px solid #636363",
									boxSizing: "border-box",
									borderRadius: "30px",
								}}
								py={"10px"}
							>
								<Box
									display={"flex"}
									alignItems="center"
									justifyContent={"space-evenly"}
									p={{ xs: 1, md: 0 }}
								>
									<Box>
										<img
											style={{
												width: "50px",
												height: "50px",
											}}
											src={tokenM}
											alt=""
										/>
									</Box>
									<Box width={{ xs: "100%", sm: "62%" }}>
										<Typography
											fontFamily="josefin Sans"
											fontWeight="500"
											fontSize={{ xs: "14px", md: "18px" }}
											lineHeight="24px"
											letterSpacing="0.02em"
											color="#FFFFFF"
										>
											Token Minter{" "}
										</Typography>
										<Typography
											fontFamily="open Sans"
											fontWeight="400"
											fontSize={{ xs: "10px", md: "14px" }}
											lineHeight={{ xs: "17px", md: "26px" }}
											color="#7C7B97"
											mt={"5px"}
										>
											Create your own ENMT Token. ENMT tokens are fully ERC20
											compliant Non-Mintable Tokens.
										</Typography>
									</Box>
								</Box>
							</Box>
						</Link>
						{/* thrd Box */}
						<Link to="/services/liquidity-lock">
							<Box
								sx={{
									border: "1px solid #636363",
									boxSizing: "border-box",
									borderRadius: "30px",
								}}
								py="10px"
							>
								<Box
									display={"flex"}
									alignItems="center"
									justifyContent={"space-evenly"}
									p={{ xs: 1, md: 0 }}
								>
									<Box>
										<img
											src={LiquidityLocker}
											style={{
												width: "50px",
												height: "50px",
											}}
											alt=""
										/>
									</Box>
									<Box width={{ xs: "100%", sm: "62%" }}>
										{/* <Link to="/services/liquidity-lock"> */}
										<Typography
											fontFamily="josefin Sans"
											fontWeight="500"
											fontSize={{ xs: "14px", md: "18px" }}
											lineHeight="24px"
											letterSpacing="0.02em"
											color="#FFFFFF"
										>
											Liquidity Locker{" "}
										</Typography>
										{/* </Link> */}
										<Typography
											fontFamily="open Sans"
											fontWeight="400"
											fontSize={{ xs: "10px", md: "14px" }}
											lineHeight={{ xs: "17px", md: "26px" }}
											color="#7C7B97"
											mt={"5px"}
										>
											Lock liquidity tokens
										</Typography>
									</Box>
								</Box>
							</Box>
						</Link>
						{/* Fourth BOx */}
						<Link to="/services/tokenLocker">
							<Box
								sx={{
									border: "1px solid #636363",
									boxSizing: "border-box",
									borderRadius: "30px",
								}}
								py="10px"
							>
								<Box
									display={"flex"}
									alignItems="center"
									justifyContent={"space-evenly"}
									p={{ xs: 1, md: 0 }}
								>
									<Box>
										<img
											src={TokenLocker}
											style={{
												width: "50px",
												height: "50px",
											}}
											alt=""
										/>
									</Box>
									<Box width={{ xs: "100%", sm: "62%" }}>
										<Typography
											fontFamily="josefin Sans"
											fontWeight="500"
											fontSize={{ xs: "14px", md: "18px" }}
											lineHeight="24px"
											letterSpacing="0.02em"
											color="#FFFFFF"
										>
											Token Locker{" "}
										</Typography>
										<Typography
											fontFamily="open Sans"
											fontWeight="400"
											fontSize={{ xs: "10px", md: "14px" }}
											lineHeight={{ xs: "17px", md: "26px" }}
											color="#7C7B97"
											mt={"5px"}
										>
											Lock your ERC20 team tokens Send tokens to someone else
											that are locked for a configurable period{" "}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Link>
					</Stack>
				</Box>
			</Container>
		</Box>
	);
}

export default Service;
