import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { useState } from "react";
import TextField from "../TextField";
import TextAreaField from "../TextAreaField";

import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { url } from "../../utils";
import { toast } from "react-toastify";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	//   width: "60%",
	bgcolor: "#11112e",
	border: "2px solid white",
	boxShadow: 24,
	p: 4,
	marginTop: "18%",
	marginBottom: "5%",
};

export default function EditProjectModal({
	handleOpen,
	open,
	handleClose,
	saleDataEdit,
}) {
	const matches = useMediaQuery("(min-width:600px)");

	// Additional info
	const [logoLink, setlogoLink] = useState("");
	const [webLink, setwebLink] = useState("");
	const [githubLink, setgithubLink] = useState("");
	const [twitterLink, settwitterLink] = useState("");
	const [redditLink, setredditLink] = useState("");
	const [telegramLink, settelegramLink] = useState("");
	const [projectDescription, setprojectDescription] = useState("");

	const [projectName, setprojectName] = useState("");
	React.useEffect(() => {
		if (saleDataEdit) {
			setprojectName(saleDataEdit?.projectName);
			setlogoLink(saleDataEdit?.logoLink);
			setwebLink(saleDataEdit?.webLink);
			setgithubLink(saleDataEdit?.githubLink);
			settwitterLink(saleDataEdit?.twitterLink);
			setredditLink(saleDataEdit?.redditLink);
			settelegramLink(saleDataEdit?.telegramLink);
			setprojectDescription(saleDataEdit?.projectDescription);
		}
	}, [saleDataEdit]);

	const submmit = async () => {
		let data = {
			projectName: projectName,
			logoLink: logoLink,
			webLink: webLink,
			githubLink: githubLink,
			twitterLink: twitterLink,
			redditLink: redditLink,
			telegramLink: telegramLink,
			projectDescription: projectDescription,
		};
		// console.log("data ==>", data);
		let tx = await axios.put(
			url + `presaleRouter/UpdatePresaleData/${saleDataEdit._id}`,
			data
		);
		// console.log("tx ==>", tx);
		toast.success("Project Updated Successfully");
		window.location.reload();
	};
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				style={{
					overflow: "scroll",
					border: "1px solid red",
				}}
			>
				{/* <Fade in={open}> */}
				<Box width={matches ? "60%" : "90%"} sx={style}>
					<div
					// className="createSaleFields"
					>
						{/* <div className={`${isMobile ? "" : "dF"}`}> */}
						{/* <div className="fieldTextRow"> */}
						<TextField
							type="text"
							placeholder="Enter here"
							label="Project name"
							className="fieldText"
							QuestionIcon
							value={projectName}
							onChange={(e) => setprojectName(e.target.value)}
						/>
						<TextField
							type="text"
							label="Medium link"
							className="fieldText"
							QuestionIcon
							value={githubLink}
							onChange={(e) => setgithubLink(e.target.value)}
							placeholder="Ex. https://www.medium..."
						/>
						<TextField
							type="text"
							label="Reddit link"
							className="fieldText"
							QuestionIcon
							value={redditLink}
							onChange={(e) => setredditLink(e.target.value)}
							placeholder="Ex. https://www.Reddit..."
						/>
						{/* </div> */}
						<div className="fieldTextRow">
							<TextField
								type="text"
								label="Website link"
								className="fieldText"
								QuestionIcon
								value={webLink}
								onChange={(e) => setwebLink(e.target.value)}
								placeholder="Ex. https://www.cardence.io"
							/>
							<TextField
								type="text"
								label="Logo link"
								className="fieldText"
								QuestionIcon
								value={logoLink}
								onChange={(e) => setlogoLink(e.target.value)}
								placeholder="Ex. https://www.cardence.io/logo.png"
							/>
						</div>
						<div className="fieldTextRow">
							<TextField
								type="text"
								label="Telegram link"
								className="fieldText"
								QuestionIcon
								value={telegramLink}
								onChange={(e) => settelegramLink(e.target.value)}
								placeholder="Ex. https://t.me..."
							/>
							<TextField
								type="text"
								label="Twitter link"
								className="fieldText"
								QuestionIcon
								value={twitterLink}
								onChange={(e) => settwitterLink(e.target.value)}
								placeholder="Ex. https://www.twitter..."
							/>
						</div>
					</div>
					<div className="projectDescription">
						<div
						//    className={`${isMobile ? "" : "dF"}`}
						>
							<TextAreaField
								type="textarea"
								placeholder="Enter here"
								label="Project description"
								className="textAreas"
								QuestionIcon
								value={projectDescription}
								onChange={(e) => setprojectDescription(e.target.value)}
							/>
						</div>
					</div>
					<Box
						style={{
							clipPath:
								"polygon(21% 0 , 94% 0, 100% 20%, 79% 100%, 6% 100%, 0 80%)",
							background:
								" radial-gradient(241.67% 3206.67% at -4.58% -26.67%, #AD00FF 8.51%, #0029FF 100%)",
							cursor: "pointer",
							// opacity: 0.5,
						}}
						width={matches ? "160px" : "220px"}
						height="42px"
						fontWeight="700"
						fontSize={matches ? "16px" : "20px"}
						color="#fff"
						display="flex"
						paddingBottom="3px"
						justifyContent="center"
						alignItems="center"
						fontFamily="Gemunu Libre"
						onClick={() => submmit()}
					>
						Update
					</Box>
					{/* </div> */}
				</Box>
				{/* </Fade> */}
			</Modal>
		</div>
	);
}
