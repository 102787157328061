import React, { useState } from "react";
import { Box, Dialog, Button, DialogContent } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import twitter from "../images/twitter.png";
import fb from "../images/facebook.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { url } from "../utils";

const MainModal = ({
	setModalOpen,
	ModalOpen,
	username,
	setUsername,
	settwitterState,
}) => {
	const handleClose = () => {
		setModalOpen(false);
	};
	const HandleUser = async () => {
		if (username) {
			const { data } = await axios.get(url + "TwitterClient/getFollowers");
			const ok = data?.data.filter((user) => user.username === username);
			if (ok.length !== 0) {
				settwitterState(ok);
			}
			handleClose();
		} else {
			alert("please enter your username");
		}
	};

	return (
		<Box>
			<Dialog
				maxWidth={"xs"}
				open={ModalOpen}
				onClose={handleClose}
				sx={{
					"& .MuiPaper-root": {
						boxShadow: "0px 0px 16px 7px rgba(70, 60, 221, 0.34)",
						padding: "10px",
						background: "#090E27",
					},
				}}
			>
				<DialogContent
					sx={{
						width: "100%",
						height: "100%",
						padding: "10px",
						fontSize: "14px",
					}}
				>
					Follow Us on{" "}
					<a
						href="https://twitter.com/BloctechS"
						target="_blank"
						rel="noreferrer"
						style={{
							textDecoration: "underline",
							color: "rgb(29, 155, 240)",
						}}
					>
						Twitter
					</a>{" "}
					and enter your username to verify it.
					<Box my={2}>
						Username:{" "}
						<input
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							placeholder="@MyName"
							style={{
								background: "none",
								border: "1px solid #72717d",
								height: "38px",
								color: "#fff",
								padding: "0px 10px",
							}}
						/>
						<Button
							onClick={HandleUser}
							sx={{
								backgroundColor: "#D542D6",
								border: "1px solid #D542D6",
								textTransform: "capitalize",
								color: "#fff",
								borderRadius: "0px",
							}}
						>
							Continue
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

function Whitelist({ setOpen, open }) {
	const [address, setAddress] = useState();
	const [click, setclick] = useState(false);
	const [username, setUsername] = useState();
	const [twitterState, settwitterState] = useState(false);

	const [ModalOpen, setModalOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box>
			<MainModal
				ModalOpen={ModalOpen}
				setModalOpen={setModalOpen}
				username={username}
				setUsername={setUsername}
				settwitterState={settwitterState}
			/>
			<Dialog
				fullWidth="sm"
				maxWidth={true}
				open={open}
				onClose={handleClose}
				sx={{
					"& .MuiPaper-root": {
						boxShadow: "0px 0px 16px 7px rgba(70, 60, 221, 0.34)",
					},
				}}
			>
				{/* <DialogTitle></DialogTitle> */}
				<DialogContent
					sx={{
						width: "100%",
						height: "100%",
						backgroundClip:
							"content-box, border-box, border-box, border-box, border-box",
						// borderRadius: "15px",
						background: "#090E27",
						padding: "0px",
						border: "2px solid transparent",
						backgroundImage:
							"linear-gradient(#090E27, #090E27), linear-gradient(192.51deg, #D542D6 3.3%, rgba(132, 84, 235,0.2) 93.12%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(0deg, #090E27 0%, #090E27 100%), linear-gradient(192.51deg, rgba(213, 66, 214, 0.3) 3.3%, #8454EB 93.12%)",
						backgroundOrigin:
							"content-box, border-box, border-box, border-box, border-box",
						backgroundRepeat: "no-repeat",
						backgroundSize: "100% 100%, 30% 90%, 0% 0%, 0% 0%, 30% 90%",
						backgroundPosition:
							"top left, top left, top right, bottom left, bottom right",
					}}
				>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							background: "#090E27",
							borderRadius: "15px",
						}}
						p={5}
					>
						<input
							value={address}
							placeholder="Please enter your wallet address"
							onChange={(e) => setAddress(e.target.value)}
							style={{
								border: "1px solid #5142FC",
								outline: "none",
								background: "none",
								width: "100%",
								height: "40px",
								padding: "5px 10px",
								borderRadius: "6px",
								color: "#fff",
							}}
						/>
						<Box
							my={2}
							display="flex"
							alignItems="center"
							border="1px solid #72717d"
							justifyContent="space-between"
							onClick={() => setModalOpen(true)}
						>
							<Box display="flex" alignItems="center">
								<img src={twitter} width="30px" alt="" />
								<Box ml={1}>Follow @Metastarter on Twitter</Box>
							</Box>

							<Box bgcolor={twitterState ? "green" : "#4949ef"} px={1}>
								{twitterState ? <DoneIcon /> : <KeyboardArrowDownIcon />}
							</Box>
						</Box>
						{/* </a> */}

						{/* <a
              href=" https://www.facebook.com/WaterLeather"
              target="_blank"
              rel="noreferrer"
            > */}
						<Box
							my={2}
							display="flex"
							alignItems="center"
							border="1px solid #72717d"
							justifyContent="space-between"
							onClick={() => setclick(true)}
						>
							<Box display="flex" alignItems="center">
								<img src={fb} width="30px" alt="" />
								<Box ml={1}>Like @Metastarter on Facebook</Box>
							</Box>

							<Box bgcolor={click ? "green" : "#4949ef"} px={1}>
								{click ? <DoneIcon /> : <KeyboardArrowDownIcon />}
							</Box>
						</Box>
						{/* </a> */}
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
}

export default Whitelist;
